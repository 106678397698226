import React, { useState } from 'react';
import { format, startOfWeek, endOfWeek, startOfMonth, endOfMonth, eachDayOfInterval, addMonths, subMonths, isSameDay, isSameWeek, isSameMonth, getMonth, isToday } from 'date-fns';

const CalendarSmall = ({ selectedMode, setSelectedMode, selectedDates, setSelectedDates }) => {
    const [currentMonth, setCurrentMonth] = useState(new Date());

    const handleDateClick = (date) => {
        if (selectedMode === 'Daily') {
            setSelectedDates([date]);
        } else if (selectedMode === 'Weekly') {
            const start = startOfWeek(date, { weekStartsOn: 1 });
            const end = endOfWeek(date, { weekStartsOn: 1 });
            setSelectedDates(eachDayOfInterval({ start, end }));
        } else if (selectedMode === 'Monthly') {
            const start = startOfMonth(date);
            const end = endOfMonth(date);
            setSelectedDates(eachDayOfInterval({ start, end }));
        }
    };

    const handlePreviousMonth = () => {
        setCurrentMonth(subMonths(currentMonth, 1));
    };

    const handleNextMonth = () => {
        setCurrentMonth(addMonths(currentMonth, 1));
    };

    const handleNow = () => {
        setCurrentMonth(new Date());
        const today = new Date();
        if (selectedMode === 'Daily') {
            setSelectedDates([today]);
        } else if (selectedMode === 'Weekly') {
            const start = startOfWeek(today, { weekStartsOn: 1 });
            const end = endOfWeek(today, { weekStartsOn: 1 });
            setSelectedDates(eachDayOfInterval({ start, end }));
        } else if (selectedMode === 'Monthly') {
            const start = startOfMonth(today);
            const end = endOfMonth(today);
            setSelectedDates(eachDayOfInterval({ start, end }));
        }
    };

    const renderCalendar = () => {
        const startDate = startOfMonth(currentMonth);
        const endDate = endOfMonth(currentMonth);
        const days = eachDayOfInterval({ start: startDate, end: endDate });

        // Get the start and end of the first and last weeks of the month
        const startWeek = startOfWeek(startDate, { weekStartsOn: 1 });
        const endWeek = endOfWeek(endDate, { weekStartsOn: 1 });
        const fullDays = eachDayOfInterval({ start: startWeek, end: endWeek });

        return (
            <div>
                <div className="grid text-sm grid-cols-7 gap-1 text-center mb-2">
                    {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day) => (
                        <div key={day} className="font-bold">{day}</div>
                    ))}
                </div>
                <div className="grid text-sm grid-cols-7 gap-1">
                    {fullDays.map((day) => {
                        const isSelected = selectedDates.some((selectedDate) => isSameDay(selectedDate, day));
                        const isInSelectedWeek = selectedMode === 'Weekly' && selectedDates.some((selectedDate) => isSameWeek(selectedDate, day, { weekStartsOn: 1 }));
                        const isInSelectedMonth = selectedMode === 'Monthly' && selectedDates.some((selectedDate) => isSameMonth(selectedDate, day));
                        const isCurrentMonth = getMonth(day) === getMonth(currentMonth);

                        return (
                            <button
                                key={day}
                                onClick={() => handleDateClick(day)}
                                className={`p-1 ${isSelected || isInSelectedWeek || isInSelectedMonth ? 'bg-blue-500 text-white' : !isCurrentMonth ? 'bg-gray-200 text-gray-400' : 'bg-gray-100 text-gray-800'} rounded-lg ${isToday(day) ? 'border-2 border-blue-500' : ''}`}
                            >
                                {format(day, 'd')}
                            </button>
                        );
                    })}
                </div>
            </div>
        );
    };

    return (
        <div className="p-4">
            <div className="flex text-sm justify-center mb-4">
                <button onClick={() => setSelectedMode('Daily')} className={(selectedMode === "Daily" ? "bg-gray-600" : "bg-gray-500") + " px-4 py-2 text-white rounded-l-lg"}>Daily</button>
                <button onClick={() => setSelectedMode('Weekly')} className={(selectedMode === "Weekly" ? "bg-gray-600" : "bg-gray-500") + " px-4 py-2 border-x border-black text-white "}>Weekly</button>
                <button onClick={() => setSelectedMode('Monthly')} className={(selectedMode === "Monthly" ? "bg-gray-600" : "bg-gray-500") + " px-4 py-2 text-white rounded-r-lg"}>Monthly</button>
            </div>

            <div className="flex text-sm justify-between items-center mb-4">
                <button onClick={handlePreviousMonth} className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg"> {'<'}</button>
                <h2 className="text-xl w-40 font-semibold">{format(currentMonth, 'MMMM yyyy')}</h2>
                <button onClick={handleNextMonth} className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg">{'>'}</button>
                <button onClick={handleNow} className="px-4 py-2 bg-blue-500 text-white rounded-lg">Now</button>
            </div>

            {renderCalendar()}
        </div>
    );
};

export default CalendarSmall;
