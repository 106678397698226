import {
    Success, Fail
} from "../constants";

export const initialState = {
    user: null,
    tokens: {},
    userCreated: false,
    passwordUpdate: ''
};

export default function (state = initialState, action) {
    switch (action.type) {
        case Success:
            return {
                ...state,
                user: action.payload,
            };

        case Fail:
            return {
                ...state,
                user: action.payload,
            };


        default:
            return state;
    }
}
