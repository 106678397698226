import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useAppContext } from '../../../../../AppContextProvider ';
import { useLocation, useNavigate } from 'react-router-dom';
import { Select } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faSave } from '@fortawesome/free-regular-svg-icons';
import { NumberExtractor, ReferenceTypeHeaders, ReferenceTypeItems, calculateDifference, formatTime, getCorrectDate, getCorrectDateForInput, getDateByRange, getUniqueObjectsByKey, replaceEmptyObjectsWithNull } from '../../../../../common';
import { ApiCall_GET, ApiCall_POST } from '../../../../../Redux/Generic/action';
import { extractVarFromUrl } from '../../../../../session';
import TreatmentPlanSummary from './TreatmentPlanSummary';
import MedicationPlanner from './MedicationPlanner';
import Medications from './Medications';
import StatsPanel from './StatsPanel';
import Swal from 'sweetalert2';
import { faGears, faMinus, faPlus, faRefresh, faTrash } from '@fortawesome/free-solid-svg-icons';
import { getMedicationType, getMedicationTypeNew } from './DrugSwitch';
import moment from "moment";
import PrintPop from './PrintPop';


export const StimPlan = ({ ApiCall_GET, ApiCall_POST, ...props }) => {
  let navigate = useNavigate();
  let location = useLocation();

  var artid = location.state ? location.state.artid : extractVarFromUrl(window.location.href, 'artid');
  var coupleid = location.state ? location.state.coupleid : extractVarFromUrl(window.location.href, 'coupleid');
  var artData = location.state ? location.state.artData : null;
  var IsCalledByView = location.state ? location.state.IsCalledByView : null;

  const NumberOfColums = 50

  var item = props.data;
  const theme = useAppContext().themeDetails;
  const [selectedIndex, setselectedIndex] = useState(0);
  const [addDrugModal, setAddDrugModal] = useState(false);
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const [activeMedicTab, setActiveMedicTab] = useState('Procedures');
  const [treatmentPlan, setTreatmentPlan] = useState(null);
  const [formObjList, setFormObjList] = useState(null);
  const [dateFields, setDateFields] = useState(['date1']);
  const [medications, setMedications] = useState([]);
  const [medication, setMedication] = useState(null)
  const [popup, setPopup] = useState(null)

  var periodTypes = [
    { name: 'Daily', id: 1 },
    { name: 'BD', id: 2 },
    { name: 'TDS', id: 3 },
    { name: 'QID', id: 4 }
  ]

  const getInitFormObject = () => {
    return {
      medTypeId: 1,
      drugId: 0,
      dosage: '',
      drugName: '',
      frequency: 0,
      period: '',
      units: '',
      dateAdministered: '',
      startDate: '',
      endDate: '',
    }
  }

  const [formObj, setFormObj] = useState(getInitFormObject());



  const handleInputChange = (e, val) => {
    var { name, value } = e.target;

    if (val) { value = val }

    if (NumberExtractor(name.replace('date', '')) > 0) {//to check the specific dates
      setFormObj({
        ...formObj,
        ['startDate']: '',
        ['endDate']: '',
        [name]: value,
      });
    }
    else if (name == 'startDate' || name == 'endDate') {
      var otherObjects = Object.fromEntries(
        Object.entries(formObj).filter(([key]) => !key.startsWith('date'))
      )
      otherObjects = {
        ...otherObjects,
        [name]: value,
      }
      setFormObj(otherObjects)
      resetDateField()
    }
    else if (name == 'drugId') {
      var index = e.nativeEvent.target.selectedIndex;
      setFormObj({
        ...formObj,
        ['drugName']: e.nativeEvent.target[index].text,
        [name]: value,
      });
    }
    else {
      setFormObj({
        ...formObj,
        [name]: value,
      });
    }

  };

  const addDateField = () => {
    const newDateField = `date${dateFields.length + 1}`;
    setDateFields([...dateFields, newDateField]);
  };

  const resetDateField = () => {
    setDateFields(['date1']);
  };
  const resetAllFields = () => {
    setFormObj(getInitFormObject())
    setDateFields(['date1']);
  };


  useEffect(() => {
    ApiCall_GET('TreatmentPlan/GetTreatmentPlan?coupleId=' + coupleid + '&treatmentPlanId=0&artId=' + artid, true).then(function (payload) {
      var tpl = createTreatmentPlanList(payload)
      var tp = tpl[selectedIndex]
      getMedications(tp)
      setTreatmentPlan(replaceEmptyObjectsWithNull(tp))

    })

  }, [])
  const getMedications = (tp) => {
    ApiCall_GET('ART/GetAllMedicationDetails', true).then(function (payload) {
      setMedications(payload?.NewMedications?.sort())
      autoAddMedicines(replaceEmptyObjectsWithNull(tp), payload?.NewMedications?.sort())
    })
  };

  const deleteItem = (item) => {
    Swal.fire({
      title: "Do you want to delete " + item.drugName + " ?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No"
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const newState = formObjList?.filter(x => x.drugId !== item.drugId);
        setFormObjList(newState)
      } else if (result.isDenied) {
        Swal.fire(item.drugName + " is not deleted", "", "info");
      }
    });

  };

  function doFirstWordsMatch(str1, str2) {
    // Extract the first word from each string
    const firstWord1 = str1.split(' ')[0];
    const firstWord2 = str2.split(' ')[0];

    // Compare the first words
    return firstWord1 === firstWord2;
  }

  const autoAddMedicines = (tp, meds, medicationObj) => {
    const unwantedLabels = ['Dose', 'When', 'Comments']; // Labels to filter out
    const medicines = tp?.items ? tp?.items?.filter(x => x.objectTypeId == 2 && x.dataTypeId == 3).filter(item => {
      return !unwantedLabels.includes(item.label);
    }) : [];

    var medicineList = [];

    if (!medicationObj) {
      medicationObj = medication
    }

    medicines?.forEach(item => {

      var obj = getInitFormObject();

      obj.medTypeId =
        item.parentId === ReferenceTypeHeaders.StimMedication ||
          item.parentId === ReferenceTypeHeaders.StimMedicationFSH ||
          item.parentId === ReferenceTypeHeaders.AdjuvantTherapy ||
          item.parentId === ReferenceTypeHeaders.Agonist ||
          item.parentId === ReferenceTypeHeaders.Antagonist ? 1
          : item.parentId === ReferenceTypeHeaders.TriggerMedication ? 2
            : item.parentId === ReferenceTypeHeaders.LutealPhaseMedication ? 8
              : 0;

      obj.drugId = item.objectId;
      obj.isAdded = 0;
      obj.dosage = extractValuesByKey('Dosage', item.procedurevalue) || extractValuesByKey('Dose', item.procedurevalue) || extractValuesByKey('dosage', item.procedurevalue) || extractValuesByKey('dose', item.procedurevalue);
      obj.startDate = extractValuesByKey('Start', item.procedurevalue) || extractValuesByKey('start', item.procedurevalue) ? getCorrectDateForInput(getDateByRange(artData?.DayofCycle, extractValuesByKey('Start', item.procedurevalue) || extractValuesByKey('start', item.procedurevalue))) : ''
      obj.endDate = extractValuesByKey('Finish', item.procedurevalue) || extractValuesByKey('finish', item.procedurevalue) ? getCorrectDateForInput(getDateByRange(artData?.DayofCycle, extractValuesByKey('Finish', item.procedurevalue) || extractValuesByKey('finish', item.procedurevalue))) : ''
      obj.date1 = extractValuesByKey('When', item.procedurevalue) || extractValuesByKey('when', item.procedurevalue) ? getCorrectDateForInput(getDateByRange(artData?.DayofCycle, extractValuesByKey('When', item.procedurevalue) || extractValuesByKey('when', item.procedurevalue))) : ''
      obj.drugName = meds && meds.filter(x => x.Id == item.objectId)[0]?.Label;
      obj.units = '';
      obj.period = 'Daily'

      var listOfAddedMeds = getUniqueObjectsByKey(medication?.Stim, 'drug') || getUniqueObjectsByKey(medication?.Trigger, 'drug') || getUniqueObjectsByKey(medication?.Luteal, 'drug')
      var foundDrug = listOfAddedMeds?.filter(x => x.drug == getMedicationType(item.objectId));
      if (foundDrug.length) {
        obj.isAdded = 1;
      }

      if ((!obj.startDate || (!obj.startDate && !obj.date1)) && obj.medTypeId === 1) { //stim
        if (!obj.endDate) {
          obj.startDate = obj.date1 ? obj.date1 : getCorrectDateForInput(getDateByRange(artData?.DayofCycle, (3 - 1)));

          obj.date1 = '';
          if (artData?.tridate) {
            obj.endDate = calculateDifference(obj.startDate, getCorrectDateForInput(artData?.tridate)) > 0 ? getCorrectDateForInput(artData?.tridate) : obj.startDate;
          }
          else {
            obj.endDate = calculateDifference(obj.startDate, getDateByRange(artData?.DayofCycle, (11 - 1))) > 0 ? getCorrectDateForInput(getDateByRange(artData?.DayofCycle, (11 - 1))) : obj.startDate;
          }
        }
      }
      else if (!obj.startDate && !obj.date1 && obj.medTypeId === 2) { //trigger
        obj.dateAdministered = artData?.tridate ? getCorrectDateForInput(artData?.tridate) : getCorrectDateForInput(getDateByRange(artData?.DayofCycle, (13 - 1)));
      }
      else if (!obj.startDate && !obj.date1 && obj.medTypeId === 8) { //luteal
        obj.startDate = artData?.tridate ? getCorrectDateForInput(artData?.tridate) : getCorrectDateForInput(getDateByRange(artData?.DayofCycle, (11 - 1)));
        obj.endDate = artData?.tridate ? getCorrectDateForInput(artData?.tridate) : getCorrectDateForInput(getDateByRange(artData?.DayofCycle, 49));
      }


      if (!obj.dosage) {
        // console.log('dosage', obj)
        // console.log('--', tp?.items?.filter(x => x.parentId == item.objectId))
        var child = tp?.items?.filter(x => x.parentId == item.objectId && item.originalValue && (x.dataTypeId == 3 || x.dataTypeId == 5))
        obj.dosage = NumberExtractor(item.label) ? NumberExtractor(item.label) : NumberExtractor(child[0]?.label)
      }


      if (!obj.dosage) {
        // console.log('item', obj)
        var child = tp?.items?.filter(x => x.parentId == item.objectId && item.originalValue && (x.dataTypeId == 3 || x.dataTypeId == 5))
        // console.log('child', child)
        // console.log('NumberExtractor', NumberExtractor(item.label) ? NumberExtractor(item.label) : NumberExtractor(child[0]?.label))
      }


      if (obj.medTypeId != 0) {
        medicineList.push(obj);
      }
      // dateAdministered: '',
    });


    getUniqueObjectsByKey(medicationObj?.Stim, 'drug')?.forEach(item => {
      var foundDrug = medicineList.filter(x => x.drugId == getMedicationTypeNew(item.drug) || doFirstWordsMatch(x.drugName, item.drugmastername));
      // console.log(medicineList, 'medicineList')
      // console.log(item, 'item')
      if (foundDrug.length == 0) {
        item = replaceEmptyObjectsWithNull(item)
        var obj = getInitFormObject();
        obj.drugId = getMedicationTypeNew(item.drug);
        obj.drugName = item.drugmastername
        obj.dosage = item.dose//extractValuesByKey('Dosage', item.procedurevalue) || extractValuesByKey('Dose', item.procedurevalue) || extractValuesByKey('dosage', item.procedurevalue) || extractValuesByKey('dose', item.procedurevalue);
        if (item.from == item.to) {
          obj.date1 = getCorrectDateForInput(item.from)
        }
        else {
          obj.startDate = getCorrectDateForInput(item.from) // extractValuesByKey('Start', item.procedurevalue) || extractValuesByKey('start', item.procedurevalue) ? getCorrectDateForInput(getDateByRange(artData?.DayofCycle, extractValuesByKey('Start', item.procedurevalue) || extractValuesByKey('start', item.procedurevalue))) : ''
          obj.endDate = getCorrectDateForInput(item.to) // extractValuesByKey('Finish', item.procedurevalue) || extractValuesByKey('finish', item.procedurevalue) ? getCorrectDateForInput(getDateByRange(artData?.DayofCycle, extractValuesByKey('Finish', item.procedurevalue) || extractValuesByKey('finish', item.procedurevalue))) : ''
        }
        // obj.date1 = extractValuesByKey('When', item.procedurevalue) || extractValuesByKey('when', item.procedurevalue) ? getCorrectDateForInput(getDateByRange(artData?.DayofCycle, extractValuesByKey('When', item.procedurevalue) || extractValuesByKey('when', item.procedurevalue))) : ''
        // obj.drugName = meds && meds.filter(x => x.Id == item.objectId)[0]?.Label;
        obj.medTypeId = 1
        obj.isAdded = 1;
        obj.units = item.theunits || item.units1;
        obj.period = item.period
        // // dateAdministered: '',

        medicineList.push(obj);
      }
      else {
        const obj = foundDrug[0];
        obj.isAdded = 1;
        const list = medicineList.filter(x => x.drugId != obj.drugId);
        medicineList = [...list, obj];
      }
    });


    getUniqueObjectsByKey(medicationObj?.Trigger, 'drug')?.forEach(item => {

      var foundDrug = medicineList.filter(x => x.drugId == getMedicationTypeNew(item.drug) || doFirstWordsMatch(x.drugName, item.drugmastername));

      if (foundDrug.length == 0) {

        item = replaceEmptyObjectsWithNull(item)
        var obj = getInitFormObject();
        obj.drugId = getMedicationTypeNew(item.drug);
        obj.drugName = item.drugmastername
        obj.dosage = item.dose//extractValuesByKey('Dosage', item.procedurevalue) || extractValuesByKey('Dose', item.procedurevalue) || extractValuesByKey('dosage', item.procedurevalue) || extractValuesByKey('dose', item.procedurevalue);
        if (item.startdate == item.findate) {
          // obj.date1 = getCorrectDateForInput(item.startdate)
          obj.dateAdministered = getCorrectDateForInput(item.startdate)
        }
        else {
          obj.startDate = getCorrectDateForInput(item.startdate) // extractValuesByKey('Start', item.procedurevalue) || extractValuesByKey('start', item.procedurevalue) ? getCorrectDateForInput(getDateByRange(artData?.DayofCycle, extractValuesByKey('Start', item.procedurevalue) || extractValuesByKey('start', item.procedurevalue))) : ''
          obj.endDate = getCorrectDateForInput(item.findate) // extractValuesByKey('Finish', item.procedurevalue) || extractValuesByKey('finish', item.procedurevalue) ? getCorrectDateForInput(getDateByRange(artData?.DayofCycle, extractValuesByKey('Finish', item.procedurevalue) || extractValuesByKey('finish', item.procedurevalue))) : ''
        }
        // obj.date1 = extractValuesByKey('When', item.procedurevalue) || extractValuesByKey('when', item.procedurevalue) ? getCorrectDateForInput(getDateByRange(artData?.DayofCycle, extractValuesByKey('When', item.procedurevalue) || extractValuesByKey('when', item.procedurevalue))) : ''
        // obj.drugName = meds && meds.filter(x => x.Id == item.objectId)[0]?.Label;
        obj.medTypeId = 2
        obj.units = item.theunits || item.units1;
        obj.period = item.period
        // // dateAdministered: '',
        obj.isAdded = 1;
        medicineList.push(obj);
      }
      else {
        const obj = foundDrug[0];
        obj.isAdded = 1;
        const list = medicineList.filter(x => x.drugId != obj.drugId);
        medicineList = [...list, obj];
      }
    });


    getUniqueObjectsByKey(medicationObj?.Luteal, 'drug')?.forEach(item => {
      var foundDrug = medicineList.filter(x => x.drugId == getMedicationTypeNew(item.drug) || doFirstWordsMatch(x.drugName, item.drugmastername));
      if (foundDrug.length == 0) {
        item = replaceEmptyObjectsWithNull(item)
        var obj = getInitFormObject();
        obj.drugId = getMedicationTypeNew(item.drug);
        obj.drugName = item.drugmastername
        obj.dosage = item.dose//extractValuesByKey('Dosage', item.procedurevalue) || extractValuesByKey('Dose', item.procedurevalue) || extractValuesByKey('dosage', item.procedurevalue) || extractValuesByKey('dose', item.procedurevalue);
        if (item.startdate == item.findate) {
          obj.date1 = getCorrectDateForInput(item.startdate)
        }
        else {
          obj.startDate = getCorrectDateForInput(item.startdate) // extractValuesByKey('Start', item.procedurevalue) || extractValuesByKey('start', item.procedurevalue) ? getCorrectDateForInput(getDateByRange(artData?.DayofCycle, extractValuesByKey('Start', item.procedurevalue) || extractValuesByKey('start', item.procedurevalue))) : ''
          obj.endDate = getCorrectDateForInput(item.findate) // extractValuesByKey('Finish', item.procedurevalue) || extractValuesByKey('finish', item.procedurevalue) ? getCorrectDateForInput(getDateByRange(artData?.DayofCycle, extractValuesByKey('Finish', item.procedurevalue) || extractValuesByKey('finish', item.procedurevalue))) : ''
        }
        // obj.date1 = extractValuesByKey('When', item.procedurevalue) || extractValuesByKey('when', item.procedurevalue) ? getCorrectDateForInput(getDateByRange(artData?.DayofCycle, extractValuesByKey('When', item.procedurevalue) || extractValuesByKey('when', item.procedurevalue))) : ''
        // obj.drugName = meds && meds.filter(x => x.Id == item.objectId)[0]?.Label;
        obj.medTypeId = 8
        obj.isAdded = 1
        obj.units = item.theunits || item.units1
        obj.period = item.stimunitsb
        obj.frequency = item.frequency


        // // dateAdministered: '',
        medicineList.push(obj);
      }
      else {
        const obj = foundDrug[0];
        obj.isAdded = 1;
        const list = medicineList.filter(x => x.drugId != obj.drugId);
        medicineList = [...list, obj];
      }
    });
    setFormObjList(medicineList);

  }

  function extractValuesByKey(key, str) {
    const pairs = str.split(',').map(pair => pair.trim());
    const keyValuePairs = pairs.map(pair => pair.split(':').map(item => item.trim()));
    for (const pair of keyValuePairs) {
      if (pair[0] === key) {
        return pair[1];
      }
    }

    return null; // Key not found in the string
  }


  const addMedication = () => {
    if (formObjList) {
      const existingIndex = formObjList.findIndex(item => item.drugId === formObj.drugId);
      if (existingIndex !== -1) {
        // Remove the existing item and add the new item
        const updatedList = formObjList.filter(item => item.drugId !== formObj.drugId);
        formObj.isEdited = true
        setFormObjList([...updatedList, formObj]);
      } else {
        formObj.isEdited = true
        setFormObjList([...formObjList, formObj]);
      }
    } else {
      setFormObjList([formObj]);
    }

    resetAllFields();
  };


  const createTreatmentPlanList = (List) => {

    var returnObj;
    var returnObjList = [];

    const uniqueIds = [...new Set(List.map(item => item.treatmentPlanID))];

    uniqueIds.map((id) => {

      var FirstTpObject = List.filter(x => x.treatmentPlanID === id)[0];

      returnObj = {
        'treatmentPlanID': id,
        'isCurrent': FirstTpObject.currentPlan,
        'version': FirstTpObject.formVersionMajor + '.' + FirstTpObject.formVersionMinor,
        'artid': FirstTpObject.artid,
        'procedureTypeName': FirstTpObject.procedureTypeName,
        'procedureTypeId': FirstTpObject.procedureTypeId,
        'addedon': FirstTpObject.addedon,
        'comments': FirstTpObject.comments,
        'isIndemnitySigned': FirstTpObject.indemnitySigned,
        'clinicianname': FirstTpObject.clinicianname,
        'items': List.filter(x => x.treatmentPlanID === id)
      }

      returnObjList.push(returnObj);
    })


    return returnObjList;
  }

  function getDateFieldsAsList(obj) {
    // Initialize an empty array to store the result
    const dateFieldsList = [];

    // Iterate over the keys of the input object
    for (const key in obj) {
      // Check if the key starts with 'date'
      if (key.startsWith('date') && key != 'dateAdministered') {
        // Add the key-value pair as an object to the result array
        if (obj[key]) {
          dateFieldsList.push({ [key]: obj[key] });
        }
      }
    }

    return dateFieldsList;
  }



  const saveList = (drugId, shouldPopupStayOpen) => {

    var finalList = [];

    const filteredList = drugId
      ? formObjList.filter(x => x.drugId === drugId && x.drugName)
      : formObjList.filter(x => x.drugName);


    filteredList?.forEach((item) => {
      var finalObj = {}
      finalObj.artid = artData?.artid
      finalObj.dose = item.dosage

      // --------------------------------------------------------------------------------------------------------------------------------------------

      //add following to backend too
      finalObj.TreatmentPlanObjectId = item.drugId
      finalObj.drug = getMedicationType(item.drugId)

      // --------------------------------------------------------------------------------------------------------------------------------------------



      finalObj.drugName = item.drugName
      finalObj.medicationTypeId = item.medTypeId
      finalObj.period = item.period
      finalObj.stimunitsb = item.period
      finalObj.frequency = item.frequency
      finalObj.units = (item.units ? item.units : '') + ' ' + (item.frequency ? item.frequency : '') + ' ' + (item.period ? item.period : '')
      // {(getLabelContent(item, index, med?.obj)?.frequency ? getLabelContent(item, index, med?.obj)?.frequency : '') + ' ' +(getLabelContent(item, index, med?.obj)?.theunits ? getLabelContent(item, index, med?.obj)?.theunits : '') + ' ' + (getLabelContent(item, index, med?.obj)?.units1 ? getLabelContent(item, index, med?.obj)?.units1 : '') + ' ' + (getLabelContent(item, index, med?.obj)?.period ? getLabelContent(item, index, med?.obj)?.period : '') + ' ' + (getLabelContent(item, index, med?.obj)?.stimunitsb ? getLabelContent(item, index, med?.obj)?.stimunitsb : '')}<br />

      if (item.startDate) {
        finalObj.startDate = item.startDate
        finalObj.from = item.startDate
        finalObj.findate = item.startDate
      }
      if (item.endDate) {
        finalObj.endDate = item.endDate
        finalObj.to = item.endDate
        finalObj.findate = item.endDate
      }



      if (item.dateAdministered) {
        finalObj.startDate = item.dateAdministered
        finalObj.endDate = item.dateAdministered
        finalObj.from = item.dateAdministered
        finalObj.to = item.dateAdministered
        finalObj.findate = item.dateAdministered
        finalObj.dayoffset = calculateDifference(artData?.DayofCycle, item.dateAdministered) + 1
      }

      var dates = getDateFieldsAsList(item)

      if (dates?.length > 0) {
        dates.forEach((dateItem) => {
          for (const dateKey in dateItem) {
            finalObj.startDate = dateItem[dateKey];
            finalObj.endDate = dateItem[dateKey];
            finalObj.from = dateItem[dateKey];
            finalObj.to = dateItem[dateKey];
            finalObj.findate = dateItem[dateKey];
            finalObj.dayoffset = calculateDifference(artData?.DayofCycle, dateItem[dateKey]) + 1

            finalList.push(finalObj);
          }
        });
      }
      else if (item.startDate && item.endDate) {
        const totalDays = calculateDifference(item.startDate, item.endDate)
        for (var i = 0; i <= totalDays; i++) {
          var dayoffset = calculateDifference(artData?.DayofCycle, moment(getCorrectDate(item.startDate), 'DD-MM-YYYY').add(i, 'days').format('DD-MM-YYYY')) + 1
          finalList.push({ ...finalObj, dayoffset: dayoffset });
        }
      }
      else {
        finalList.push(finalObj)
      }


    })


    var errors = []
    getUniqueObjectsByKey(finalList, 'drug')?.forEach((item) => {
      if (!item.dose) {
        // console.log('dosage', item)
        errors.push({ drugName: item.drugName, errorType: 'dosage' })
      }
      if (!item.endDate && !item.findate) {
        // console.log('date(s)', item)
        errors.push({ drugName: item.drugName, errorType: 'date(s)' })
      }
    })

    if (!errors.length) {

      ApiCall_POST('Art/SaveStimPlan', finalList, true).then(function (payload) {
        Swal.fire({
          icon: 'success',
          title: 'Stim Plan Saved ',
          text: '',
          showConfirmButton: false,
          timer: 2500,
        });
        // setFormObjList([])
        setFormObj(getInitFormObject())

        ApiCall_GET('Art/GetStimMedicationDetails/' + artid, true).then(function (payload) {
          setMedication(replaceEmptyObjectsWithNull(payload))
          if (!shouldPopupStayOpen) {
            setAddDrugModal(false)
          }
          else {
            ApiCall_GET('ART/GetAllMedicationDetails', true).then(function (medicationDetailsPayload) {
              setMedications(medicationDetailsPayload?.NewMedications?.sort())
              autoAddMedicines(treatmentPlan, medicationDetailsPayload?.NewMedications?.sort(), replaceEmptyObjectsWithNull(payload))
            })
          }

        })

      })

    }
    else {
      var html = ''
      errors?.forEach((item) => {
        html += '<font color="red">Please enter "' + item.errorType + '" for "' + item.drugName + '"</font><br/><br/>'
      })

      Swal.fire({
        icon: "error",
        title: "Please resolve these issues in the medications to continue",
        html: html,
        showCloseButton: true,
        focusConfirm: false,
        confirmButtonText: `Ok`,
      });
    }


  }

  const removeDrug = (drug, shouldPopupStayOpen) => {
    var drugName = (drug.drugName || drug.drugmastername)
    var medTypeId = (drug.medicationTypeId || drug.medTypeId)
    var drugId = (drug.drugId || getMedicationTypeNew(drug.drug))
    Swal.fire({
      title: "Do you want to remove " + drugName + " from the schedule?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No"
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // const newState = formObjList?.filter(x => x.drugId !== item.drugId);
        Swal.fire(drugName + " is removed from the schedule.", "", "info");
        var obj = { artId: artid, drugId: getMedicationType(drugId), medicationTypeId: medTypeId }

        ApiCall_POST('Art/DeleteStimPlan/' + artid + '/' + getMedicationType(drugId) + '/' + medTypeId, obj, true).then(function (payload) {
          Swal.fire({
            icon: 'success',
            title: drugName + ' is removed from the stim plan',
            text: '',
            showConfirmButton: false,
            timer: 2500,
          });
          setFormObj(getInitFormObject())

          ApiCall_GET('Art/GetStimMedicationDetails/' + artid, true).then(function (payload) {
            setMedication(replaceEmptyObjectsWithNull(payload))
            if (!shouldPopupStayOpen) {
              setAddDrugModal(false)
            }
            else {
              ApiCall_GET('ART/GetAllMedicationDetails', true).then(function (medicationDetailsPayload) {
                setMedications(medicationDetailsPayload?.NewMedications?.sort())
                autoAddMedicines(treatmentPlan, medicationDetailsPayload?.NewMedications?.sort(), replaceEmptyObjectsWithNull(payload))
              })
            }
          })
        })

        // setFormObjList(newState)
      } else if (result.isDenied) {
        Swal.fire(drugName + " is not deleted", "", "info");
      }
    });
  };

  const HandleMedTabs = (item) => {

    setActiveMedicTab(item);
  }
  // const handleInputChange = (type) => {
  //   setDateSelectOption(type)
  // }
  // const handlePrint = (type) => {
  //   setDateSelectOption(type)
  // }

  return (

    <div className={theme.thumb + ' ' + theme.track + ' w-full p-2 bg-white flex flex-col overflow-auto scrollbar  scrollbar-thin'} style={{ height: "100%" }} >
      <div className={' flex items-between p-0.5 mb-3 pl-2 rounded  '}>
        <div className='w-2/5 text-left'>
          <label className={' pl-1 text-base font-medium '}>Stim Plan</label>
        </div>
        <div className='w-3/5 text-right flex gap-x-2 justify-end'>
          <button onClick={() => setIsOpenDetails(!isOpenDetails)} id="printButton" className={(isOpenDetails ? "bg-green-600 text-white " : "bg-white text-green-600 ") + "  flex  justify-center items-center  hover:bg-green-600 text-green-600 font-medium hover:text-white py-0.5 px-2 border-[1px] border-gray-300 hover:border-transparent rounded"}>
            <FontAwesomeIcon icon={faEye} />
            <label className='text-sm pl-2 cursor-pointer'> Cycle & TP Details</label>
          </button>
          <button onClick={() => setPopup(true)} id="printButton" className="bg-white flex  justify-center items-center  hover:bg-green-600 text-green-600 font-medium hover:text-white py-0.5 px-2 border-[1px] border-gray-300 hover:border-transparent rounded">
            <svg width="19" height="14" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 5.33333V12C19 12.1768 18.9285 12.3464 18.8013 12.4714C18.674 12.5964 18.5014 12.6667 18.3214 12.6667H15.6071V15.3333C15.6071 15.5101 15.5357 15.6797 15.4084 15.8047C15.2811 15.9298 15.1085 16 14.9286 16H4.07143C3.89146 16 3.71886 15.9298 3.59161 15.8047C3.46435 15.6797 3.39286 15.5101 3.39286 15.3333V12.6667H0.678571C0.498603 12.6667 0.326006 12.5964 0.198749 12.4714C0.0714921 12.3464 0 12.1768 0 12V5.33333C0 4.23083 0.963572 3.33333 2.14853 3.33333H3.39286V0.666667C3.39286 0.489856 3.46435 0.320287 3.59161 0.195262C3.71886 0.070238 3.89146 0 4.07143 0H14.9286C15.1085 0 15.2811 0.070238 15.4084 0.195262C15.5357 0.320287 15.6071 0.489856 15.6071 0.666667V3.33333H16.8515C18.0364 3.33333 19 4.23083 19 5.33333ZM4.75 3.33333H14.25V1.33333H4.75V3.33333ZM14.25 10.6667H4.75V14.6667H14.25V10.6667ZM15.6071 7C15.6071 6.80222 15.5474 6.60888 15.4356 6.44443C15.3238 6.27998 15.1648 6.15181 14.9788 6.07612C14.7928 6.00043 14.5882 5.98063 14.3907 6.01921C14.1933 6.0578 14.0119 6.15304 13.8696 6.29289C13.7272 6.43275 13.6303 6.61093 13.591 6.80491C13.5517 6.99889 13.5719 7.19996 13.6489 7.38268C13.7259 7.56541 13.8564 7.72159 14.0238 7.83147C14.1912 7.94135 14.388 8 14.5893 8C14.8592 8 15.1181 7.89464 15.309 7.70711C15.4999 7.51957 15.6071 7.26522 15.6071 7Z" fill="currentColor" />
            </svg><label className='text-sm pl-1 cursor-pointer'> Print</label>
          </button>
          <button
            // onClick={() => navigate('/coupledetails', { state: { id: coupleid } })}
            className="flex items-center cursor-pointer justify-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:text-black hover:bg-yellow-400 focus:ring-4 focus:ring-gray-200 font-medium rounded text-sm px-3 py-1"
          >
            <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.15716 0.607836C5.26072 0.711393 5.3125 0.834005 5.3125 0.975672C5.3125 1.11734 5.26072 1.23981 5.15716 1.34309L2.12293 4.37732L5.16752 7.42191C5.26417 7.51856 5.3125 7.63938 5.3125 7.78436C5.3125 7.92934 5.26072 8.05361 5.15716 8.15716C5.05361 8.26072 4.93099 8.3125 4.78933 8.3125C4.64766 8.3125 4.52519 8.26072 4.42191 8.15716L0.942381 4.66728C0.900958 4.62586 0.871548 4.58098 0.85415 4.53266C0.836753 4.48433 0.828192 4.43255 0.828468 4.37732C0.828468 4.32209 0.837167 4.27031 0.854564 4.22199C0.871962 4.17366 0.901234 4.12878 0.942381 4.08736L4.43226 0.59748C4.52892 0.500827 4.64808 0.4525 4.78974 0.4525C4.93141 0.4525 5.05388 0.504279 5.15716 0.607836Z" fill="#52575C" />
            </svg>
            <label className='pl-2 cursor-pointer' onClick={() => { navigate('/runningSheet', { state: { coupleid: coupleid, artData: artData, IsCalledByView: IsCalledByView } }) }}> Back to Running Sheet</label>
          </button>
        </div>
      </div>
      {/*  Add Drug popup */}

      {addDrugModal &&
        <div className="  fixed inset-0 z-10 overflow-y-auto delay-500 transition duration-1000 ease-in-out">
          <div style={{ backdropFilter: 'blur(7px)' }} className="fixed inset-0 transition-opacity filter cursor-pointer" aria-hidden="true" onClick={() => { setAddDrugModal(false) }}>
            <div className="absolute inset-0 bg-gray-700 opacity-70  " ></div>
          </div>

          <div className=" h-[90%] top-10 absolute w-11/12 left-[5%] p-2 delay-900 duration-700 bg-white rounded-md">
            <label className={theme.txt_color + ' my-4 mx-2 font-bold cursor-pointer  border-b-[3px] border-b-amber-500'}>Drug Managment</label>
            <svg className={(props?.CloseBlocked ? 'hidden' : '') + ' absolute top-2 right-2 cursor-pointer shadow  font-semibold  '}
              onClick={() => { setAddDrugModal(false) }}
              width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path className="hover:shadow   " d="M16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM12.6 14L9 10.4L5.4 14L4 12.6L7.6 9L4 5.4L5.4 4L9 7.6L12.6 4L14 5.4L10.4 9L14 12.6L12.6 14Z" fill="#ed4545" />
            </svg>
            <div className="flex h-[96%]   delay-900 duration-700 bg-white rounded-md">
              <div className="relative w-full flex h-auto  mx-auto bg-gray-100 rounded-md shadow-lg gap-1 mt-2">
                {/* Left Section Stim Add----------------- */}
                <div className={theme.thumb + ' ' + theme.track + ' w-3/5 px-0.5 h-full overflow-y-scroll scrollbar scrollbar-thin'}>

                  <div className={(formObj?.drugName ? "bg-yellow-50 " : " ") + '  shadow border rounded-md  p-2 bg-white'}>

                    <div className='flex items-center gap-2  pb-3'>
                      <label className="flex items-center  px-2">
                        <input type="radio" className={theme.cheqColor + ' border-0 '} name='medTypeId' checked={formObj?.medTypeId == 1} onChange={(e) => { handleInputChange(e, 1); resetDateField() }} />
                        <span className={theme.txt_color + '  text-sm font-medium  pl-2 '}>Medication</span>
                      </label>
                      <label className="flex items-center  px-2">
                        <input type="radio" className={theme.cheqColor + ' border-0 '} name='medTypeId' checked={formObj?.medTypeId == 2} onChange={(e) => { handleInputChange(e, 2); resetDateField() }} />
                        <span className={theme.txt_color + '  text-sm font-medium  pl-2 '}>Trigger</span>
                      </label>
                      <label className="flex items-center  px-2">
                        <input type="radio" className={theme.cheqColor + ' border-0 '} name='medTypeId' checked={formObj?.medTypeId == 8} onChange={(e) => { handleInputChange(e, 8); resetDateField() }} />
                        <span className={theme.txt_color + '  text-sm font-medium  pl-2 '}>Luteal Phase Support</span>
                      </label>
                    </div>


                    <div className='flex items-center gap-2 my-2 border-b pb-5 px-2'>
                      <select name="drugId" onChange={handleInputChange} value={formObj?.drugId || ''} className="bg-white shadow border w-1/5 text-gray-900 text-sm rounded-lg block  p-1 ">
                        <option disabled="" value="0">- Select Drug -</option>
                        {medications && medications.filter(x => {
                          const medTypeId = formObj?.medTypeId;
                          const parentIdConditions = [
                            medTypeId === 1 && (x.ParentId === ReferenceTypeHeaders.StimMedicationFSH || x.ParentId === ReferenceTypeHeaders.StimMedication || x.ParentId === ReferenceTypeHeaders.AdjuvantTherapy || x.ParentId === ReferenceTypeHeaders.Antagonist),
                            medTypeId === 2 && x.ParentId === ReferenceTypeHeaders.TriggerMedication,
                            medTypeId === 8 && x.ParentId === ReferenceTypeHeaders.LutealPhaseMedication,
                          ];

                          return getMedicationType(x.Id) && parentIdConditions.some(condition => condition);
                        }).map((item, index) => (
                          item?.Label && <option key={item.Id} value={item?.Id}>{item?.Label}</option>
                        ))}

                      </select>

                      <input type='text' name="dosage" onChange={handleInputChange} value={formObj?.dosage || ''} placeholder='Add Dosage' className="bg-white shadow border w-1/5 text-gray-900 text-sm rounded-lg block  p-1 " />

                      {formObj?.medTypeId == 8 ?
                        <select name="frequency" onChange={handleInputChange} value={formObj?.frequency || ''} className="bg-white shadow border w-1/5 text-gray-900 text-sm rounded-lg block  p-1 ">
                          <option disabled="" value="0">- Select Frequency -</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                        </select>
                        : ''}

                      {formObj?.medTypeId != 2 ?
                        <select name="period" onChange={handleInputChange} value={formObj?.period || ''} className="bg-white shadow border w-1/5 text-gray-900 text-sm rounded-lg block  p-1 ">
                          <option disabled="" value={null}>- Select Period -</option>
                          {periodTypes.map((item) => (
                            <option value={item.name}>{item.name}</option>
                          ))}

                        </select>
                        : <input
                          type="date"
                          name='dateAdministered'
                          onChange={handleInputChange}
                          min={getCorrectDateForInput(getCorrectDate(artData?.DayofCycle))}
                          max={getCorrectDateForInput(getDateByRange(artData?.DayofCycle, NumberOfColums))}
                          value={formObj?.dateAdministered || ''}
                          className="bg-white shadow border w-[19%] text-gray-900 text-sm rounded-lg   block  p-1"
                        />}
                    </div>
                    {formObj?.medTypeId != 2 ?
                      <>
                        <label className="flex items-center  px-2">
                          <span className={theme.txt_color + '  text-sm font-medium '}>Specify a Date Range</span>
                        </label>
                        <div className='flex flex-wrap gap-2 p-2'>
                          <input type='date' name="startDate" onChange={handleInputChange} value={formObj?.startDate}
                            min={getCorrectDateForInput(getCorrectDate(artData?.DayofCycle))}
                            max={getCorrectDateForInput(getDateByRange(artData?.DayofCycle, NumberOfColums))}
                            className="bg-white shadow border w-1/5 text-gray-900 text-sm rounded-lg block  p-1 " />

                          <input type='date' name="endDate" onChange={handleInputChange} value={formObj?.endDate}
                            min={formObj?.startDate ? getCorrectDateForInput(getCorrectDate(formObj?.startDate)) : getCorrectDateForInput(getCorrectDate(artData?.DayofCycle))}
                            max={getCorrectDateForInput(getDateByRange(artData?.DayofCycle, NumberOfColums))}
                            className="bg-white shadow border w-1/5 text-gray-900 text-sm rounded-lg block  p-1 " />
                        </div>
                        <div className="flex items-center mt-3 px-2">
                          <label className={theme.txt_color + '  text-sm font-medium   '}>Select Specific Dates</label>
                          <button type="button" onClick={addDateField}
                            className="text-green-800 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-3 py-1 mx-3  "
                          >+ Add Date Field</button>
                        </div>
                      </>
                      : ''}
                    <div className='flex gap-1  border-b'>
                      {formObj?.medTypeId != 2 ?
                        <>
                          <div className='flex flex-wrap w-full gap-1 p-2'>
                            {dateFields.map((fieldName, index) => (
                              <input
                                key={index}
                                type="date"
                                name={fieldName}
                                min={getCorrectDateForInput(getCorrectDate(artData?.DayofCycle))}
                                max={getCorrectDateForInput(getDateByRange(artData?.DayofCycle, NumberOfColums))}
                                onChange={(e) => { handleInputChange(e) }}
                                value={formObj[fieldName] || ''}
                                className="bg-white shadow border w-[19%] text-gray-900 text-sm rounded-lg   block  p-1"
                              />
                            ))}
                          </div>
                        </>
                        : ''}
                    </div>

                    {/* Buttons ---------- */}
                    <div className=' flex justify-end rounded-md  '>
                      <div className={(formObj?.medTypeId != 2 ? ' w-2/5 ' : ' w-full ') + 'flex items-end self-end justify-end p-2 '}>
                        <button type="button" className="text-green-800 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2 mr-2 "
                          onClick={(e) => addMedication()}
                          title='Add or Update item in the list'
                        >Add / Update
                        </button>

                        <button type="button" onClick={() => { resetAllFields(); setAddDrugModal(false); setDateFields(['date1']) }}
                          className="text-red-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2 ">Cancel</button>
                      </div>
                    </div>

                  </div>
                  {/* ---------- */}
                  <div className=' shadow border rounded-md bg-white  p-2 mt-2'>
                    {/* <div className='flex items-center justify-between py-2'> */}
                    <div className="mb-2 xl:flex-nowrap md:flex-wrap  justify-between flex xl:flex-row md:flex-col shadow border rounded-md bg-white p-2 gap-x-2">
                      <div className='flex'>
                        <p className='flex items-center text-gray-500 text-sm font-bold  mr-2'>Legend</p>
                        <div className=' items-center gap-2 py-2 pr-4 rounded border  '>
                          <p className='flex items-center text-gray-500 text-sm '><div className='w-6 h-4 border rounded border-blue-100 mx-2 bg-blue-100'></div> - Autopicked medicines from Treatment Plan</p>
                          <p className='flex items-center text-gray-500 text-sm '><div className='w-6 h-4 my-1 border rounded border-green-200 mx-2 bg-green-100'></div> - Medicines from current Stim Plan</p>
                          <p className='flex items-center text-gray-500 text-sm '><div className='w-6 h-4 border rounded border-yellow-200 mx-2 bg-yellow-100'></div> - Edited/Changed medicines </p>
                        </div>
                      </div>
                      <div className='flex items-end'>
                        <button type="button" className="text-orange-600  bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2 mr-2 "
                          onClick={() => autoAddMedicines(treatmentPlan, medications)}
                          title='Fetch Medications from Treatment Plan'
                        >
                          <FontAwesomeIcon icon={faRefresh} className='mr-2' />
                          Fetch medications
                        </button>
                        <button type="button" className="text-blue-600  bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2 mr-2 "
                          onClick={() => saveList()}
                        >
                          <FontAwesomeIcon icon={faSave} className='mr-2' />
                          Save all medicines to stim plan
                        </button>
                      </div>
                    </div>

                    <div className={'  w-full  flex  py-1 text-white rounded ' + theme.tHedUpdte}>
                      <div style={{ width: '20%', height: "100% " }} className={' flex item-center ml-1  py-1 '}>
                        <p className='text-sm font-bold '>Med Type </p>
                      </div>
                      <div style={{ width: '20%', height: "100% " }} className={' py-1  '}>
                        <p className='text-sm font-bold '>Drug </p>
                      </div>
                      <div style={{ width: '10%', height: "100% " }} className={'  py-1 '}>
                        <p className='text-sm font-bold  '>Dosage</p>
                      </div>
                      <div style={{ width: '8%', height: "100% " }} className={'  py-1 '}>
                        <p className='text-sm font-bold '>Frequency</p>
                      </div>
                      <div style={{ width: '7%', height: "100% " }} className={'  py-1 '}>
                        <p className='text-sm font-bold  '>Period</p>
                      </div>
                      <div style={{ width: '20%', height: "100% " }} className={'  py-1 '}>
                        <p className='text-sm font-bold '>Dates</p>
                      </div>
                      <div style={{ width: '5%', height: "100% " }} className={'  py-1 '}>
                        <p className='text-sm font-bold '>Actions</p>
                      </div>
                    </div>

                    <div className={theme.thumb + ' ' + theme.track + ' h-[380px] overflow-y-scroll scrollbar scrollbar-thin  '}>
                      {formObjList && formObjList?.filter(x => x.drugName).sort((a, b) => a.medTypeId - b.medTypeId).map((item) => (
                        <div className={(item.isEdited ? 'bg-yellow-50' : item.isAdded ? 'bg-green-50' : 'bg-blue-50') + '  w-full  flex  py-1  border-b '} title={item.isAdded ? '~ cannot be updated directly, remove it then add with updated parameters.' : ''}>
                          <div style={{ width: '20%', height: "100% " }} className={' flex item-center ml-1  py-1 '}>
                            <p className='text-sm '>{item.medTypeId == 1 ? 'Stimulation' : item.medTypeId == 2 ? 'Trigger' : item.medTypeId == 8 ? 'Luteal' : ''}</p>
                          </div>
                          <div style={{ width: '20%', height: "100% " }} className={' py-1  '}>
                            <p className='text-sm '>{item.drugName} </p>
                          </div>
                          <div style={{ width: '10%', height: "100% " }} className={'  py-1 '}>
                            <p className='text-sm  '>{item.dosage}</p>
                          </div>
                          <div style={{ width: '8%', height: "100% " }} className={'  py-1 '}>
                            <p className='text-sm  '>{item.frequency ? item.frequency : ''}</p>
                          </div>
                          <div style={{ width: '7%', height: "100% " }} className={'  py-1 '}>
                            <p className='text-sm  '>{item.period ? item.period : ''}</p>
                            {/* <p className='text-sm  '>{item.period ? periodTypes.filter(x => x.id == item.period)[0]?.name : ''}</p> */}
                          </div>
                          <div style={{ width: '20%', height: "100% " }} className={' flex flex-wrap items-center py-1 '}>
                            {item.startDate ?
                              <p className='text-sm border cursor-pointer hover:bg-orange-400 hover:text-white text-orange-400 border-orange-400 p-1 rounded-md'>From <b>{item.startDate}</b> to <b>{item.endDate}</b></p> : ''
                            }
                            {item['date1'] ?
                              Object.entries(item).filter(([key]) => key.startsWith('date')).map((item) => (
                                item[1] && < p className='text-sm border cursor-pointer hover:bg-green-400 hover:text-white text-green-400 border-green-400 p-1 rounded-md'> {item[1]}</p>
                              ))
                              : ''
                            }
                            {item.dateAdministered ?
                              < p className='text-sm border cursor-pointer hover:bg-green-400 hover:text-white text-green-400 border-green-400 p-1 rounded-md'> {item.dateAdministered}</p> : ''
                            }
                          </div>
                          <div style={{ width: '10%', height: "100% " }} className={' flex items-center py-1 gap-2 '}>
                            {item.isAdded ? '' : <p title={"Edit " + item.drugName} className="text-blue-500 bg-white cursor-pointer h-8 border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm flex items-center justify-center px-3" onClick={() => { setFormObj(item) }}><FontAwesomeIcon icon={faEdit} /></p>}
                            {item.isAdded ? '' : <p title={"Delete " + item.drugName} className="text-red-500 bg-white cursor-pointer h-8 border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm flex items-center justify-center px-3" onClick={() => { deleteItem(item) }}><FontAwesomeIcon icon={faTrash} /></p>}
                            <p title={(item.isAdded ? "Remove " : "Add ") + item.drugName + (item.isAdded ? " from " : " to ") + "Stim Plan"} className={(item.isAdded ? " text-red-500 " : " text-green-500 ") + " bg-white cursor-pointer h-8 border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm flex items-center justify-center px-3"} onClick={() => { item.isAdded ? removeDrug(item, true) : saveList(item?.drugId, true) }}>{item.isAdded ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />} </p>

                          </div>
                        </div>
                      ))}

                    </div>
                  </div>

                </div>

                {/* Right Section Tp View----------------- */}
                <div className='w-2/5 px-1 h-full'>
                  {/* <div className={'mb-2 xl:flex md:flex-col shadow border rounded-md bg-white p-2 '}> */}
                  <div className="mb-2 xl:flex-nowrap  flex xl:flex-row shadow border rounded-md p-2 bg-blue-200">
                    <label className=" w-1/3 md:w-1/2 flex flex-col items-center  pr-2 ">
                      <span className={theme.txt_color + ' w-full text-center text-sm font-bold   '}>Day One of Cycle </span>
                      <p className={'  text-sm font-medium w-full text-center'}>{getCorrectDateForInput(artData?.DayofCycle)}</p>
                    </label>
                    <label className=" w-1/3 md:w-1/2 flex flex-col items-center border-x px-2">
                      <span className={theme.txt_color + ' w-full text-sm font-bold text-center '}>Date of Trigger  </span>
                      <p className={'  text-sm font-medium  text-center  '}>{artData?.DayofCycle && artData?.tridate ? getCorrectDateForInput(artData?.tridate) : artData?.DayofCycle ? '(Predicted)' : ''}</p>
                    </label>
                    <label className=" w-1/3 md:w-1/2 flex flex-col items-center  px-2">
                      <span className={theme.txt_color + ' w-full text-sm font-bold  text-center '}>Date of EPU </span>
                      <p className={'  text-sm font-medium  text-center  '}>{artData?.DayofCycle && artData?.dateofEPU ? getCorrectDateForInput(artData?.dateofEPU) : artData?.DayofCycle ? '(Predicted)' : ''}</p>
                    </label>
                  </div>

                  {treatmentPlan ?
                    <TreatmentPlanSummary treatmentPlan={treatmentPlan} />
                    :
                    <div className='w-full text-center text-red-500'>
                      <p>~Treatment Plan not found~</p>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      }


      <div className={'w-full h-full  pt-0 relative'}>

        <div className="mb-2 xl:flex-nowrap  flex xl:flex-row shadow border rounded-md p-2 bg-green-100">
          <label className=" w-1/3 md:w-1/2 flex flex-col items-center  pr-2 ">
            <span className={theme.txt_color + ' w-full text-center text-sm font-bold   '}>Day One of Cycle </span>
            <p className={'  text-sm font-medium w-full text-center'}>{getCorrectDateForInput(artData?.DayofCycle)}</p>
          </label>
          <label className=" w-1/3 md:w-1/2 flex flex-col items-center border-x px-2">
            <span className={theme.txt_color + ' w-full text-sm font-bold text-center '}>Date of Trigger  </span>
            <p className={'  text-sm font-medium  text-center  '}>{artData?.DayofCycle && artData?.tridate ? getCorrectDateForInput(artData?.tridate) : artData?.DayofCycle ? '(Predicted)' : ''}</p>
          </label>
          <label className=" w-1/3 md:w-1/2 flex flex-col items-center  px-2">
            <span className={theme.txt_color + ' w-full text-sm font-bold  text-center '}>Date of EPU </span>
            <p className={'  text-sm font-medium  text-center  '}>{artData?.DayofCycle && artData?.dateofEPU ? getCorrectDateForInput(artData?.dateofEPU) : artData?.DayofCycle ? '(Predicted)' : ''}</p>
          </label>
        </div>


        {isOpenDetails &&

          <div className=' flex' style={{ height: '263px' }} >

            <div className='w-1/2 border mb-2 mr-1 shadow rounded p-2'>


              <div className={theme.thumb + ' ' + theme.track + ' h-full pt-1 overflow-y-scroll scrollbar   scrollbar-thin'}>
                <StatsPanel artData={artData} />
              </div>


            </div>
            <div className='w-1/2 border mb-2 shadow ml-1 rounded p-2'>
              {treatmentPlan ?
                <TreatmentPlanSummary treatmentPlan={treatmentPlan} />
                :
                <div className='w-full text-center text-red-500'>
                  <p>~Treatment Plan not found~</p>
                </div>
              }
            </div>
          </div>
        }

        {/* Bottom */}
        {/* procedures/Medication  table */}
        <div className='border mt-3 shadow rounded p-2  absolute w-full '>

          {!IsCalledByView &&
            <button
              onClick={() => { setAddDrugModal(true); autoAddMedicines(treatmentPlan, medications) }}
              className="flex items-center cursor-pointer justify-center text-green-800 bg-white border border-gray-300 focus:outline-none hover:text-white border-green-500 hover:bg-green-600 focus:ring-2 focus:ring-gray-200 font-medium rounded text-sm px-3 py-1 mb-2 "
            >
              <FontAwesomeIcon icon={faGears} className='mr-2' /> Manage Drug
            </button>
          }
          {/* ist div */}
          <Medications autoAddMedicines={autoAddMedicines} setFormObj={setFormObj} setAddDrugModal={setAddDrugModal} IsCalledByView={IsCalledByView} artid={artid} removeDrug={removeDrug} artData={artData} setMedication={setMedication} NumberOfColums={NumberOfColums} medication={medication} dayOneOfCycle={artData?.DayofCycle} />
        </div>

      </div>

      {popup &&
        <PrintPop setPopup={setPopup} artid={artid} artData={artData} setMedication={setMedication} NumberOfColums={NumberOfColums} medication={medication} dayOneOfCycle={artData?.DayofCycle} />
      }



    </div >

  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { ApiCall_GET, ApiCall_POST }

export default connect(mapStateToProps, mapDispatchToProps)(StimPlan)