import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import AndrologyResultView from './Views/AndrologyResultView/AndrologyResultView';
import AndrologyResultViewTesaPesa from './Views/AndrologyResultViewTesaPesa/AndrologyResultViewTesaPesa';
import { formatTime, formatTimeDifference, getCorrectDate, getUserInformation, replaceEmptyObjectsWithNull, returnNullIfEmpty } from '../../../../common';
import { ApiCall_GET, ApiCall_POST } from '../../../../Redux/Generic/action'

export const AndrologySubView = ({ coupleId, artid, andrologyid, andrologytypeid, openViewModal, setOpenViewModal, ApiCall_GET }, props) => {
    const [andrologyList, setAndrologyList] = useState([])

    useEffect(() => {
        ApiCall_GET('Lab/GetAndrologyList/' + coupleId + (artid ? '/' + artid : '/0'), true).then(function (payload) {
            setAndrologyList(replaceEmptyObjectsWithNull(payload));
        });
    }, [])

    return andrologyList && andrologyList[0] && (
        andrologyList[0]?.andrologytypeid == 7 ?
            <AndrologyResultViewTesaPesa coupleId={coupleId} andrologyid={andrologyList[0]?.andrologyid} andrologytypeid={andrologyList[0]?.andrologytypeid} setOpenForm={setOpenViewModal} />
            : <AndrologyResultView coupleId={coupleId} andrologyid={andrologyList[0]?.andrologyid} andrologytypeid={andrologyList[0]?.andrologytypeid} setOpenForm={setOpenViewModal} />
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { ApiCall_GET }

export default connect(mapStateToProps, mapDispatchToProps)(AndrologySubView)