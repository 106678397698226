import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import logo from '../../../../Assets/images/white.png';
import { formatTime, getCorrectDate, getScanResults } from '../../../../common';
import { getSession } from '../../../../session';
import { useState } from 'react';
import moment from 'moment'

export const ScansPrint = ({ theme, allScans, artid, formData, coupleDetails, handleMedication }) => {
    const [couple, setCouple] = useState(null)
    const user = getSession('user')
    useEffect(() => {
        setCouple(getSession('patient'))
    }, [])

    return (
        <div id="divPrint" className=" flex min-h-screen  w-full p-2 delay-900 duration-700 pagebreak ">
            {/* Header */}
            <div className="relative w-full  mx-auto p-3">
                <div className={` ${theme.navbar} ` + 'flex justify-between   mb-2  p-2'} >

                    <div className='flex items-center justify-end '>
                        <img src={logo} className="logoCFC" alt="img" width='160px' />
                        {/* <p className=' text-white text-base pr-2'>City Fertility Center</p> */}
                        {/* <img src={clinicIcon} className="h-3 pr-1" alt="img" /> */}
                        <p className={`dot-${user?.BrandCode?.toUpperCase()} `} style={{ backgroundColor: `#${user?.ChartingColor}` }}></p>
                        <p className=' text-white  text-sm ml-1'>{user?.LocationName}</p>
                    </div>
                    <div className='flex justify-end text-white items-center '>
                        <label className='  text-sm'>{moment(new Date()).format('ddd DD-MMM-yyyy')}</label>
                        {/* <label className='pl-1  font-semibold'>{moment(currentTime.toString()).format('hh:mm:ss A')}</label> */}
                    </div>

                </div>
                {/* <div className='header-space'></div> */}

                <label className={'mr-4 font-bold text- xl ' + theme.txt_color + ' border-b-[3px] border-b-amber-500 '}>In-Cycle Scan Results</label>

                {/* patient info */}
                <div className='w-full mt-2 shadow mr-1 mt-3 mb-2'>
                    <p className={theme.tHedUpdte + '  text-sm font-medium text-white text-left p-1  rounded-t'}>Patient / Couple Details</p>
                    <div className='flex w-full'>
                        <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2  '>
                            <p className='cursor-pointer text-sbase font-medium   text-gray-700'>Patient </p>
                            <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                <p className={'text-sm text-gray-500  '}>
                                    {couple?.patientFullName}
                                </p>
                                <p className={'text-sm text-gray-500  '}>
                                    <b>DOB: </b> {couple?.patientDOB}
                                </p>
                            </div>
                        </div>
                        <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2 '>
                            <p className='cursor-pointer text-sbase font-medium   text-gray-700'>Partner
                            </p>
                            <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                <p className={'text-sm text-gray-500  '}>
                                    {couple?.partnerFullName}
                                </p>
                                <p className={'text-sm text-gray-500  '}>
                                    <b>DOB: </b>{couple?.partnerDOB}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='flex w-full'>
                        <div className=' text-left  mx-2 flex items-center  py-1    w-1/2 '>
                            <p className=' text-sm font-medium   text-gray-700'>Clinician</p>
                            <p className={'pl-2 text-sm text-gray-500  '}> {couple?.clinicianName}</p>
                        </div>
                        {/* <div className=' text-left  mx-2 flex items-center  py-1      w-1/2 '>
                            <p className=' text-sm font-medium   text-gray-700'></p>
                            <p className={'pl-2 text-sm text-gray-500  '}> {}</p>

                        </div> */}
                        <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2 '>
                            <p className='cursor-pointer text-sbase w-28 font-medium   text-gray-700'>Couple Code
                            </p>
                            <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                <p className={'text-sm text-gray-500  '}>
                                    {couple?.coupleCode}
                                </p>
                                <p className={'text-sm text-gray-500  '}>
                                    <b>{'ART'+artid}</b>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <label className={'mr-4 font-bold text- xl ' + theme.txt_color + ' border-b-[3px] border-b-amber-500 '}>Medication</label>

                <div className={'bg-[#5599ae] flex items-center text-white text-left p-1 mt-2 rounded-t'}>
                    <p className='text-sbase font-medium  '>Current FSH Drugs</p>
                </div>
                <div className={theme.panelBg + '  border'}>
                    {formData?.StimMedication ?
                        handleMedication(formData?.StimMedication)
                        : <p className='px-2 py-1 text-xss text-gray-400 text-center font-bold my-1'>~No Current FSH Drugs found~</p>
                    }
                </div>
                <label className={'mr-4 font-bold text- xl ' + theme.txt_color + ' border-b-[3px] border-b-amber-500 '}>Scan Results</label>
                < div className={'   w-full mt-2 '} style={{ width: "100%" }}>
                    {allScans?.map((item, index) => (
                        <div className={' border-gray-200   border  shadow  rounded shadow border mb-1 '}>
                            <p className={theme.bg_Light + " " + theme.txt_color + ' px-2 py-1 text-sm text-gray-800 font-medium round w-full '}>In-Cycle Scan~ by <i><b>{item.clinicianname}</b></i> {' ~ Added on: ' + getCorrectDate(item.scandate)}</p>

                            <div className='bg-white rounded  pl-3 flex flex-wrap p-1 items-center w-full '>
                                <div className='w-full flex'>
                                    <div className='w-2/6 flex   border-b  py-0.5'>
                                        <label className='text-xss text-black    '>Cycle Day</label>
                                        <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{item.scandayofcycle} </label>
                                    </div>
                                    <div className='w-2/6 flex   border-b  py-0.5'>
                                        <label className='text-xss text-black '>Clinic</label>
                                        <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{item.ClinicName} </label>
                                    </div>
                                    <div className='w-2/6 flex   border-b  py-0.5'>
                                        <label className='text-xss text-black   '>Procedure Location</label>
                                        <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{item.HospitalName} </label>
                                    </div>
                                </div>
                                <div className='w-full flex'>
                                    <div className='w-2/6 flex   border-b  py-0.5'>
                                        <label className='text-xss text-black   '>Uterus</label>
                                        <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{item.result_uterus} </label>

                                    </div>
                                    <div className=' flex w-2/6  border-b  py-0.5'>
                                        <label className='text-xss text-black   '>Next Scan Date</label>
                                        <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{getCorrectDate(item.nextscan)} </label>

                                    </div>
                                    <div className='w-2/6 flex   border-b  py-0.5'>
                                        <label className='text-xss text-black  '> Endometrial Thickness</label>
                                        <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{item.result_endo} </label>
                                    </div>
                                </div>

                                <div className='flex text-gray-500 w-full flex-wrap'>
                                    <label className={" text-sm  font-bold mr-1"} >L : </label>
                                    <label className={" text-sm  overflow-hidden flex   "} style={{ width: '97%' }} >{item.result_l && getScanResults(item.result_l, true)}</label>
                                </div>
                                <div className='flex text-gray-500 w-full  flex-wrap'>
                                    <label className={" text-sm   font-bold mr-1"} >R : </label>
                                    <label className={"text-sm  overflow-hidden  "} style={{ width: '97%' }}  >{item.result_r && getScanResults(item.result_r, true)}</label>
                                    {/* <label className={" text-sm w-52 flex flex-wrap bg-red-300 "} >{item.result_r && getScanResults(item.result_r)}</label> */}
                                </div>
                                <div className='w-full flex   py-0.5'>
                                    <label className='text-xss text-black pr-1  '> Comments:</label>
                                    <textarea
                                        style={{
                                            // overflowY: 'hidden', // Hide scrollbar
                                            height: 'fit-content', // Let the textarea adjust its height based on content
                                            minHeight: 'fit-content', // Minimum height to fit content
                                            resize: 'none', // Prevent manual resizing by the user
                                        }}

                                        rows={item.comments && Math.max(item.comments.split('\n').length, 1)} className='text-xss text-left  font-medium w-full p-1 disabled scrollbar-thin' disabled value={item.comments} />

                                </div>
                            </div>
                            {/* <textarea rows={item.comments && Math.max(item.comments.split('\n').length, 1)} className='text-xss text-left  font-medium w-full p-1 disabled ' disabled value={comments} /> */}
                        </div>

                    ))}

                    {/*  */}
                </div>
                <footer className='flex mt-3 p-2 hidden'>
                    <div className='w-full flex items-center'>
                        <img className='rounded-full  h-14' src={logo} alt={"logo"} />
                        <div className='border-gray-200  p-1 ml-2 '>
                            <p className='text-xss text-left  m-0 text-gray-700 font-medium'>
                                Document generated on 19-JUN-2023 16:10:05 AEST<br />
                                Do NOT rely solely on the information on this print-out. Always check the Current Treatment Plan in the FSF Database for changes.<br />
                                Form version 8.1 saved on 31-OCT-2022 09:41:04 AEST by Michelle Brown. </p>
                        </div>
                    </div>
                </footer>
            </div >
        </div >
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ScansPrint)