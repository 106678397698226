import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useAppContext } from '../../../../../AppContextProvider ';
import { ApiCall_GET } from '../../../../../Redux/Generic/action';
import { NumberExtractor } from '../../../../../common';

export const TreatmentPlanSummary = ({ treatmentPlan, ApiCall_GET }) => {
    const theme = useAppContext().themeDetails;
    const [ReferenceItemList, setReferenceItemList] = useState([])
    const [clinicsList, setClinicsList] = useState([]);


    useEffect(() => {
        if (treatmentPlan) {
            ApiCall_GET('Procedures/GetProcedureItems/0/' + treatmentPlan.treatmentPlanID, true).then(function (ReferenceItemListPayload) {
                ApiCall_GET('UserManagment/GetClinicDetailsList', true).then(function (payload) {
                    setClinicsList(payload)
                });
                setReferenceItemList(ReferenceItemListPayload)
            });
        }
    }, [])


    const displaySummaryItem = (item) => {

        return (
            <label className=' text-sbase text-gray-500 font-medium'>
                {ReferenceItemList?.filter(x => x.parentId === item.id).map((innerItem) => (
                    <label key={innerItem.id} className='flex text-sbase'>
                        {((innerItem.dataTypeId === 1 && ReferenceItemList.filter(x => x.parentId === innerItem.id && x.value !== '' && x.value !== '0' && x.value !== null).length > 0) || ((innerItem.dataTypeId === 3 || innerItem.dataTypeId === 5) && innerItem.value !== '' && innerItem.value !== '0' && innerItem.value !== null) ?
                            (
                                <label className='mr-1 text-sbase'>{innerItem.label + ' .'}</label>
                            )
                            :
                            (((innerItem.dataTypeId === 2 || innerItem.dataTypeId === 9 || innerItem.dataTypeId === 8) && innerItem.value !== '' && innerItem.value !== '0' && innerItem.value !== null ?
                                (<label className='mr-1 text-sbase'>{innerItem.label + " : " + innerItem.value + '  .'}</label>)
                                : (innerItem.dataTypeId === 4 && innerItem.value !== '' && innerItem.value !== '0' && innerItem.value !== null ?
                                    (innerItem.label.toLowerCase() === "when to start antagonist" || innerItem.label.toLowerCase() === "when" || innerItem.label.toLowerCase() === "start" || innerItem.label.toLowerCase() === "finish" || innerItem.label.toLowerCase() === "when first bloods need to be done by" || innerItem.label.toLowerCase() === "when first scan should be booked for" ?
                                        (<label className='mr-1 text-sbase'>{(NumberExtractor(innerItem.value) ? innerItem.label + " : Day-" + innerItem.value + '  .' : innerItem.label + " : " + innerItem.value + '  .')}</label>)
                                        : (<label className='mr-1 text-sbase'>{innerItem.id == 364 ? (innerItem.label + " : " + clinicsList?.filter(x => x.locationid == innerItem?.value)[0]?.ClinicName + '  .') : innerItem.id === 354 ? (innerItem.label + " : " + (NumberExtractor(innerItem?.value) > 0 ? clinicsList?.filter(x => x.hospitalid === Number(innerItem?.value))[0]?.name : innerItem?.value) + '  .') : (innerItem.label + " : " + innerItem.value + '  .')}</label>))
                                    : '')
                            ))
                        )}

                        {displaySummaryItem(innerItem)}</label>

                ))}
            </label>
        )
    }


    return (
        <div className={theme.thumb + ' ' + theme.track + ' xl:h-[90%] md:h-[90%]  sm:h-[80%] 0 p-1 bg-white  rounded-md shadow overflow-y-scroll scrollbar  scrollbar-thin '}>
            <label className='text-base font-medium  py-2'>Treatment Plan <span className={theme.txt_color + ' text-sbase  ml-2 font-medium'}>{'v' + (treatmentPlan?.version)}</span></label>
            {/* Tp Medication Section */}
            <div className=' shadow  my-2'>
                <div className={theme.tHedUpdte + ' text-white text-left p-1  rounded-t'}>
                    <label className='text-sbase font-medium'>Medications</label>
                </div>
                {/* {treatmentPlan && treatmentPlan.items && treatmentPlan.items.filter(x => x.objectTypeId === 2 && treatmentPlan.items?.filter(y => y.parentId === x.id).length > 0)?.map((item) => (
                    <div className={' border-b-[2px]  border-b-gray-200  text-left py-2 p-1 mx-2 flex ' + theme.hoverBg}>
                        <label className='cursor-pointer text-sbase color-green font-bold w-52 text-gray-700'>Stimulation Medication</label>
                        {displaySummaryItem(item)}
                    </div>
                ))} */}

                {ReferenceItemList?.filter(x => x.objectTypeId === 2 && x.parentId === null)?.map((item) => (

                    <div key={item.id} className={' border-b-[2px]  border-b-gray-200  text-left py-2 p-1 mx-2 flex  ' + theme.hoverBg}>
                        <label className=' cursor-pointer text-sbase color-green font-bold w-52 text-gray-700'>{item.label}
                        </label>
                        <label className='text-xss text-gray-500 font-medium'>
                            {displaySummaryItem(item)}
                        </label>
                    </div>

                ))}
            </div>
            {/* -------------------------- */}
            <div className=' shadow py-2'>
                <div className={theme.tHedUpdte + ' text-white text-left p-1  rounded-t'}>
                    <label className='text-sbase font-medium'>Treatment Procedures</label>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200  ' + theme.hoverBg}>
                    <label className='cursor-pointer text-sbase font-bold w-52 text-gray-700'>Procedure Type
                    </label>
                    <label className='text-sbase text-gray-500 font-medium'>
                        {treatmentPlan?.procedureTypeName ? treatmentPlan?.procedureTypeName : <p className='text-red-500 animate-pulse border px-2 rounded border-red-500'>~ Procedure Type option required ~</p>}
                    </label>
                </div>
                {/* {ReferenceItemList?.filter(x => x.objectTypeId === 1 && x.parentId === null)?.map((item) => ( */}

                {ReferenceItemList?.filter(x => x.objectTypeId === 1 && x.parentId === null)?.map((item) => (

                    <div key={item.id} className={' border-b-[2px]  border-b-gray-200  text-left py-2 p-1 mx-2 flex  ' + theme.hoverBg}>
                        <label className=' cursor-pointer text-sbase color-green font-bold w-52 text-gray-700'>{item.label}
                        </label>
                        <label className='text-xss text-gray-500 font-medium'>
                            {displaySummaryItem(item)}
                        </label>
                    </div>

                ))}

                {/* ))} */}

            </div>
            {/* Comments */}
            <div className='w-full my-2'>
                <div className={theme.tHeadColor + ' flex text-white text-left p-2  rounded-t justify-between'}>
                    <label className='text-xss font-medium'>Comments</label>
                    <label className='text-xss font-medium'>Total Comments#
                        {/* {countComment()} */}
                    </label>

                </div>
                <div className={theme.thumb + ' ' + theme.track + ' overflow-y-scroll scrollbar scrollbar-thin border-gray-200  p-2  border border-gray-300 shadow h-40 '}>
                    {/* {TreatmentPlanState && TreatmentPlanState.map((item, index) => (
                    item.comments.length && index > selectedIndex ? */}
                    <div className={' border-gray-200   mx-1 border border-gray-300 shadow  rounded shadow border mt-1 '}>
                        <textarea
                            style={{
                                // overflowY: 'hidden', // Hide scrollbar
                                resize: 'none', // Prevent manual resizing by the user
                            }}
                            disabled
                            rows={treatmentPlan?.comments && Math.max(treatmentPlan.comments.split('\n').length, +1)}
                            className='text-xss text-left  font-medium w-full p-1 disabled  scrollbar-thin'
                            value={treatmentPlan?.comments}
                        />
                    </div>
                    {/* : ''
                  ))} */}
                </div>
            </div>
            {/* ------------------------ */}
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { ApiCall_GET }

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentPlanSummary)