import React, { useState } from 'react'
import { connect } from 'react-redux'
import { ApiCall_POST, ApiCall_GET } from '../../../Redux/Generic/action'
import { useAppContext } from '../../../AppContextProvider ';
import { useEffect } from 'react';
import { formatTimeDifference, getCorrectDate } from '../../../common';

export const ScreeningTestsList = ({ ApiCall_POST, ApiCall_GET, coupleid, isScreeningOpen, setIsScreeningOpen, ...otherProps }) => {
    const theme = useAppContext().themeDetails;

    const [screeningTests, setScreeningTests] = useState([])

    useEffect(() => {

        ApiCall_GET('art/GetScreeningTest/10/' + coupleid, true).then(function (payload) {
            setScreeningTests(payload)
        });

    }, [])

    return (
        isScreeningOpen &&
        <div className="  fixed inset-0 z-10 overflow-y-auto delay-500 transition duration-1000 ease-in-out">
            <div style={{ backdropFilter: 'blur(0px)' }} className="fixed inset-0 transition-opacity filter" aria-hidden="true" onClick={() => setIsScreeningOpen(false)}>
                <div className="absolute inset-0 bg-gray-700 opacity-40  " ></div>
            </div>
            <div className="flex items-center h-screen px-4 py-8 delay-900 duration-700">
                <div className="relative w-1/2 h-auto  p-4 mx-auto bg-gray-100 rounded-md shadow-lg">
                    <svg className={' absolute top-2 right-2 cursor-pointer shadow  font-semibold  '}
                        onClick={() => setIsScreeningOpen(false)}
                        width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="hover:shadow   " d="M16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM12.6 14L9 10.4L5.4 14L4 12.6L7.6 9L4 5.4L5.4 4L9 7.6L12.6 4L14 5.4L10.4 9L14 12.6L12.6 14Z" fill="#ed4545" />
                    </svg>
                    <p className={'mr-4 font-bold text-xxl text-center my-3 ' + theme.txt_color + ' '}>AMH Results</p>
                    {screeningTests ?
                        <>
                            <div className={theme.tHedUpdte + '  w-full  flex items-center  mt-3 '} >
                                <div style={{ width: '10%', height: "100% " }} className={' px-2 py-1 ' + theme.tHedUpdte}>
                                    <p className='text-sm font-medium text-white'>#</p>
                                </div>
                                <div style={{ width: '30%', height: "100% " }} className={' px-2 py-1 ' + theme.tHedUpdte}>
                                    <p className='text-sm font-medium text-white'>Screening Date</p>
                                </div>

                                <div style={{ width: '30%', height: "100% " }} className={'  pr-2 py-1 ' + theme.tHedUpdte}>
                                    <p className='text-sm font-medium text-white'>Type</p>
                                </div>
                                <div style={{ width: '20%', height: "100% " }} className={'  pr-2 py-1 ' + theme.tHedUpdte}>
                                    <p className='text-sm font-medium text-white'>Result</p>
                                </div>
                                <div style={{ width: '20%', height: "100% " }} className={'  pr-2 py-1 ' + theme.tHedUpdte}>
                                    <p className='text-sm font-medium text-white'>Level</p>
                                </div>
                            </div>
                            <div className={' shadow  mb-10'}>
                                {screeningTests?.map((item, index) => (
                                    <div className={' w-full  flex bg-gray-200 mt-0.5 shadow rounded'} >
                                        <div style={{ width: '10%' }} className={'flex items-center px-2 py-1 bg-[#5599ae] border-r'}>
                                            <p className='text-sm font-medium text-white'>{screeningTests.length - index}</p>
                                        </div>

                                        <div style={{ width: '30%' }} className={`flex items-center  border-r border-gray-300 px-2 py-1`}>
                                            <p className='text-sm font-medium pr-2 m-0 '>{getCorrectDate(item?.date)}</p>
                                            <label className='text-xss m-0 pt-1 text-gray-500'>{formatTimeDifference(getCorrectDate(item?.date))}</label>
                                        </div>

                                        <div style={{ width: '30%' }} className='flex items-center border-r border-gray-300 px-2 py-1'>
                                            <p className='text-sm font-medium pr-1 '>{item?.typeOfScreening}</p>
                                        </div>
                                        <div style={{ width: '20%' }} className='flex items-center border-r border-gray-300 px-2 py-1'>
                                            <p className='text-sm '>{item?.result}</p>
                                        </div>
                                        <div style={{ width: '20%' }} className='flex items-center border-r border-gray-300 px-2 py-1'>
                                            <p className='text-sm '>{item?.level}</p>
                                        </div>

                                    </div>
                                ))}
                            </div>
                        </>
                        :
                        <div className='h-60'>
                            <p className='bg-red-200 shadow text-red-800 px-2 py-1 text-xl text-center  rounded border rounded border-red-400'>~ No data to show ~</p>
                        </div>}

                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { ApiCall_POST, ApiCall_GET }

export default connect(mapStateToProps, mapDispatchToProps)(ScreeningTestsList)