import React from 'react';
import StimPrint from './StimPrint';

export default function MiddlePrint({ ApiCall_GET, maxDay, minDay, dayOneOfCycle, artData, artid, medication, NumberOfColums, setMedication, LutealMax, LutealMin }) {
    var maxNumOfColumns = 15;
    var differenceInDays = maxDay - minDay + 1;

    function calculateNumberOfPages(startDate, endDate, daysPerPage) {
        const totalDays = endDate - startDate + 1; // +1 to include both start and end days
        return Math.ceil(totalDays / daysPerPage);
    }

    return (
        <>
            {(() => {
                const columns = [];
                let iterations = calculateNumberOfPages(minDay, maxDay, maxNumOfColumns);
                let tempMinDay = minDay;
                let tempMaxDay = minDay + maxNumOfColumns - 1;

                for (let index = 0; index < iterations; index++) {
                    if (index != 0) {
                        tempMinDay += maxNumOfColumns;
                        tempMaxDay = tempMinDay + maxNumOfColumns - 1;
                    }

                    if (tempMaxDay > maxDay) {
                        tempMaxDay = maxDay;
                    }

                    if (index === iterations - 1 && tempMaxDay - tempMinDay + 1 < maxNumOfColumns) {
                        tempMaxDay = tempMinDay + maxNumOfColumns - 1;
                    }

                    columns.push(
                        <StimPrint
                            key={index}
                            artid={artid}
                            minDay={tempMinDay}
                            maxDay={tempMaxDay}
                            artData={artData}
                            setMedication={setMedication}
                            NumberOfColums={NumberOfColums}
                            medication={medication}
                            dayOneOfCycle={artData?.DayofCycle}
                            isLuteal={false}
                        />
                    );
                }

                if (artData?.PrintLutealMedication) {
                    iterations = calculateNumberOfPages(LutealMin, LutealMax, maxNumOfColumns);
                    tempMinDay = LutealMin;
                    tempMaxDay = LutealMin + maxNumOfColumns - 1;

                    for (let index = 0; index < iterations; index++) {
                        if (index != 0) {
                            tempMinDay += maxNumOfColumns;
                            tempMaxDay = tempMinDay + maxNumOfColumns - 1;
                        }

                        if (tempMaxDay > LutealMax) {
                            tempMaxDay = LutealMax;
                        }

                        if (index === iterations - 1 && tempMaxDay - tempMinDay + 1 < maxNumOfColumns) {
                            tempMaxDay = tempMinDay + maxNumOfColumns - 1;
                        }

                        columns.push(
                            <StimPrint
                                key={index}
                                artid={artid}
                                minDay={tempMinDay}
                                maxDay={tempMaxDay}
                                artData={artData}
                                setMedication={setMedication}
                                NumberOfColums={NumberOfColums}
                                medication={medication}
                                dayOneOfCycle={artData?.DayofCycle}
                                isLuteal={true}
                            />
                        );
                    }
                }



                return columns;
            })()}
        </>
    )
}
