import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useAppContext } from '../../../../../../AppContextProvider ';
import { getSession } from '../../../../../../session';
import { ApiCall_GET } from '../../../../../../Redux/Generic/action'
import logo from '../../../../../../Assets/images/white.png';
import moment from 'moment'
import { getCorrectDate, getUserInformation } from '../../../../../../common';


export const AndrologyResultViewTesaPesaPrint = ({ andrologytypeid, andrologyid, couple, andrology, ReferenceRange, DBNbr }) => {
    const theme = useAppContext().themeDetails;
    const user = getUserInformation()
    const getTitle = (andrologytypeid) => {
        var iconSuffix = ''
        switch (andrologytypeid) {
            case 1: iconSuffix = "Semen Analysis"; break;
            case 2: iconSuffix = "Suitability for ART"; break;
            case 4: iconSuffix = "Intrauterine Insemination"; break;
            case 5: iconSuffix = "Semen Freeze Report"; break;
            case 6: iconSuffix = "Sperm For IVF"; break;
            case 7: iconSuffix = " TESA / PESA"; break;
            default: iconSuffix = "unknown"; break;
        }
        return iconSuffix
    };

    return (


        <div id="divPrint" className=" flex min-h-screen  w-full  delay-900 duration-700 pagebreak ">
            {/* Header */}
            <div className="relative w-full  mx-auto p-3">
                <div className={` ${theme.navbar} ` + 'flex justify-between   mb-2  p-2'} >
                    <div className='flex items-center justify-end '>
                        <img src={logo} className="logoCFC" alt="img" width='160px' />
                        <p className={`dot-${user?.BrandCode?.toUpperCase()} `} style={{ backgroundColor: `#${user?.ChartingColor}` }}></p>
                        <p className=' text-white  text-sm ml-1'>{user?.LocationName}</p>
                    </div>
                    <div className='flex justify-end text-white items-center '>
                        <label className='  text-sm'>{moment(new Date()).format('ddd DD-MMM-yyyy')}</label>
                    </div>
                </div>

                <label className={theme.txt_color + " text-xl  leading-6 font-bold text-gray-900"}>Andrology Report :
                    {getTitle(andrologytypeid)}
                </label>
                <div className='flex   mt-3  mb-2'>
                    <div className='w-8/12  shadow mr-1 '>
                        <p className={theme.tHedUpdte + '  text-sm font-medium text-white text-left p-1  rounded-t'}>Patient / Couple Details</p>
                        <div className='flex w-full'>
                            <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2  '>
                                <p className='cursor-pointer text-sbase font-medium   text-gray-700'>{andrologytypeid == 4 ? 'Partner' : 'Patient'} </p>
                                <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                    <p className={'text-sm text-gray-500  '}>
                                        {couple?.partnerFullName}
                                    </p>
                                    <p className={'text-sm text-gray-500  '}>
                                        <b>DOB: </b>{couple?.partnerDOB}
                                    </p>
                                </div>
                            </div>
                            <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2 '>
                                <div className=' pl-2 flex-wrap justify-between items-center w-full'>

                                    <p className={'text-sm text-gray-500  '}>
                                        <b>Andrology ID : </b> {andrologyid}
                                    </p>
                                    <p className={'text-sm text-gray-500  '}>
                                        <b>Sample # : </b> {andrology?.sample_no}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='flex w-full '>
                            <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2 '>
                                <p className=' text-sm font-medium   text-gray-700'>Address</p>
                                <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                    <p className={'text-sm text-gray-500  '}>
                                        {couple?.partnerAddress}
                                    </p>
                                    <p className={'text-sm text-gray-500  '}>
                                        <b>Phone (H): </b>{couple?.PartnerPhoneHome}
                                        <b>Phone (M): </b>{couple?.PartnerPhoneMobile}
                                    </p>

                                </div>
                            </div>
                            <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2 '>
                                <div className='flex pl-2 flex-wrap justify-between items-center w-full'>

                                    <p className={'text-sm text-gray-500  '}>
                                        <b>Couple Code: </b>{couple?.coupleCode}
                                    </p>
                                    <p className={'text-sm text-gray-500  '}>
                                        <b>Proc Date : </b> {getCorrectDate(andrology?.sampledate)}
                                    </p>
                                </div>
                            </div>



                        </div>
                    </div>
                    <div className='w-4/12  shadow mr-1 '>
                        <p className={theme.tHedUpdte + '  text-sm font-medium text-white text-left p-1  rounded-t'}>Patient / Couple Details</p>
                        <div className='p-1 w-full'>
                            <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                <p className='cursor-pointer text-sbase font-medium   text-gray-700'>Clinician</p>
                                <p className={'text-sm text-gray-500  '}>{couple?.clinicianName}</p>
                            </div>
                            <div className='flex pl-2 flex-wrap justify-between items-center w-full border-b-[1px]  border-b-gray-200'>
                                <p className='cursor-pointer text-sbase font-medium   text-gray-700'>Clinician Ph :</p>
                                <p className={'text-sm text-gray-500  '}>{andrology?.clinicianphone}</p>
                            </div>
                            <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                <p className='cursor-pointer text-sbase font-medium   text-gray-700'>Anaesthetic</p>
                                <p className={'text-sm text-gray-500  '}>{andrology?.tp_anaesthetic}</p>
                            </div>
                            <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                <p className='cursor-pointer text-sbase font-medium   text-gray-700'>Scientist:</p>
                                <p className={'text-sm text-gray-500  '}>{andrology?.sciName}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='w-full mt-2 shadow mr-1 mt-3 mb-2'>
                    <p className={theme.tHedUpdte + ' text-sm font-medium text-white text-left p-1 rounded-t'}>
                        Media Type <span style={{ fontSize: '9px' }}>Tests performed at Room Temperature</span>
                    </p>
                    <div className='flex justify-between items-center w-full px-2 border-b-[1px] border-b-gray-200'>
                        <div className='flex py-1 w-60'>
                            <p className='text-sm font-medium text-gray-700'>Procedure Type:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_type_collection}</div>
                        </div>
                        <div className='flex py-1 w-60'>
                            <p className='text-sm font-medium text-gray-700'>Collected in:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.mediaTypeName}</div>
                        </div>
                        <div className='flex py-1 w-60'>
                            <p className='text-sm font-medium text-gray-700'>Batch:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.batchName}</div>
                        </div>
                    </div>
                    <div className='flex justify-between items-center w-full px-2 border-b-[1px] border-b-gray-200'>
                        <div className='flex py-1 w-60'>
                            <p className='text-sm font-medium text-gray-700'>Expire Date:</p>
                            <div className='pl-2 text-sm text-gray-500'>{getCorrectDate(andrology?.tp_type_expire)}</div>
                        </div>
                        <div className='flex py-1 w-60'>
                            <p className='text-sm font-medium text-gray-700'>For ICSI Today:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_icsi ? "Yes" : "No"}</div>
                        </div>
                        <div className='flex py-1 w-60'>
                            <p className='text-sm font-medium text-gray-700'>Freeze Consent Excess Material:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_fcem ? "Yes" : "No"}</div>
                        </div>
                    </div>
                    <div className='flex justify-between   w-full px-2 border-b-[1px] border-b-gray-200'>
                        <div className=' py-1 w-8/12'>
                            <p className='text-sm font-medium text-gray-700'>Reason for Biopsy:</p>
                            <div className='flex  py-1 w-full'>
                                <textarea
                                    style={{
                                        // overflowY: 'hidden', // Hide scrollbar
                                        resize: 'none', // Prevent manual resizing by the user
                                    }}
                                    disabled
                                    rows={andrology?.comments && Math.max(andrology.comments.split('\n').length, +1)}
                                    className='text-xss border border-gray-300 text-left  font-medium w-full p-1 disabled  scrollbar-thin'
                                    value={andrology?.tp_biopsy_reason}
                                />
                            </div>
                        </div>
                        <div className='pl-2 py-1 justify-end items-end  w-4/12'>
                            <p className='text-sm font-medium text-gray-700'>Consent Form Checked:</p>
                            <div className=' text-sm text-gray-500 mt-5'>__________________________________</div>
                        </div>

                    </div>
                </div>


                <div className='w-full mt-2 shadow mr-1 mt-3 mb-2'>
                    <p className={theme.tHedUpdte + ' text-sm font-medium text-white text-left p-1 rounded-t'}>
                        Procedure
                    </p>
                    <div className='flex justify-between items-center w-full px-2 border-b-[1px] border-b-gray-200'>
                        <div className='flex py-1 w-1/2'>
                            <p className='text-sm font-medium w-80 text-gray-700'>Needle Epididymal (PESA):</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_proc_1 ? 'Yes' : 'No'}</div>
                        </div>
                        <div className='flex py-1 w-1/6'>
                            <p className='text-sm font-medium text-gray-700'>Quality of Sample:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_proc_1_qual}</div>
                        </div>
                        <div className='flex py-1 w-1/6'>
                            <p className='text-sm font-medium text-gray-700'>Blood:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_proc_1_blood}</div>
                        </div>
                    </div>
                    <div className='flex justify-between items-center w-full px-2 border-b-[1px] border-b-gray-200'>
                        <div className='flex py-1 w-1/2'>
                            <p className='text-sm font-medium w-80 text-gray-700'>Needle Seminiferous Tubules (Micro TESA):</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_proc_2 ? 'Yes' : 'No'}</div>
                        </div>
                        <div className='flex py-1 w-1/6'>
                            <p className='text-sm font-medium text-gray-700'>Quality of Sample:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_proc_2_qual}</div>
                        </div>
                        <div className='flex py-1 w-1/6'>
                            <p className='text-sm font-medium text-gray-700'>Blood:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_proc_2_blood}</div>
                        </div>
                    </div>
                    <div className='flex justify-between items-center w-full px-2 border-b-[1px] border-b-gray-200'>
                        <div className='flex py-1 w-1/2'>
                            <p className='text-sm font-medium w-80 text-gray-700'>Open Biopsy Epididymal:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_proc_3 ? 'Yes' : 'No'}</div>
                        </div>
                        <div className='flex py-1 w-1/6'>
                            <p className='text-sm font-medium text-gray-700'>Quality of Sample:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_proc_3_qual}</div>
                        </div>
                        <div className='flex py-1 w-1/6'>
                            <p className='text-sm font-medium text-gray-700'>Blood:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_proc_3_blood}</div>
                        </div>
                    </div>
                    <div className='flex justify-between items-center w-full px-2 border-b-[1px] border-b-gray-200'>
                        <div className='flex py-1 w-1/2'>
                            <p className='text-sm font-medium w-80 text-gray-700'>Open Biopsy Seminiferous Tubules:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_proc_4 ? 'Yes' : 'No'}</div>
                        </div>
                        <div className='flex py-1 w-1/6'>
                            <p className='text-sm font-medium text-gray-700'>Quality of Sample:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_proc_4_qual}</div>
                        </div>
                        <div className='flex py-1 w-1/6'>
                            <p className='text-sm font-medium text-gray-700'>Blood:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_proc_4_blood}</div>
                        </div>
                    </div>
                    <div className='flex justify-between items-center w-full px-2'>
                        <div className='flex py-1 w-1/2'>
                            <p className='text-sm font-medium w-80 text-gray-700'>Micro Surgical Testicular Sperm Extraction (TESE):</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_proc_5 ? 'Yes' : 'No'}</div>
                        </div>
                        <div className='flex py-1 w-1/6'>
                            <p className='text-sm font-medium text-gray-700'>Quality of Sample:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_proc_5_qual}</div>
                        </div>
                        <div className='flex py-1 w-1/6'>
                            <p className='text-sm font-medium text-gray-700'>Blood:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_proc_5_blood}</div>
                        </div>
                    </div>
                </div>


                <div className='w-full mt-2 shadow mr-1 mt-3 mb-2'>
                    <p className={theme.tHedUpdte + ' text-sm font-medium text-white text-left p-1 rounded-t'}>
                        Sample Analysis
                    </p>
                    <div className='flex justify-between items-center w-full px-2 border-b-[1px] border-b-gray-200'>
                        <div className='flex py-1 w-1/3'>
                            <p className='text-sm w-40 font-medium text-gray-700'>Motile Sperm Detected:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_motile ? 'Yes' : 'No'}</div>
                        </div>
                        <div className='flex py-1 w-1/3'>
                            <p className='text-sm font-medium text-gray-700'>Sperm Motility:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_Motility ? andrology?.tp_Motility.toFixed(0) : 0}</div>
                            <p className='text-sm font-medium text-gray-700'>%</p>
                        </div>
                    </div>
                    <div className='flex justify-between items-center w-full px-2 border-b-[1px] border-b-gray-200'>
                        <div className='flex py-1 w-1/3'>
                            <p className='text-sm w-40 font-medium text-gray-700'>Immotile Sperm Detected:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_immotile ? 'Yes' : 'No'}</div>

                        </div>
                        <div className='flex py-1 w-1/3'>
                            <p className='text-sm font-medium text-gray-700'>Sperm Morphology:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_morphology ? andrology?.tp_morphology.toFixed(0) : 0}</div>
                            <p className='text-sm font-medium text-gray-700'>%</p>
                        </div>
                    </div>
                    <div className='flex justify-between items-center w-full px-2'>
                        <div className='flex py-1 w-1/3'>
                            <p className='text-sm w-40 font-medium text-gray-700'>No Sperm Detected:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_no_sperm ? 'Yes' : 'No'}</div>
                        </div>
                        <div className='flex py-1 w-1/3'>
                            <p className='text-sm font-medium text-gray-700'>Suitable for ICSI:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_icsi_suitable ? 'Yes' : 'No'}</div>

                        </div>
                    </div>
                </div>

                <div className="w-full  shadow text-sm mr-1  my-2">
                    <p className={theme.tHedUpdte + '   font-medium text-white text-left p-1  rounded-t'}>Comments</p>
                    <textarea
                        style={{
                            // overflowY: 'hidden', // Hide scrollbar
                            resize: 'none', // Prevent manual resizing by the user
                        }}
                        disabled
                        // rows={andrology?.comments && Math.max(andrology.comments.split('\n').length, +2)}
                        className='text-xss text-left  font-medium w-full p-1   scrollbar-thin'
                        value={andrology?.comments}
                    />
                </div>


                <div className='w-full mt-2 shadow mr-1 mt-3 mb-2'>
                    <p className={theme.tHedUpdte + ' text-sm font-medium text-white text-left p-1 rounded-t'}>
                        Cryopreservation
                    </p>
                    <div className='flex justify-between items-center w-full px-2 border-b-[1px] border-b-gray-200'>
                        <div className='flex py-1 w-1/10'>
                            <p className={theme.tHedUpdte + ' text-white p-1 rounded-md text-sm font-medium text-gray-700'}>Freeze Details</p>
                        </div>
                        <div className='flex py-1  w-1/4 '>
                            <p className='text-sm font-medium text-gray-700'>Excess Material for Cryopreservation:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_cryopreservation}</div>
                        </div>
                        <div className='flex py-1  w-1/4'>
                            <p className='text-sm font-medium text-gray-700'>Freeze Method:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_method}</div>
                        </div>
                        <div className='flex py-1  w-1/4'>
                            <p className='text-sm font-medium text-gray-700'>Number of Straws or Vials Frozen</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_vails_frozen}</div>
                        </div>
                    </div>
                    <div className='flex flex-col w-full px-2'>

                        <div className='flex flex-wrap justify-between items-center w-full border-b-[1px] border-b-gray-200'>
                            <div className='flex py-1 w-1/4'>
                                <p className='text-sm font-medium text-gray-700'>Goblet Colour:</p>
                                <div className='pl-2 text-sm text-gray-500'>{andrology?.tp_goblit_colour}</div>
                            </div>
                            <div className='flex py-1 w-1/4 '>
                                <p className='text-sm font-medium text-gray-700'>Tank:</p>
                                <div className='pl-2 text-sm text-gray-500'>{andrology?.storagetankname}</div>
                            </div>
                            <div className='flex py-1 w-1/4'>
                                <p className='text-sm font-medium text-gray-700'>Position:</p>
                                <div className='pl-2 text-sm text-gray-500'>{andrology?.storagelocationname}</div>
                            </div>
                            <div className='flex py-1 w-1/4'>
                                <p className='text-sm font-medium text-gray-700'>Scientist:</p>
                                <div className='pl-2 text-sm text-gray-500'>{andrology?.freezeSciName}</div>
                            </div>
                        </div>
                    </div>
                </div>




                <div className='w-full mt-2 shadow mr-1 mt-3 mb-2'>
                    <p className={`${theme.tHedUpdte} text-sm font-medium text-white text-left p-1 rounded-t`}>
                        Witnessing
                    </p>
                    <div className='flex flex-wrap w-full px-2 border-b-[1px] border-b-gray-200'>
                        <div className='flex py-1 w-1/2 '>
                            <p className={theme.tHedUpdte + ' text-white p-1 rounded-md text-sm font-medium text-gray-700'}>Patient Identification</p>
                        </div>
                        <div className='flex py-1 w-1/2'>
                            <p className={theme.tHedUpdte + ' text-white p-1 rounded-md text-sm font-medium text-gray-700'}>Sample Cryopreservation (If Applicable)</p>
                        </div>
                    </div>
                    <div className='flex flex-wrap w-full px-2 border-b-[1px] border-b-gray-200'>
                        <div className='flex py-1 w-1/3'>
                            <p className='text-sm font-medium text-gray-700'>Scientist:</p>
                            <div className='pl-2 text-sm text-gray-500'> ________________</div>
                        </div>
                        <div className='flex py-1 w-1/6 '>
                            <p className='text-sm font-medium text-gray-700'>Date:</p>
                            <div className='pl-2 text-sm text-gray-500'> __________ </div>
                        </div>
                        <div className='flex py-1 w-1/3'>
                            <p className='text-sm font-medium text-gray-700'>Scientist:</p>
                            <div className='pl-2 text-sm text-gray-500'> ________________</div>
                        </div>
                        <div className='flex py-1 w-1/6'>
                            <p className='text-sm font-medium text-gray-700'>Date:</p>
                            <div className='pl-2 text-sm text-gray-500'> __________</div>
                        </div>
                    </div>
                    <div className='flex flex-wrap w-full px-2 border-b-[1px] border-b-gray-200'>
                        <div className='flex py-1 w-1/3'>
                            <p className='text-sm font-medium text-gray-700'>Witness:</p>
                            <div className='pl-2 text-sm text-gray-500'> ________________</div>
                        </div>
                        <div className='flex py-1 w-1/6 '>
                            <p className='text-sm font-medium text-gray-700'>Date:</p>
                            <div className='pl-2 text-sm text-gray-500'> __________ </div>
                        </div>
                        <div className='flex py-1 w-1/3'>
                            <p className='text-sm font-medium text-gray-700'>Witness:</p>
                            <div className='pl-2 text-sm text-gray-500'> ________________</div>
                        </div>
                        <div className='flex py-1 w-1/6'>
                            <p className='text-sm font-medium text-gray-700'>Date:</p>
                            <div className='pl-2 text-sm text-gray-500'> __________</div>
                        </div>
                    </div>
                    <div className='flex flex-wrap w-full px-2 border-b-[1px] border-b-gray-200'>
                        <div className='flex py-1 w-1/2 '>
                            <p className={theme.tHedUpdte + ' text-white p-1 rounded-md text-sm font-medium text-gray-700'}>Sample Preparation for Microinjection</p>

                        </div>
                        <div className='flex py-1 w-1/2'>
                            <p className={theme.tHedUpdte + ' text-white p-1 rounded-md text-sm font-medium text-gray-700'}>Scientific Report Completed</p>
                        </div>
                    </div>
                    <div className='flex flex-wrap w-full px-2 border-b-[1px] border-b-gray-200'>
                        <div className='flex py-1 w-1/3'>
                            <p className='text-sm font-medium text-gray-700'>Scientist:</p>
                            <div className='pl-2 text-sm text-gray-500'> ________________</div>
                        </div>
                        <div className='flex py-1 w-1/6 '>
                            <p className='text-sm font-medium text-gray-700'>Date:</p>
                            <div className='pl-2 text-sm text-gray-500'> __________ </div>
                        </div>
                        <div className='flex py-1 w-1/3'>
                            <p className='text-sm font-medium text-gray-700'>Report By:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.reportBy}</div>
                        </div>
                        <div className='flex py-1 w-1/6'>
                            <p className='text-sm font-medium text-gray-700'>Date:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.reportDate}</div>
                        </div>
                    </div>
                    <div className='flex flex-wrap w-full px-2 border-b-[1px] border-b-gray-200'>
                        <div className='flex py-1 w-1/3'>
                            <p className='text-sm font-medium text-gray-700'>Witness:</p>
                            <div className='pl-2 text-sm text-gray-500'> ________________</div>
                        </div>
                        <div className='flex py-1 w-1/6  '>
                            <p className='text-sm font-medium text-gray-700'>Date:</p>
                            <div className='pl-2 text-sm text-gray-500'> __________ </div>
                        </div>
                        <div className='flex py-1 w-1/3'>
                            <p className='text-sm font-medium text-gray-700'>Report By:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.verifyBy}</div>
                        </div>
                        <div className='flex py-1 w-1/6'>
                            <p className='text-sm font-medium text-gray-700'>Date:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.verifyDate}</div>
                        </div>
                    </div>
                </div>

                {/* ----------- */}
                <footer className='flex mt-3 p-2 hidden'>
                    <div className='w-full flex items-center'>
                        <img className='rounded-full  h-14' src={logo} alt={"logo"} />
                        <div className='border-gray-200  p-1 ml-2 '>
                            <p className='text-xss text-left  m-0 text-gray-700 font-medium'>
                                Document generated on 19-JUN-2023 16:10:05 AEST<br />
                                Do NOT rely solely on the information on this print-out. Always check the Current Treatment Plan in the FSF Database for changes.<br />
                                Form version 8.1 saved on 31-OCT-2022 09:41:04 AEST by Michelle Brown. </p>
                        </div>
                    </div>
                </footer>
            </div >
        </div >
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { ApiCall_GET }

export default connect(mapStateToProps, mapDispatchToProps)(AndrologyResultViewTesaPesaPrint)


