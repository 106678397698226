import React from 'react'
import { connect } from 'react-redux'
import { useAppContext } from '../../../../../AppContextProvider ';
import { getCorrectDate, returnNullIfEmpty } from '../../../../../common';

export const StatsPanel = ({ artData, ...props }) => {
    const theme = useAppContext().themeDetails;
    artData = returnNullIfEmpty(artData)
    const cycleType = artData?.cycletype?.split(' ')[0]?.trim()
   
    return (
        <div >
            <div className="  pb-2  rounded-t-lg  ">
                <div className="text-base font-medium">Cycle and Therapy Overview</div>
            </div>
            <div className=' shadow border'>
                <div className={theme.tHedUpdte + ' text-white text-left p-1 rounded-t'}>
                    <label className='text-sbase font-medium'>Main Details</label>
                </div>
                <div className='p-2 border-gray-100 flex flex-wrap'>
                    <div className='text-sbase w-2/6 py-1 flex items-center'><div className='w-36 font-bold' >Clinician:</div> {artData?.Doctor}</div>
                    <div className='text-sbase w-2/6 py-1 flex items-center'><div className='w-36 font-bold' >Procedure Type:</div> {artData?.cycletype}</div>
                    <div className='text-sbase w-2/6 py-1 flex items-center'><div className='w-36 font-bold' >{cycleType} Type:</div> {cycleType + ' ' + (cycleType == 'FOT' || cycleType == 'FET' ? artData?.fettype : artData?.typeofIVFscycle)}</div>
                    <div className='text-sbase w-2/6 py-1 flex items-center'><div className='w-36 font-bold' >Entered Date:</div>  {getCorrectDate(artData?.thelogdate)}</div>
                    <div className='text-sbase w-2/6 py-1 flex items-center'><div className='w-36 font-bold' >Day 1 of Cycle:</div> {getCorrectDate(artData?.DayofCycle)}</div>
                    <div className='text-sbase w-2/6 py-1 flex items-center'><div className='w-36 font-bold' >FSH Commence Date:</div> {artData?.fsh_CommenceDate}</div>
                </div>
            </div>
            <div className='mt-1  shadow border'>
                <div className={theme.tHedUpdte + ' text-white text-left p-1 rounded-t'}>
                    <label className='text-sbase font-medium'>Transfer Details</label>
                </div>
                <div className="p-2 border-gray-100 flex flex-wrap">
                    <div className="text-sbase w-2/6 py-1 flex items-center">
                        <div className="w-36 font-bold">INSEM Date:</div>
                        <div>{getCorrectDate(artData?.insemdate)}</div>
                    </div>
                    <div className="text-sbase w-2/6 py-1 flex items-center">
                        <div className="w-36 font-bold">Date of Thaw:</div>
                        <div>{getCorrectDate(artData?.dateofFET)}</div>
                    </div>
                    <div className="text-sbase w-2/6 py-1 flex items-center">
                        <div className="w-36 font-bold">Date of EPU:</div>
                        <div>{getCorrectDate(artData?.dateofEPU)}</div>
                    </div>
                    <div className="text-sbase w-2/6 py-1 flex items-center">
                        <div className="w-36 font-bold"># of Follicles:</div>
                        <div>{artData?.numberoffollicles}</div>
                    </div>
                    <div className="text-sbase w-2/6 py-1 flex items-center">
                        <div className="w-36 font-bold">Date of Transfer:</div>
                        <div>{getCorrectDate(artData?.dateofET)}</div>
                    </div>
                    <div className="text-sbase w-2/6 py-1 flex items-center">
                        <div className="w-36 font-bold">Endometrial Thickness:</div>
                        <div>{artData?.endometrialthickness}</div>
                    </div>

                    <div className="text-sbase w-2/6 py-1 flex items-center">
                        <div className="w-36 font-bold">Speculum Size:</div>
                        <div>{artData?.speculumSize}</div>
                    </div>
                    <div className="text-sbase w-2/6 py-1 flex items-center">
                        <div className="w-36 font-bold">Speculum Width:</div>
                        <div>{artData?.speculumWidth}</div>
                    </div>
                    <div className="text-sbase w-2/6 py-1 flex items-center">
                        <div className="w-36 font-bold">Speculum Placement:</div>
                        <div>{artData?.speculumPlacement}</div>
                    </div>
                    <div className="text-sbase w-4/6 py-1 flex items-center">
                        <div className="w-52 font-bold">Max. Oestradiol prior to EPU:</div>
                        <div>{artData?.e2trigger}</div>
                    </div>
                </div>

            </div>
            <div className=' mt-1 shadow border'>
                <div className={theme.tHedUpdte + ' text-white text-left p-1 rounded-t'}>
                    <label className='text-sbase font-medium'>Metrics</label>
                </div>
                <div className='p-2 border-gray-100 flex flex-wrap'>
                    <div className="text-sbase w-2/6  py-1 flex items-center">
                        <div className="w-36 font-bold">Previous 13200:</div>
                        <div>{artData?.the13200}</div>
                    </div>
                    <div className="text-sbase  w-2/6  py-1 flex items-center">
                        <div className="w-36 font-bold" >&gt; 20:</div>
                        <div>{artData?.greater}</div>
                    </div>
                    <div className="text-sbase  w-2/6  py-1 flex items-center">
                        <div className="w-36 font-bold">&lt; 20:</div>
                        <div>{artData?.lesser}</div>
                    </div>
                    <div className="text-sbase  w-3/6  py-1 flex items-center">
                        <div className="w-64 font-bold">Progesterone Level on day of trigger:</div>
                        <div>{artData?.progesteronelevel}</div>
                    </div>
                    <div className="text-sbase  w-3/6  py-1 flex items-center">
                        <div className="w-56 font-bold">Scheduled Pregnancy Test Date:</div>
                        <div>{artData?.ShedPregTestDate}</div>
                    </div>
                </div>
            </div>
            {artData?.cycletype?.includes("FET") ?
                <div className=' mt-1 shadow border'>
                    <div className={theme.tHedUpdte + ' text-white text-left p-1 rounded-t'}>
                        <label className='text-sbase font-medium'>GAMETE SOURCES for Thaw</label>
                    </div>
                    <div className='p-2 border-gray-100 flex flex-wrap'>
                        <div className="text-sbase w-2/6  py-1 flex items-center">
                            <div className="w-36 font-bold">Semen Source:</div>
                            <div>{artData?.spermdonor_id && artData?.SpermDonorCode}</div>
                        </div>
                        <div className="text-sbase  w-2/6  py-1 flex items-center">
                            <div className="w-36 font-bold" >Oocyte Source:</div>
                            <div>{artData?.oocytedonor_id && artData?.OocyteDonorCode}</div>
                        </div>
                    </div>
                </div> :
                <div className='mt-1 shadow border'>
                    <div className={theme.tHedUpdte + ' text-white text-left p-1 rounded-t'}>
                        <label className='text-sbase font-medium'>Source Details</label>
                    </div>
                    <div className="p-2 border-gray-100 flex flex-wrap">
                        <div className="text-sbase w-2/6 py-1 flex items-center">
                            <div className="w-36 font-bold">Semen Source:</div>
                            <div>{artData?.spermSource}</div>
                        </div>
                        <div className="text-sbase w-2/6 py-1 flex items-center">
                            <div className="w-36 font-bold">Donor Sperm:</div>
                            <div>{artData?.spermdonor_id ? 'Yes' : 'No'} <br></br> {artData?.SpermDonorCode}</div>
                        </div>
                        <div className="text-sbase w-2/6 py-1 flex items-center">
                            <div className="w-36 font-bold">Sperm Prep:</div>
                            <div>{artData?.semenpreptype} <br></br> {getCorrectDate(artData?.semenprepdate)}</div>
                        </div>
                        <div className="text-sbase w-2/6 py-1 flex items-center">
                            <div className="w-36 font-bold">Donor Embryo:</div>
                            <div>{artData?.embryodonor_id ? 'Yes' : 'No'} <br></br> {artData?.DonorEmbryoCode}</div>
                        </div>
                        <div className="text-sbase w-2/6 py-1 flex items-center">
                            <div className="w-36 font-bold">Donor Oocyte:</div>
                            <div>{artData?.oocytedonor_id ? 'Yes' : 'No'} <br></br> {artData?.OocyteDonorCode}</div>
                        </div>
                        <div className="text-sbase w-2/6 py-1 flex items-center">
                            <div className="w-36 font-bold">Epididymal Sperm:</div>
                            <div>{artData?.nurseepididymalsperm}</div>
                        </div>
                        <div className="text-sbase w-2/6 py-1 flex items-center">
                            <div className="w-36 font-bold">Testicular Sperm:</div>
                            <div>{artData?.nursetesticularsperm}</div>
                        </div>
                    </div>

                </div>
            }
            <div className='mt-1 shadow border'>
                <div className={theme.tHedUpdte + ' text-white text-left p-1 rounded-t'}>
                    <label className='text-sbase font-medium'>Rebate Min Gap Tracking</label>
                </div>
                <div className="p-2 border-gray-100 flex flex-wrap">
                    <div className="text-sbase w-2/6 py-1 flex items-center">
                        <div className="w-36 font-bold">Rebate Type:</div>
                        <div>{artData?.RebatemingapName}</div>
                    </div>
                    <div className="text-sbase w-2/6 py-1 flex items-center">
                        <div className="w-36 font-bold">Date Billed:</div>
                        <div>{getCorrectDate(artData?.rebate_mingap_billed_date)}</div>
                    </div>
                    <div className="text-sbase w-2/6 py-1 flex items-center">
                        <div className="w-36 font-bold">Medicare Received:</div>
                        <div>{getCorrectDate(artData?.rebate_mingap_medicare_date)}</div>
                    </div>
                    <div className="text-sbase w-2/6 py-1 flex items-center">
                        <div className="w-36 font-bold">Genie Received:</div>
                        <div>{getCorrectDate(artData?.rebate_mingap_genie_date)}</div>
                    </div>
                    <div className="text-sbase w-2/6 py-1 flex items-center">
                        <div className="w-36 font-bold">Medicare reference:</div>
                        <div>{artData?.rebate_mingap_medicare_ref}</div>
                    </div>
                    <div className="text-sbase w-2/6 py-1 flex items-center">
                        <div className="w-36 font-bold">Amount Claimed:</div>
                        <div>{artData?.rebate_mingap_amount_claimed}</div>
                    </div>
                    <div className="text-sbase w-2/6 py-1 flex items-center">
                        <div className="w-36 font-bold">Amount Received:</div>
                        <div>{artData?.rebate_mingap_amount_received}</div>
                    </div>
                    <div className="text-sbase w-2/6 py-1 flex items-center">
                        <div className="w-36 font-bold">Rebate / Min Gap box:</div>
                        <div>{artData?.rebate_mingap_box}</div>
                    </div>
                </div>

            </div>
            <div className='mt-1 shadow border'>
                <div className={theme.tHedUpdte + ' text-white text-left p-1 rounded-t'}>
                    <label className='text-sbase font-medium'>Comments (Outcome)</label>
                </div>
                <div className='p-2 border-gray-100 flex flex-wrap'>
                    <textarea className='w-full text-sm' rows={5} disabled={true}>{artData?.outcome}</textarea>
                </div>
            </div>

        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(StatsPanel)