import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ApiCall_POST, ApiCall_GET } from '../../../../../../Redux/Generic/action';
import Swal from 'sweetalert2';
import { TaskTypes } from '../../../../../../common';

export const AddTask = ({ ApiCall_POST, ApiCall_GET, theme,  isAddTaskShown, setIsAddTaskShowns, displayData, ...otherProps }, props) => {
    const [taskTypes, setTaskTypes] = useState([]);
    const [formData, setFormData] = useState(clearForm());
    const [selectedTaskTypeId, setSelectedTaskTypeId] = useState(0);
    const [taskError, setTaskError] = useState(false);
    const dayOfOneTaskId = 2;


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTaskError(false)

        if (name === 'task') {

            const selectedOption = e.target.options[e.target.selectedIndex];
            var subTaskId = selectedOption.getAttribute("data-subtaskid");
            setSelectedTaskTypeId(Number(subTaskId));

            setFormData((prevData) => ({
                ...prevData,
                subTask: subTaskId,
            }));

        }


        if (Number(value) === dayOfOneTaskId && name === 'task') {
            setFormData((prevData) => ({
                ...prevData,
                ['day']: 1,
            }));
            setFormData((prevData) => ({
                ...prevData,
                ['status']: 0,
            }));
        }

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    function clearForm() {
        return {
            day: null,
            task: '',
            subTask: null,
            followUpDate: null,
            status: '',
            taskComments: null,
            taskNotes: null,
        }
    }

    const handleSubmit = () => {

        setTaskError(false)


        if (!formData.task || formData.task === '0') {
            setTaskError(true)
            return
        }
        const postData = {
            dayNum: formData.day,
            taskId: formData.task,
            subTaskId: formData.subTask,
            followUpDate: formData.followUpDate,
            status: formData.status,
            comment: formData.taskComments,
            result: '',
            taskNotes: formData.taskNotes,
            artId: otherProps.artid,
            coupleId: otherProps.coupleId,
            treatmentPlanId: otherProps.treatmentPlanId
        };

        // Call the API using ApiCall_POST and postData
        ApiCall_POST('Art/SaveTPTask', postData, true)
            .then((response) => {
                // Handle success
                Swal.fire({
                    icon: 'success',
                    title: 'Task created successfully!',
                    showConfirmButton: false,
                    timer: 1000
                })

                setIsAddTaskShowns(false)
                displayData(response)
            })
            .catch((error) => {
                // Handle error
                console.error('Error adding task', error);
            });
    };


    useEffect(() => {

        ApiCall_GET('Art/LoadTaskTypeList/' + otherProps.artid, true).then(function (payload) {
            setTaskTypes(payload);
        });
    }, []);

    return (
        <div className={theme.tableBg + ' bg-gray-50 my-2 shadow rounded p-3'}>
            <div className=' w-full flex'>
                {/* Day */}
                <div className='w-1/12 mr-1'>
                    <p className='text-sm font-medium mb-1'>Day</p>
                    <select
                        className='border shadow rounded p-2.5 text-sm text-gray-800 w-full focus:ring-green-500 focus:border-green-500'
                        name='day'
                        value={formData.day}
                        onChange={handleInputChange}
                        disabled={formData.task === 2}
                    >
                        <option value="0">--None--</option>
                        {Array.from({ length: 60 }, (_, index) => (
                            <option key={index + 1} value={index + 1}>
                                Day - {index + 1}
                            </option>
                        ))}
                    </select>
                </div>

                <div className='w-3/12 mr-1'>
                    <p className='text-sm font-medium mb-1'>Task<span className='text-red-600'>*</span></p>
                    <select className={"border shadow rounded p-2.5 text-sm  text-gray-800 w-full focus:ring-green-500 focus:border-green-500  " + (taskError && ' border-red-400 ')}
                        name='task'
                        value={formData.task}
                        onChange={(e) => { handleInputChange(e); }}
                    >
                        <option value="0">--None--</option>
                        {taskTypes.filter(item => !taskTypes.some(subItem => subItem.SubTaskId === item.Id) && item.Id !== TaskTypes.TreatmentPlanCreated && item.Id !== TaskTypes.TreatmentPlanRevised).map((item) => (
                            <option key={item.Id} value={item.Id} data-subtaskid={item.SubTaskId}>
                                {item.Name}
                            </option>
                        ))}
                    </select>
                    {taskError && <span className="bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">Task is required!</span>}
                </div>

                <div className='w-3/12 mr-1'>
                    <p className='text-sm font-medium mb-1'>Sub Task</p>
                    <select className="border shadow rounded p-2.5 text-sm  text-gray-800 w-full focus:ring-green-500 focus:border-green-500"
                        name='subTask'
                        value={formData.subTask}
                        onChange={handleInputChange}
                    >
                        <option value={null}>--None--</option>
                        {taskTypes.filter(item => item.Id === selectedTaskTypeId).map((item) => (
                            <option selected={item.Id === selectedTaskTypeId} key={item.Id} value={item.Id}>
                                {item.Name}
                            </option>
                        ))}
                    </select>
                </div>

                {/* <div className='w-3/12  mr-1'>
                    <p className='text-sm font-medium mb-1'>Follow-Up Date</p>
                    <input type='date'
                        name='followUpDate'
                        value={formData.followUpDate}
                        onChange={handleInputChange}
                        className='border shadow rounded  p-2 text-sm  text-gray-800 w-full focus:ring-green-500 focus:border-green-500' />
                </div> */}

                <div className='w-3/12 mr-1'>
                    <p className='text-sm font-medium mb-1'>Status <span className='text-red-600'>*</span></p>
                    <select className="border shadow rounded p-2.5 text-sm  text-gray-800 w-full focus:ring-green-500 focus:border-green-500"
                        name='status'
                        value={formData.status}
                        onChange={handleInputChange}
                        disabled={formData.task === 2}
                    >
                        {/* <option value="">Select Status</option> */}
                        <option value="0">Pending</option>
                        <option value="1">Done</option>
                    </select>
                </div>



                <div className='w-3/12 mr-1'>
                    <p className='text-sm font-medium mb-1'>Task Comments</p>
                    <textarea rows="2" className="my-1 block p-2.5 w-full text-sm px-1 py-0.5 border rounded accent-green-700  text-gray-700 focus:border-teal-500 focus:outline-none"
                        name='taskComments'
                        value={formData.taskComments}
                        onChange={handleInputChange}
                        placeholder="Add task comments ...">

                    </textarea>
                </div>
                <div className='w-3/12'>
                    <p className='text-sm font-medium mb-1'>Task Notes</p>
                    <textarea rows="2" className="my-1 block p-2.5 w-full text-sm px-1 py-0.5 border rounded accent-green-700  text-gray-700 focus:border-teal-500 focus:outline-none"
                        name='taskNotes'
                        value={formData.taskNotes}
                        onChange={handleInputChange}
                        placeholder="Add task notes ...">

                    </textarea>
                </div>
            </div>

            <div className='w-full mt-2'>
                <button
                    className={theme.side_Br_Hover + ' ' + theme.txt_color + ' ' + theme.borderColor + ' font-semibold shadow border hover:text-white py-1 px-4 hover:border-transparent mr-3 rounded'}
                    onClick={handleSubmit}
                >
                    Add Task
                </button>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { ApiCall_POST, ApiCall_GET };

export default connect(mapStateToProps, mapDispatchToProps)(AddTask);
