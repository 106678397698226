import React, { useState, useEffect } from 'react';
import { format, startOfWeek, endOfWeek, startOfMonth, endOfMonth, eachMinuteOfInterval, eachDayOfInterval, addDays, isSameDay, isSameMonth, isSameWeek, eachHourOfInterval, startOfDay, endOfDay } from 'date-fns';
import { useAppContext } from '../../../../AppContextProvider ';

const CalendarFull = ({ selectedMode, selectedDates, calendarData, events = [] }) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const theme = useAppContext().themeDetails;

    useEffect(() => {
        if (selectedDates.length > 0) {
            setCurrentDate(selectedDates[0]);
        }
    }, [selectedDates]);

    const renderEvents = (day) => {
        const dayEvents = events.filter(event => isSameDay(event.date, day));
        return dayEvents.map((event, index) => (
            <div key={index} className="mt-2 p-1 px-2 text-sm bg-blue-500 text-white rounded-md">
                {event.title}
            </div>
        ));
    };

    const renderMonthlyView = () => {
        const monthStart = startOfMonth(currentDate);
        const monthEnd = endOfMonth(currentDate);
        const startDate = startOfWeek(monthStart, { weekStartsOn: 1 });
        const endDate = endOfWeek(monthEnd, { weekStartsOn: 1 });

        const rows = [];
        let days = [];
        let day = startDate;

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                const cloneDay = day;
                days.push(
                    <div
                        className={`w-48 h-28 p-2 border ${!isSameMonth(day, monthStart) ? 'text-gray-400' : ''}`}
                        key={day}
                    >
                        <span className='text-sm font-medium'>{format(day, 'd MMM')}</span>
                        {selectedDates.some(d => isSameDay(d, day)) && <div className={theme.thumb + ' ' + theme.track + ' overflow-y-auto scrollbar scrollbar-thin h-16'}>{renderEvents(day)}</div>}
                    </div>
                );
                day = addDays(day, 1);
            }
            rows.push(
                <div className="flex w-full" key={day}>
                    {days}
                </div>
            );
            days = [];
        }
        return <div>{rows}</div>;
    };

    const renderWeeklyView = () => {
        const start = startOfWeek(currentDate, { weekStartsOn: 1 });
        const end = endOfWeek(currentDate, { weekStartsOn: 1 });
        const days = eachDayOfInterval({ start, end });

        return (
            <div className="flex w-full">
                {days.map(day => (
                    <div
                        key={day}
                        className="w-full h-64 p-2 border"
                    >
                        <div className="font-bold mb-2">{format(day, 'EEE d')}</div>
                        {eachHourOfInterval({
                            start: new Date(day.setHours(7, 0, 0)), // Start at 7 AM
                            end: new Date(day.setHours(21, 0, 0)) // End at 9 PM
                        }).map(hour => (
                            <div key={hour} className="flex flex-col">
                                <div className="text-sm border-b p-1">
                                    <span>{format(hour, 'h a')}</span>
                                </div>
                                {selectedDates.some(d => isSameDay(d, day)) && renderEvents(hour)}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        );
    };



    const renderDailyView = () => {
        const day = selectedDates[0] || currentDate;
        const hours = eachHourOfInterval({ start: startOfDay(day), end: endOfDay(day) });

        return (
            <div className="overflow-y-auto scrollbar scrollbar-thin w-full h-[75vh]">
                {hours.map(hour => {
                    const hourAppointments = events.filter(event =>
                        isSameDay(new Date(event.appointmentstarton), hour) &&
                        format(new Date(event.appointmentstarton), 'HH') === format(hour, 'HH')
                    );
                    // console.log(hourAppointments, 'hourAppointments')
                    // console.log(calendarData, 'calendarData')

                    return (
                        <div key={hour} className="w-full h-min-16 p-2 border">
                            <p className='font-medium text-sm'>{format(hour, 'h a')}</p>
                            <div className='flex flex-wrap gap-2'>
                                {hourAppointments.map((appointment, index) => (

                                    <div key={index} className={`mt-1 p-1 bg-${appointment.itemtype_palettecolor}-100 text-sm text-black border border-black rounded`}>
                                        {appointment.title || appointment.itemtypename} - {appointment.patient_firstname}
                                    </div>
                                ))}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };



    return (
        <div className="w-full h-full">
            {selectedMode === 'Monthly' && renderMonthlyView()}
            {selectedMode === 'Weekly' && renderWeeklyView()}
            {selectedMode === 'Daily' && renderDailyView()}
        </div>
    );
};

export default CalendarFull;
