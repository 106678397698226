import React from 'react'
import { ThreeCircles } from 'react-loader-spinner'
import { Dna } from 'react-loader-spinner'
import './style.css'

export default function loader({ visible }) {
    return visible && (
        <div className='loader-css '>
            <Dna
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
            />

            {/* <ThreeCircles
                height="80"
                width="80"
                color={theme.iconColor} // Pass the theme color as a prop
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="three-circles-rotating"
                outerCircleColor=""
                innerCircleColor=""
                middleCircleColor=""

            /> */}
        </div>
    )
}
