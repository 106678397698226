import React from 'react'
import { useNavigate } from 'react-router-dom';
import { UserType, isUserPermited } from '../../../session';
import { useAppContext } from '../../../AppContextProvider ';

export default function MagnetSidebar(props) {
    const theme = useAppContext().themeDetails;
    let navigate = useNavigate();
    return (
        <div className={theme.bg_Light + ' ' + theme.thumb + ' ' + theme.track + ' duration-150 overflow-x-auto  p-2 rounded-md h-full scrollbar scrollbar-thin'}>
            <div className={theme.side_Br_Hover + ' bg-white rounded text-sm cursor-pointer  hover:text-white mt-1  text-left p-2 font-medium	hidden'}>
                Overview
            </div>
            <div className={theme.side_Br_Hover + ' bg-white rounded text-sm cursor-pointer  hover:text-white mt-1  text-left p-2 font-medium	hidden'}>
                Actions
            </div>
            <div className={props.ActiveItem === 'TreatmentPlan' ? (theme.itemActive + ' rounded text-sm cursor-pointer  mt-1 color-green text-left p-2 font-medium') : (theme.side_Br_Hover + ' bg-white rounded text-sm cursor-pointer mt-1 text-left p-2 font-medium')} onClick={() => navigate('../treatmentplan', { state: { artid: props.artid, coupleid: props.coupleid, IsCalledByView: props.IsCalledByView } })}>
                Treatment Plan
            </div>
            <div className={props.ActiveItem === 'Scans' ? (theme.itemActive + ' rounded text-sm cursor-pointer  mt-1 color-green text-left p-2 font-medium') : (theme.side_Br_Hover + ' bg-white rounded text-sm cursor-pointer mt-1 text-left p-2 font-medium')} onClick={() => navigate('../scans', { state: { artid: props.artid, coupleid: props.coupleid, IsCalledByView: props.IsCalledByView } })}>
                Scans
            </div>

            <div className={props.ActiveItem === 'BloodResult' ? (theme.itemActive + ' rounded text-sm cursor-pointer  mt-1 color-green text-left p-2 font-medium') : (theme.side_Br_Hover + ' bg-white rounded text-sm cursor-pointer mt-1 text-left p-2 font-medium')} onClick={() => navigate('../bloodResult', { state: { artid: props.artid, coupleid: props.coupleid, IsCalledByView: props.IsCalledByView } })}>
                Blood
            </div>
            <div className={props.ActiveItem === 'BookingForm' ? (theme.itemActive + ' rounded text-sm cursor-pointer  mt-1 color-green text-left p-2 font-medium') : (theme.side_Br_Hover + ' bg-white rounded text-sm cursor-pointer mt-1 text-left p-2 font-medium')} onClick={() => navigate('../bookingform', { state: { artid: props.artid, coupleid: props.coupleid, IsCalledByView: props.IsCalledByView } })}>
                Booking Form
            </div>
            <div className={theme.side_Br_Hover + ' bg-white rounded text-sm cursor-pointer  hover:text-white mt-1  text-left p-2 font-medium	hidden'}>
                In-Cycle Notes
            </div>
            <div className={theme.side_Br_Hover + ' bg-white rounded text-sm cursor-pointer  hover:text-white mt-1  text-left p-2 font-medium	hidden'}>
                ART
            </div>
            <div className={theme.side_Br_Hover + ' bg-white rounded text-sm cursor-pointer  hover:text-white mt-1  text-left p-2 font-medium	hidden'}>
                LAB
            </div>
            {isUserPermited(UserType.Administrator) &&

                <div className={props.ActiveItem === 'Ohss' ? (theme.itemActive + ' rounded text-sm cursor-pointer  mt-1 color-green text-left p-2 font-medium  ') : (theme.side_Br_Hover + ' bg-white rounded text-sm cursor-pointer mt-1 text-left p-2 font-medium ')} onClick={() => navigate('../ohss', { state: { artid: props.artid, coupleid: props.coupleid, IsCalledByView: props.IsCalledByView } })}>
                    OHSS
                </div>
            }

            <div className={theme.side_Br_Hover + ' bg-white rounded text-sm cursor-pointer  hover:text-white mt-1  text-left p-2 font-medium	hidden'}>
                Logs
            </div>
            <div className={theme.side_Br_Hover + ' bg-white rounded text-sm cursor-pointer  hover:text-white mt-1  text-left p-2 font-medium	hidden'}>
                In-Cycle Notifications
            </div>
            {/* <div className={props.ActiveItem === 'AddReferenceItem' ? (theme.itemActive +'  rounded text-sm cursor-pointer  hover:text-white mt-1 color-green text-left p-2 font-medium') : ( theme.side_Br_Hover +' bg-white rounded text-sm cursor-pointer  hover:bg-green-700 hover:text-white mt-1  text-left p-2 font-medium')} onClick={() => navigate('../addreferenceitems', { state: { artid: props.artid, coupleid: props.coupleid, IsCalledByView:IsCalledByView  } })}>
                Add Reference Items
            </div> */}

            {/* {!isUserPermited(UserType.Nurse) ?
                <div className={props.ActiveItem === 'UpdateTreatmentPlan' ? (theme.itemActive + '  rounded text-sm cursor-pointer  hover:text-white mt-1 color-green text-left p-2 font-medium') : (theme.side_Br_Hover + ' bg-white rounded text-sm cursor-pointer hover:bg-green-700 hover:text-white mt-1  text-left p-2 font-medium')} onClick={() => navigate('../updateTreatmentPlan', { state: { artid: props.artid, coupleid: props.coupleid, IsCalledByView: props.IsCalledByView } })}>
                    + Add Treatment Plan
                </div>
                : ''} */}

        </div>
    )
}
