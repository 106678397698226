//Variables
import moment from "moment";
import { getSession } from "./session"
import Swal from "sweetalert2";
import React from "react";


export const SessionExpiryTime = 30 * 60; //minutes


export const ReferenceTypeItems = {
  FreezeAll: 129,
  // Blastocyst: 130,
  Blastocyst: 94,
  StimMedication: 170,
  MethoxyfluraneInhalation: 303,
  IVSedation: 304,
  GeneralAnesthetic: 305,
  DayOneOfCycle: 352,
  ProcedureLocation: 354,
  NoOfEmbryo: 357,

  FreshSample: 359,
  FrozenSample: 360,
  TESA_GA: 361,
  TESA_LA: 362,
  OpenBiopsy: 363,
  ClinicId: 364

};
export const TaskTypes = {
  TreatmentPlanCreated: 1,
  Day1Date: 2,
  StartFSHMedication: 3,
  ScanInformation: 32,
  BloodsInformation: 33,
  AssessmentPage: 34,
  TransferResults: 35,
  IUIResults: 36,
  FreezeResults: 37,
  SpermFreezeResults: 38,
  PregnancyBloodTest: 39,
  PregnancyScanTest: 40,
  TreatmentPlanRevised: 45,
  DonorInformation: 46,
  AnzardData: 47
}

export const ReferenceTypeHeaders = {
  StimMedication: 170,
  StimMedicationFSH: 171,
  Antagonist: 344,
  Agonist: 355,
  AdjuvantTherapy: 346,
  TriggerMedication: 347,
  ImportantDates: 348,
  ProcedureMedication: 349,
  LutealPhaseMedication: 350
};

export const CycleType = {
  IVF: 1,
  FET: 2,
  FOT: 3,
  FOC: 4,
  IUI: 5,
  OI: 6,
  OvulationInduction: 6,
  ED_IVF: 7,
  EggDonorIVF: 7,
  DI: 8,
  DonorIUI: 8,
  Assessment: 9,
};
export const ohss = {
  NIL: 0,
  MILD: 1,
  MODERATE: 2,
  SEVERE: 3,

};
export const ohssCons = {
  NIL: 0,
  MILD: 1,
  MODERATE: 2,

};

export const PermissionType = {
  None: 0,
  Read: 1,
  Write: 2,
  ReadWrite: 3
};


//Methods

export const getCycleTypeColor = (type) => {
  switch (type) {
    case 'IVF': return '#6AAA4C';
    case 'FET': return '#005FB5';
    case 'FOT': return '#005FB5';
    case 'FOC': return '#00c0e2';
    case 'IUI': return '#DDDDDD';
    case 'OI': return '#ddf1f1';
    case 'DI': return '#DDDDDD';
    case 'AIH': return '#BBB';
    default: return '';
  }
}


export const getClinicianBgColor = (colorType) => {
  switch (colorType) {
    case 'purple': return '#800080,#800080';
    case 'green': return '#ACCF9B,#ACCF9B';
    case 'pink': return '#FE8787,#FE8787';
    case 'red': return '#ff0000,#ff0000';
    case 'yellow': return '#ffff75,#ffff75';
    case 'cyan': return '#9effff,#9effff';
    case 'orange': return '#ffa500,#ffa500';
    case 'cyan_white': return '#9effff,#FFFFFF';
    case 'cyan_green': return '#9effff,#ACCF9B';
    case 'cyan_purple': return '#9effff,#800080';
    case 'orange_white': return '#ffa500,#FFFFFF';
    case 'green_purple': return '#ACCF9B,#800080';
    case 'green_yellow': return '#ACCF9B,#ffff75';
    case 'green_orange': return '#ACCF9B,#ffa500';
    case 'pink_white': return '#FE8787,#FFFFFF';
    case 'purple_white': return '#800080,#FFFFFF';
    case 'cyan_pink': return '#9effff,#FE8787';
    case 'green_white': return '#ACCF9B,#FFFFFF';
    case 'pink_purple': return '#FE8787,#800080';
    case 'pink_orange': return '#FE8787,#ffa500';
    case 'green_pink': return '#ACCF9B,#FE8787';
    default: return '#fff,';
  }
}

const months = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

export const getEnumValueById = (id) => {
  for (const name in CycleType) {
    if (CycleType[name] === id) {
      return name;
    }
  }
  return null; // Return null if the ID doesn't match any enum value
}


export const getTextColor = (colorCode) => {
  // Convert color code to RGB values
  const hexToRgb = (hex) => {
    if (!hex) {
      return '';
    }
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
  };

  // Calculate color brightness/darkness
  const isDarkColor = (rgb) => {
    const brightness = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
    return brightness < 128;
  };

  // Get RGB values from color code
  const rgb = hexToRgb(colorCode);

  // Determine text color based on darkness
  return isDarkColor(rgb) ? "white" : "black";
};


export const getInitials = (names) => {
  const namesArray = names.split(" ");
  const initials = namesArray.map((name) => name.charAt(0).toUpperCase());
  return initials.join("");
};


export const getArtStatClass = (str) => {
  if (str === 'ON-HOLD') return 'text-yellow-500';
  else if (str === 'FINAL') return 'text-green-800';
  else if (str === 'CANCELLED') return 'text-pink-600';
  else if (str === 'CANCELLED') return 'text-pink-600';
  else if (str === 'CURRENT') return 'text-blue-800';
}

export const addYellowClassOnChnage = (e) => {
  const { type } = e.target;
  if (type === 'checkbox' || type === 'radio') {
    const parentDiv = e.target.closest('div');

    if (parentDiv) {
      parentDiv.classList.add('bg-yellow-200');
    }

  } else if (type === 'select-one') {
    // Array.from(e.target.options).forEach(option => {
    //   option.classList.remove('bg-yellow-200');
    // });

    // const selectedOption = e.target.options[e.target.selectedIndex];
    // selectedOption.classList.add('bg-yellow-200');
  }
  else {
    e.target.classList.add('bg-yellow-200');
  }
}


export const formatTime = (date, isAmPm) => {
  if (!moment(date).isValid() || !date || typeof date !== 'string') {
    return '';
  }
  return isAmPm ? moment(date).format('LT') : moment(date).format('HH:mm');
};


export const getDateByRange = (date, days) => {
  // Parse the input date using Moment.js==
  const correctformatdate = getCorrectDateForInput(date)
  const parsedDate = moment(correctformatdate);

  // Add the specified number of days
  const newDate = parsedDate.add(days, 'days');

  // Format the new date as 'DD-MM-yyyy'
  const formattedDate = newDate.format('DD-MM-yyyy');

  return formattedDate;
};

export const getFormattedDateForInput = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, '0');
  if ((day === '01' && month === '01' && (year === 1900 || year === '1900')) || isNaN(date) || !dateTimeString || typeof dateTimeString !== 'string') {
    return null; // Return null for null, non-string, or empty string input
  }
  return year + '-' + month + '-' + day
}

// export function formatTimeDifference(fromDate) {
//   const currentDate = moment();
//   const targetDate = moment(fromDate, ['D-MM-YYYY', 'M-DD-YYYY', 'DD-MM-YYYY', 'YYYY-MM-DD', 'DD/MM/YYYY', 'YYYY/MM/DD', moment.ISO_8601], true);
//   return targetDate.fromNow();
// }


export function formatTimeDifference(targetDate) {
  const currentDate = new Date();
  targetDate = new Date(targetDate);
  const timeDifferenceInMilliseconds = targetDate - currentDate;
  const isPast = timeDifferenceInMilliseconds < 0;
  const isFuture = timeDifferenceInMilliseconds > 0;

  const timeUnits = [
    { unit: 'year', divisor: 31536000000 },
    { unit: 'month', divisor: 2592000000 },
    { unit: 'day', divisor: 86400000 },
    { unit: 'hour', divisor: 3600000 },
    { unit: 'minute', divisor: 60000 },
    { unit: 'second', divisor: 1000 },
  ];

  const formatTimeUnit = (value, unit) =>
    value === 1 ? `a ${unit} ${isPast ? 'ago' : 'from now'}` : `${value} ${unit}s ${isPast ? 'ago' : 'from now'}`;

  const getTimeUnitDifference = (divisor) => Math.floor(Math.abs(timeDifferenceInMilliseconds) / divisor);

  const getTimeDifference = (unit) => {
    const divisor = timeUnits.find((timeUnit) => timeUnit.unit === unit).divisor;
    const value = getTimeUnitDifference(divisor);
    return value > 0 ? formatTimeUnit(value, unit) : null;
  };

  if (isPast || isFuture) {
    for (const timeUnit of timeUnits) {
      const timeDifference = getTimeDifference(timeUnit.unit);
      if (timeDifference) {
        return timeDifference;
      }
    }
  }

  return 'Now';
}

// export function replaceEmptyObjectsWithNull(obj) {
//   // Check if obj is null or undefined
//   if (obj === null || typeof obj !== 'object') {
//     return obj;
//   }

//   for (const key in obj) {
//     if (obj.hasOwnProperty(key)) {
//       if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
//         // Recursively call the function for inner objects
//         obj[key] = replaceEmptyObjectsWithNull(obj[key]);

//         // Check if the inner object is now empty, replace with null
//         if (obj[key] !== null && Object.keys(obj[key]).length === 0) {
//           obj[key] = null;
//         }
//       }
//     }
//   }
//   return obj;
// }

export function replaceEmptyObjectsWithNull(obj) {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] && typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        // Recursively call the function for inner objects
        obj[key] = replaceEmptyObjectsWithNull(obj[key]);

        // Check if the inner object is now empty, replace with null
        if (obj[key] !== null && Object.keys(obj[key]).length === 0) {
          obj[key] = null;
        }
      } else if (obj[key] && Array.isArray(obj[key])) {
        // Recursively call the function for arrays
        obj[key] = obj[key].map(item => replaceEmptyObjectsWithNull(item));

        // Check if the array is now empty, replace with null
        if (obj[key].length === 0) {
          obj[key] = null;
        }
      } else if (obj[key] === "" || obj[key] === "{}" || obj[key] === "[]") {
        // Replace empty strings, empty objects, and empty arrays with null
        obj[key] = null;
      }
    }
  }

  return obj;
}

export function getCurrentDate() {
  return moment().format('YYYY-MM-DD HH:mm:ss'); // Format the date as per your requirement
};

export function getRandomNumber() {
  return Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
}


export function calculateDifference(fromDate, toDate, addOneDay) {

  // const date1Obj = new Date(fromDate);
  // const date2Obj = new Date(toDate);

  fromDate = getCorrectDate(fromDate);
  toDate = getCorrectDate(toDate);

  const date1Obj = moment(fromDate, 'DD-MM-YYYY');//moment(dayOne, 'DD-MM-YYYY');
  const date2Obj = moment(toDate, 'DD-MM-YYYY')//, 'YYYY-MM-DD');




  if (!isNaN(date1Obj) && !isNaN(date2Obj)) {
    const timeDifference = date2Obj - date1Obj;
    var differenceInDays = timeDifference / (1000 * 3600 * 24);
    if (addOneDay) { differenceInDays++ }
    return differenceInDays;
  }

  return null;
};

export function getCorrectDate(str) {



  const parsedDate = moment(str, ['D-MM-YYYY', 'M-DD-YYYY', 'DD-MM-YYYY', 'YYYY-MM-DD', 'DD/MM/YYYY', 'YYYY/MM/DD', moment.ISO_8601], true);

  if (parsedDate.isValid()) {
    // Convert the moment object to the desired format ('YYYY-MM-DD')
    var pDate = parsedDate.format('DD-MM-YYYY');
    if (pDate === '01-01-1900') {
      return null
    }
    return pDate;
  }

  return null; // Return null for invalid dates
}



export function getCorrectDateForInput(str) {



  const parsedDate = moment(str, ['DD-MM-YYYY', 'YYYY-MM-DD', 'DD/MM/YYYY', 'YYYY/MM/DD', moment.ISO_8601], true);

  if (parsedDate.isValid()) {
    // Convert the moment object to the desired format ('YYYY-MM-DD')
    var pDate = parsedDate.format('YYYY-MM-DD');
    if (pDate === '1900-01-01') {
      return null
    }
    return pDate;
  }

  return null; // Return null for invalid dates
}



export function NumberExtractor(inputString) {
  // Check if inputString is not a string or is empty
  if (typeof inputString !== 'string' || inputString.trim() === '') {
    return '';
  }

  // Use regular expression to find all numbers in the string, including decimal numbers
  const numbers = inputString.match(/[-+]?\d*\.?\d+/g);

  // If numbers are found, return the first one
  if (numbers && numbers.length > 0) {
    return parseFloat(numbers[0]);
  }

  return '';
}

export function calculateBMIpatient(patientHeight, patientWeight) {
  // const { patientHeight, patientWeight } = bookingform;

  const height = parseFloat(patientHeight);
  const weight = parseFloat(patientWeight);

  if (!isNaN(height) && !isNaN(weight) && height > 0 && weight > 0) {
    return (weight / Math.pow(height / 100, 2)).toFixed(2);
  }
  return "";
};
export const getColorAndCategory = (bmi) => {
  if (!bmi || bmi === 0) return null; // Return null if no BMI or BMI is 0
  if (bmi <= 35) return {
    color: '#4aa4735c', category: 'Normal weight',
    icon: <svg width="25" height="25" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9966 0.252222C11.767 0.124475 11.5145 0.0432355 11.2535 0.0131501C10.9925 -0.0169354 10.7281 0.00472298 10.4755 0.076887C10.2229 0.149051 9.98697 0.270305 9.78125 0.433715C9.57553 0.597124 9.40403 0.799484 9.27658 1.02922L5.56358 7.71122L3.43858 5.58622C3.25408 5.3952 3.03339 5.24284 2.78939 5.13802C2.54538 5.0332 2.28294 4.97803 2.01738 4.97572C1.75182 4.97341 1.48846 5.02402 1.24267 5.12458C0.996876 5.22514 0.773572 5.37365 0.585787 5.56143C0.398001 5.74922 0.249495 5.97252 0.148933 6.21831C0.0483713 6.46411 -0.00223212 6.72747 7.55134e-05 6.99302C0.00238315 7.25858 0.0575557 7.52102 0.162374 7.76503C0.267192 8.00904 0.419556 8.22973 0.610576 8.41422L4.61058 12.4142C4.98858 12.7932 5.49858 13.0012 6.02458 13.0012L6.30158 12.9812C6.60813 12.9383 6.90054 12.8249 7.1558 12.6498C7.41105 12.4747 7.62219 12.2428 7.77258 11.9722L12.7726 2.97222C12.9002 2.74267 12.9814 2.49023 13.0115 2.2293C13.0416 1.96838 13.02 1.70408 12.9479 1.4515C12.8759 1.19892 12.7548 0.963011 12.5915 0.757238C12.4283 0.551465 12.2261 0.37986 11.9966 0.252222Z"
        fill={"#4AA473"} />
    </svg>
  };
  if (bmi > 35 && bmi < 40) return {
    color: '#ffd70033', category: 'Overweight',
    icon: <svg width="31" height="27" viewBox="0 0 31 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M29.9631 18.3515L20.6398 3.1386C19.416 1.14243 17.5441 0 15.5003 0C13.4565 0 11.5846 1.14243 10.3608 3.13551L1.03753 18.3515C-0.202001 20.3723 -0.337624 22.5275 0.666934 24.2643C1.66991 26.0027 3.62857 27 6.03824 27H24.9624C27.3721 27 29.3307 26.0027 30.3337 24.2659C31.3367 22.5291 31.2026 20.3739 29.9631 18.3515ZM15.5003 21.6723C14.1536 21.6723 13.056 20.5993 13.056 19.2809C13.056 17.9609 14.152 16.8864 15.5003 16.8864C16.8487 16.8864 17.9447 17.9609 17.9447 19.2809C17.9447 20.5993 16.8471 21.6723 15.5003 21.6723ZM18.0756 10.2109C18.0582 10.2587 15.8662 15.5649 15.8662 15.5649C15.8063 15.71 15.6612 15.8057 15.5019 15.8057C15.3426 15.8057 15.1975 15.71 15.1376 15.5649L12.944 10.2572C12.8124 9.93549 12.7434 9.59252 12.7406 9.24598C12.7406 7.75619 13.9785 6.54429 15.5003 6.54429C15.9473 6.54541 16.3873 6.65266 16.7826 6.85685C17.1779 7.06103 17.5167 7.35606 17.7699 7.7166C18.0232 8.07715 18.1833 8.49246 18.2365 8.92689C18.2897 9.36133 18.2345 9.80192 18.0756 10.2109Z" fill="#F59E0B" />
    </svg>


  };
  if (bmi >= 40) return {
    color: '#ff000038', category: 'Obesity',
    icon: <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M29.9188 0L21.2214 5.89931L15.511 11.6234L15.006 12.132L14.4986 11.6234L8.83602 5.94729L0.07896 0.00729324L6.27047 9.17888L11.6051 14.5262L12.1101 15.0324L11.6051 15.541L5.87552 21.2841L0 29.9906L9.24775 23.7195L14.5059 18.4488L15.0108 17.9402L15.5182 18.4487L20.8457 23.7889L30 30L24.0742 21.222L18.4117 15.5457L17.9066 15.0397L18.4116 14.5311L23.6624 9.26755L29.9188 0Z" fill="#A91111" />
    </svg>


  };
  // if (bmi <= 79.9) return { color: '#FF4500', category: 'Obesity' };
  return { color: '#ff000038', category: 'Severe obesity', icon: '' };
};

export function getUserInformation() {
  return getSession('user');
};

export function getPatientInformation() {
  return getSession('patient');
};


export function showNotificationMessage(icon, message, ishowButton) {
  if (ishowButton) {

    Swal.fire({
      icon: icon,
      title: "Notification",
      text: message,
      showConfirmButton: ishowButton,
    })
  } else {
    Swal.fire({
      icon: icon,
      title: message,
      showConfirmButton: ishowButton,
      timer: 2000
    })
  }
};


export function numberLimter(value, limit) {

  // Check if the value is 0, if yes return an empty string
  if (value == 0) {
    return '';
  } else
    // Otherwise, limit the value between 0 and the specified limit
    return Math.min(limit, Math.max(0, value));
}

export function getScanResults(input, isCalledForBottom) {
  const commaCount = input?.split(',').length - 1;

  if (commaCount === 15) {
    const values = input.split(',').map(Number);
    const result = Array.from({ length: 16 }, (_, i) => {
      const number = ((24 - i) === 24 ? ('>23') : (24 - i) === 9 ? ('<10') : (24 - i).toString());
      const times = values[i];

      if (times && !isNaN(times) && times > 0) {
        return Array(times).fill(number).join(',');
      }
      return '';
    });
    return isCalledForBottom ? result.reverse().filter(Boolean).join(',') : result.filter(Boolean).join(',');
  }
  else {
    return input;
  }

}

export function generateScanInput(output) {
  // Check if the 'output' string is not empty and contains at least one comma-separated value
  // if (output.indexOf(',') > 0) {

  // if (output.indexOf(',') > 0) {

  // }
  // Create an array 'result' with a length of 14 (initialized with undefined elements)
  const result = Array(16);

  // Extract unique values from the 'output' string by splitting it into an array and filtering out duplicates
  let unique = output.split(',').filter((item, i, ar) => ar.indexOf(item) === i);

  // Loop through each unique value
  for (var i = 0; i < unique.length; i++) {
    // Count the occurrences of the current unique value in the 'output' string
    var length = output.split(',').filter(x => x === unique[i]).length;

    // Check if the current unique value is a number within the range of 10 to 23
    if (isNumberInRange(unique[i])) {
      // Update the 'result' array at the appropriate index (based on the unique value) with its count
      result[Number(unique[i]) - 9] = length;
    } else if (unique[i] === '>23') {
      result[15] = length
    }
    else if (unique[i] === '<10') {
      result[0] = length
    }
    else {
      return isNumberInRange(output) ? output : '';
    }
  }
  // Return the 'result' array joined into a comma-separated string
  // return result.join(',');
  return result.reverse().join(',');
  // } else {
  //   // Return null if the 'output' string is empty or doesn't contain comma-separated values
  //   return output;
  // }
}


function isNumberInRange(value) {
  // Check if the value is a number or a string that can be converted to a number
  const numericValue = Number(value);

  // Check if the conversion was successful and if the number is within the range 10 to 23 (inclusive)
  return !isNaN(numericValue) && numericValue >= 9 && numericValue <= 23;
}


// 10,10,10,10,12,12,11,


export function getUniqueObjectsByKey(arrayOfObjects, key) {

  const uniqueObjectsSet = new Set();

  // Iterate through the array of objects
  arrayOfObjects?.forEach(obj => {
    // Add the object to the set using the value of the specified key
    uniqueObjectsSet.add(obj[key]);
  });

  // Convert the set back to an array of unique objects
  const uniqueObjectsArray = Array.from(uniqueObjectsSet).map(uniqueKey => {
    // Find the first object in the original array with the matching key value
    return arrayOfObjects.find(obj => obj[key] === uniqueKey);
  });
  return uniqueObjectsArray;
}


export function returnNullIfEmpty(data) {
  if (data && Object.keys(data).length === 0 && data.constructor === Object) {
    // The object is empty, return null
    return null;
  }
  return data;
}

// export function AppendDateWithDayNo(date, IsCalledByTP, selectedValue, isDisableLesserDates) {
export function AppendDateWithDayNo(date, IsCalledByTP, selectedValue) {
  let NumberList = [];
  //disabled={isDisableLesserDates && (calculateDifference(getCorrectDate(selectedValue),getDateByRange(date, i - 1),true)<=0)} >
  for (var i = 1; i <= 60; i++) {
    NumberList.push(<option value={IsCalledByTP ? i : getDateByRange(date, i - 1)} selected={getCorrectDate(selectedValue) === getDateByRange(date, i - 1)} >
      {" Day-" + i} {date ? `\u00A0(${getDateByRange(date, i - 1)})` : ""}
    </option>);
  }
  return NumberList;
}

let currentUtterance = null; // To hold the current speech instance

export function SpeakString(text) {


  if ('speechSynthesis' in window) {
    // Stop the previous speech if it exists
    if (currentUtterance && window.speechSynthesis.speaking) {
      window.speechSynthesis.cancel();
    }

    const utterance = new SpeechSynthesisUtterance(text);
    window.speechSynthesis.speak(utterance);

    // Store the new utterance as the current one
    currentUtterance = utterance;
  }
}

export function calculateFolicleCount(scan) {
  let leftArray = [];
  try {
    leftArray = scan.result_l.split(',').map(Number);
  } catch (error) {
    // Handle the error or log it if needed
  }

  let rightArray = [];
  try {
    rightArray = scan.result_r.split(',').map(Number);
  } catch (error) {
    // Handle the error or log it if needed
  }


  const countGreaterThan14left = leftArray.filter(value => value >= 14).length;
  const countGreaterThan14right = rightArray.filter(value => value >= 14).length;
  const countLeft = leftArray.length;
  const countRight = rightArray.length;

  const total14Count = countGreaterThan14left + countGreaterThan14right;
  const total = countLeft + countRight;

  return { total14Count, total };
}

export function loadClinicDropdown(clinicsList, userLocationId, IsClinic, filterForSavedLoc) {

  let HospitalList = [];
  if (IsClinic) {
    let groupByLocationId = getUniqueObjectsByKey(clinicsList, 'locationid')
    groupByLocationId.map((item) => {
      HospitalList.push(<option className={item.locationid === userLocationId && "bg-[#ffe4c4]"} value={item?.locationid}>{item?.ClinicName}</option>);
    });
  } else {
    clinicsList.filter(x => x.hospitalid > 0 && (filterForSavedLoc ? x.locationid === Number(filterForSavedLoc) : true)).map((item) => {
      HospitalList.push(<option className={item.locationid === userLocationId && "bg-[#ffe4c4]"} value={item?.hospitalid}>{item?.name}</option>);
    });
  }


  return HospitalList;

}


export function getSubstringByLength(inputString, length) {
  // Ensure the length is within the bounds of the string
  const endIndex = Math.min(length, inputString?.length);

  // Use substring to extract the specified number of characters
  let substring = inputString?.substring(0, endIndex);

  // Add dots if the substring is shorter than the original string
  if (endIndex < inputString?.length) {
    substring += '...';
  }

  return substring;
}
export const filterEmptyValues = (obj) => {

  if (obj === null || obj === undefined || typeof obj !== 'object') {
    throw new TypeError('Expected an object');
  }
}

export function filterNonEmptyProperties(obj) {
  // Create a new object to store the filtered properties
  let result = {};
  // Iterate over the properties of the input object
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      let value = obj[key];
      // Check if the property is not null or empty
      if (value !== null && value !== '') {
        result[key] = value;
      }
    }
  }
  return result;
}



export function getMonthsDropdown() {
  return (
    <>
      <option value="0">Select month</option>
      {months.map((month, index) => (
        <option key={index} value={month.slice(0, 3)}>
          {month}
        </option>
      ))}
    </>
  );
}

export function getYearsDropdown() {
  const currentYear = new Date().getFullYear() + 1;
  const startYear = 2000;
  const options = [];

  for (let year = startYear; year <= currentYear; year++) {
    options.push(
      <option key={year} value={year}>
        {year}
      </option>
    );
  }

  return <>{options}</>;
}

export function getMinMaxByKey(list, key, type) {
  if (!Array.isArray(list) || list.length === 0 || (type !== 'min' && type !== 'max')) {
    return null;
  }

  let values = list.map(item => item[key]).filter(value => typeof value === 'number');

  if (values.length === 0) {
    return null;
  }

  return type === 'min' ? Math.min(...values) : Math.max(...values);
}

export const renderWithLineBreaks = (text) => {
  return text?.split('\n')?.map((str, index) => (
    <React.Fragment key={index}>
      {str}
      <br />
    </React.Fragment>
  ));
};

export function formatNumber(value) {
  if (value === null) {
    return '';
  }

  if (typeof value === 'string') {
    if (!isNaN(value) && value.trim() !== '') {
      value = parseFloat(value);
    } else {
      return '';
    }
  }

  if (typeof value === 'number') {
    if (Number.isInteger(value)) {
      return value.toString();
    } else {
      return value.toFixed(2);
    }
  }

  return '';
}