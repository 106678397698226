export const Success = "Success";
export const Fail = "Fail";


export const logoutSuccess = "LogoutSuccess";

export const ResetPasswordSuccess = "ResetPasswordSuccess";
export const ResetPasswordFail = "ResetPasswordFail";

export const signUpSuccess = "signUpSuccess";
export const signUpFailed = "signUpFailed";

export const agencies = "agencies";
export const getAgenciesFailed = "getAgenciesFailed";

export const myDataSuccess = "myDataSuccess";
export const myDataFailed = "myDataFailed";

export const otpVerifySuccess = "otpVerifySuccess";
export const otpVerifyFailed = "otpVerifyFailed";

export const forgetPassSuccess = "forgetPassSuccess";
export const forgetPassFail = "forgetPassFail";

export const otpResendSuccess = "otpResendSuccess";
export const otpResendFailed = "otpResendFailed";

export const updateUserSuccess = "updateUserSuccess";
export const updateUserFail = "updateUserFail";

export const updateBillingSuccess = "updateBillingSuccess";
export const updateBillingFail = "updateBillingFail";

export const createBillingSuccess = "createBillingSuccess";
export const createBillingFail = "createBillingFail";

export const setDefault = "setDefault";
export const setDefaultFail = "setDefaultFail";

export const deleteCard = "deleteCard";
export const deleteCardFail = "deleteCardFail";

export const getBillingSuccess = "getBillingSuccess";
export const getBillingFail = "getBillingFail";

export const passwordUpdated = "passwordUpdated";
export const passwordUpdatFailed = "passwordUpdatFailed";

export const updatePofileSuccess = "updatePofileSuccess";
export const updatePofileFail = "updatePofileFail";

export const getPofileSuccess = "getPofileSuccess";
export const getPofileFail = "getPofileFail";

export const createPortfolioSuccess = "createPortfolioSuccess";
export const createPortfolioFail = "createPortfolioFail";

export const getPortfolioSuccess = "getPortfolioSuccess";
export const getPortfolioFail = "getPortfolioFail";

export const createPlanSuccess = "createPlanSuccess";
export const creatPlanFail = "creatPlanFail";

export const getPlanSuccess = "getPlanSuccess";
export const getPlanFail = "getPlanFail";

export const getNotificationSuccess = "getNotificationSuccess";
export const getNotificationFail = "getNotificationFail";

export const updateNotificationSuccess = "updateNotificationSuccess";
export const updateNotificationFail = "updateNotificationFail";

export const Users = "Users";
export const getUsersFailed = "getUsersFailed";


export const getTaskSuccess = "getTaskSuccess";
export const getTaskFail = "getTaskFail";

export const influencerDataSuccess = "influencerDataSuccess";
export const influencerDataFailed = "influencerDataFailed";

export const getSocailProfilesSuccess = "getSocailProfilesSuccess";
export const getSocailProfilesFail = "getSocailProfilesFail";

export const updateSocailProfilesSuccess = "updateSocailProfilesSuccess";
export const updateSocailProfilesFail = "updateSocailProfilesFail";

export const createSocailProfilesSuccess = "updateSocailProfilesSuccess";
export const createSocailProfilesFail = "updateSocailProfilesFail";

export const createPorposalSuccess = "createPorposalSuccess";
export const createPorposalFail = "createPorposalFail";

export const updatePorposalSuccess = "updatePorposalSuccess";
export const updatePorposalFail = "updatePorposalFail";

export const getProposalListSuccess = "getProposalListSuccess";
export const getProposalListFail = "getProposalListFail";

export const requests = "requests";
export const getrequestsFailed = "getrequestsFailed";

export const requestCreated = "requestCreated";
export const requestCreatedFailed = "requestCreatedFailed";

export const requestUpdated = "requestUpdated";
export const requestUpdatedFailed = "requestUpdatedFailed";

export const getPlanByIdSuccess = "getPlanByIdSuccess";
export const getPlanByIdFail = "getPlanByIdFail";

export const getSocialProfileSuccess = "getSocialProfileSuccess";
export const getSocialProfileFail = "getSocialProfileFail";


export const gettaskSuccess = "gettaskSuccess";
export const gettaskFail = "gettaskFail";

export const getchatSuccess = "getchatSuccess";
export const getchatFail = "getchatFail";

export const getfileSuccess = "getfileSuccess";
export const getfileFail = "getfileFail";

export const getMyNotifictionSuccess = "getMyNotifictionSuccess";
export const getMyNotifictionFail = "getMyNotifictionFail";

export const getActiveTasksSuccess = "getActiveTasksSuccess";
export const getActiveTasksFail = "getActiveTasksFail";


export const updateActiveSuccess = "updateActiveSuccess";
export const updateActiveFail = "updateActiveFail";


export const updateTaskSuccess = "updateTaskSuccess";
export const updateTaskFail = "updateTaskFail";

export const taskStatusUpdated = "taskStatusUpdated";
export const taskStatusUpdateFail = "taskStatusUpdateFail";

export const getbrandtaskSuccess = "getbrandtaskSuccess";
export const getbrandtaskFail = "getbrandtaskFail";

export const getInvoicesSuccess = "getInvoicesSuccess";
export const getInvoicesFail = "getInvoicesFail";

export const getInvoicesByBrandSuccess = "getInvoicesByBrandSuccess";
export const getInvoicesByBrandFail = "getInvoicesByBrandFail";

export const graph = "graph";
export const graphMonthly = "graphMonthly";

export const countryReachCreated = "countryReachCreated";
export const countryReachCreatedFail = "countryReachCreatedFail";

export const genderReachCreated = "genderReachCreated";
export const genderReachCreatedFail = "genderReachCreatedFail";

export const genderReachUpdated = "genderReachUpdated";
export const genderReachUpdatedFail = "genderReachUpdatedFail";

export const countryReach = "countryReach";
export const countryReachFail = "countryReachFail";

export const genderReach = "genderReach";
export const genderReachFail = "genderReachFail";

export const getTreatmentPlanSuccess = "TreatmentPlanSuccess";
export const getTreatmentPlanFail = "TreatmentPlanFail";


export const getPatientSuccess = "PatientSuccess";
export const getPatientFail = "PatientFail";

export const getReferenceItemsSuccess = "ReferenceItemsSuccess";
export const getReferenceItemsFail = "ReferenceItemsFail";


