import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './Redux/store'
import { Provider } from 'react-redux';
import { setSessions } from './session';
import { AppContextProvider } from './AppContextProvider ';


const root = ReactDOM.createRoot(document.getElementById("root"));
setSessions(window.location.href)


root.render(

  <Provider store={store}>
    <AppContextProvider>
      <App />
    </AppContextProvider>
  </Provider>


);

reportWebVitals();
