import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getCorrectDate, getCorrectDateForInput } from '../../common';
import moment from 'moment';
import { useAppContext } from '../../AppContextProvider ';

const CustomDatePicker = ({ initVal, onChange, dayOne, propName, ...otherProps }) => {
    const [selectedDate, setSelectedDate] = useState(initVal);
    const theme = useAppContext().themeDetails;

    useEffect(() => {
        setSelectedDate(initVal);
    }, [initVal]);

    const renderDayContents = (day, date) => {
        return (
            <div className=''>
                <p className="text-md">{day}</p>
                <p className="text-xs">{getSubtitle(date)}</p>
            </div>
        );
    };

    function getCycleDay(dayOne, ScanDate) {
        dayOne = getCorrectDate(dayOne);
        ScanDate = getCorrectDate(ScanDate);
        const momentDayOne = moment(dayOne, 'DD-MM-YYYY');
        const momentScanDate = moment(ScanDate, 'DD-MM-YYYY');

        const differenceInDays = momentScanDate.diff(momentDayOne, 'days') + 1;
        return isNaN(differenceInDays) ? '0' : differenceInDays;
    }

    const getSubtitle = (date) => {
        return getCycleDay(dayOne, date) > 0 && getCycleDay(dayOne, date) <= 365 ? 'Day ' + getCycleDay(dayOne, date) : '-';
    };

    const handleDateChange = (date) => {
        setSelectedDate(getCorrectDateForInput(date));
        if (onChange) {
            onChange({ target: { name: propName, value: getCorrectDateForInput(date) } });
        }
    };

    return (
        <div>
            <DatePicker
                selected={selectedDate}
                onChange={(e) => handleDateChange(e)}
                dateFormat={["dd-MM-yyyy", "dd/MM/yyyy", "dd-MMM-yyyy", "dd/MMM/yyyy"]}
                name={propName}
                placeholderText='~Select~'
                autoComplete='off'
                renderDayContents={renderDayContents}
                className={'w-40 shadow border rounded px-3 rounded-xl p-1 text-sm text-gray-700 focus:border-teal-500 focus:outline-none'}
                {...otherProps}

            />
        </div>
    );
};

export default CustomDatePicker;
