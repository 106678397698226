import { combineReducers } from "redux";

import authReducer from "./Auth/reducer";
import billingReducer from "./billing/reducer";
import treatmentPlanReducer from "./TreatmentPlan/reducer";
import patientReducer from "./Patients/reducer";
import referenceItemReducer from "./ReferenceItem/reducer";
import genericReducer from "./Generic/reducer";


export default combineReducers({
    authReducer,
    billingReducer,
    treatmentPlanReducer,
    patientReducer,
    referenceItemReducer,
    genericReducer
});
