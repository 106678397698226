import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import logo from '../../../../../Assets/images/chaartlogo.png';
import moment from 'moment'

import { useAppContext } from '../../../../../AppContextProvider ';
import { getSession } from '../../../../../session';
import { calculateDifference, decodeHtmlEntities, getCorrectDate, getUniqueObjectsByKey, renderWithLineBreaks } from '../../../../../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faCircleCheck } from '@fortawesome/free-regular-svg-icons';

export const StimPrint = ({ ApiCall_GET, maxDay, minDay, dayOneOfCycle, artData, artid, medication, NumberOfColums, setMedication, isLuteal }) => {
    const theme = useAppContext().themeDetails;
    const [couple, setCouple] = useState(null)
    const user = getSession('user')

    var medicationVariables = !isLuteal ? [
        {
            obj: 'Stim',
            label: 'Stimulation'
        },
        {
            obj: 'Trigger',
            label: 'Trigger'
        }
    ] : [{
        obj: 'Luteal',
        label: 'Luteal Phase Support'
    }]

    if (isLuteal) {

        var lutealMeds = getUniqueObjectsByKey(medication['Luteal'], 'drugmastername')

        lutealMeds.forEach((item) => {
            item.showdates = item.findate == item.startdate
                ? 'on Day-' + calculateDifference(artData?.DayofCycle, item.findate, true) + ' (' + getCorrectDate(item.findate) + ')'
                : ' From Day-' + calculateDifference(artData?.DayofCycle, item.startdate, true) + ' (' + getCorrectDate(item.startdate) + ') to Day-' + calculateDifference(artData?.DayofCycle, item.findate, true) + ' (' + getCorrectDate(item.findate) + ')';
        });

        medicationVariables = [{
            obj: 'Luteal',
            label: 'Luteal Phase Support'
        }]
    }

    useEffect(() => {
        setCouple(getSession('patient'))

    }, [])
    const getKeyDates = (index, date) => {
        if (index == 0) {
            return <label className='bg-[#00509E] rounded text-white py-1 px-3'>LMP</label>
        }
        else if (date == getCorrectDate(artData?.dateofEPU)) {
            return <label className='bg-[#A91111] rounded text-white py-1 px-3'>EPU</label>
        }
        else {
            return <label className=' rounded text-white py-1 px-3'></label>
        }
    }


    const getLabelContent = (item, index, Type) => {
        const filteredMedications = medication[Type]?.filter(
            (x) => x.drugmastername === item?.drugmastername && x?.dayoffset === (index + 1)
        );
        if (filteredMedications?.length > 0) {
            return filteredMedications[0];
        }
    }

    const isExist = (index, type) => {
        index++;
        if (type == 'Scan') {
            switch (index) {
                case artData?.scandate1day: return true;
                case artData?.scandate2day: return true;
                case artData?.scandate3day: return true;
                case artData?.scandate4day: return true;
                case artData?.scandate5day: return true;
            }
        }
        else if (type == 'Blood') {
            switch (index) {
                case artData?.bloodtest1day: return true;
                case artData?.bloodtest2day: return true;
                case artData?.bloodtest3day: return true;
                case artData?.bloodtest4day: return true;
                case artData?.bloodtest5day: return true;

            }
        }
        return false;
    }

    return (
        <div id="divPrint" className=" min-h-screen w-full  p-3 delay-900 duration-700 w-full   bg-white  ">
            {/* Header */}
            <div className={'  flex justify-  w-full  my-2'} >
                <div className='flex items-center  justify-start w-[25%] '>
                    <img src={logo} className="logoCFC" alt="img" width='160px' />
                    <label className={`dot-${user?.BrandCode?.toUpperCase()} `} style={{ backgroundColor: `#${user?.ChartingColor}` }}></label>
                    <p className=' text-black  text-sm'>{user?.LocationName}</p>
                </div>
                <div className='text-center w-[60%] '>
                    <label className={theme.txt_color + ' text-xxl font-medium font-bold text-end  '}>Patient Medication Schedule </label>
                    <p className={'  text-sbase font-medium text-red-400 text-left  rounded-t'}>IMPORTANT: Do not stop taking your medications unless advised to do so by your Doctor or IVF Nurse</p>
                </div>
                <div className='flex justify-between  text-center  border-[2px]  border-pink-800  w-[24%]  h-12 mb-2 '>
                    <p className='cursor-pointer  text-base font-bold  p-2 h-full flex items-center  text-pink-100  bg-pink-800'>Plan Current as at:</p>
                    <p className={'text-sm text-pink-800  h-full flex items-center p-2'}>{moment(new Date()).format('ddd DD-MMM-yyyy')}</p>
                </div>

            </div>
            {/* </header>  */}
            <main className='p-2 '>

                <div className='w-full flex mt-2  gap-2 '>
                    <div className='w-4/12    '>
                        <p className={'bg-[#22668D]   text-sm font-medium text-white text-left p-1 px-2   rounded-t'}>Patient Information ({couple?.coupleCode})</p>
                        <div className='w-full rounded-b  border'>
                            <div className='flex text-left border-b-[1px]  border-b-gray-300   w-full  '>
                                <p className='cursor-pointer text-sbase font-medium p-0.5 px-2  text-gray-700  bg-gray-200 w-32'>Patient </p>
                                <div className=' px-2 flex flex-wrap justify-between items-center w-full'>
                                    <p className={'text-sm text-gray-500  '}>{couple?.patientFullName}</p>
                                    <p className={'text-sm text-gray-500  '}><b>DOB: </b>{couple?.patientDOB}</p>
                                </div>
                            </div>

                            <div className='flex text-left border-b-[1px]  border-b-gray-300   w-full  '>
                                <p className='cursor-pointer text-sbase font-medium px-2  p-0.5 text-gray-700  bg-gray-200 w-32'>Partner</p>
                                <div className=' px-2 flex  flex-wrap justify-between items-center w-full'>
                                    <p className={'text-sm text-gray-500  '}>{couple?.partnerFullName}</p>
                                    <p className={'text-sm text-gray-500  '}><b>DOB: </b>{couple?.partnerDOB}</p>
                                </div>
                            </div>

                            <div className='flex text-left border-b-[1px]   w-full  '>
                                <p className='cursor-pointer text-sbase font-medium p-0.5 px-2 text-gray-700 bg-gray-200 w-32'>Address</p>
                                <div className=' px-2 flex-wrap justify-between items-center w-full '>
                                    {/* <p className={'text-sm text-gray-500  '}>{couple?.PartnerName}</p> */}
                                    <p className={'text-sm text-gray-500  '}> {couple?.patientAddress}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='w-3/12  '>
                        <p className={'bg-[#22668D]   text-sm font-medium text-white text-left p-1  rounded-t'}>Clinician Details</p>
                        <div className='w-full rounded-b  border'>
                            <div className='flex text-left      border-b-[1px]  border-b-gray-300   w-full  '>
                                <p className='cursor-pointer text-sbase font-medium p-0.5 px-2  text-gray-700  bg-gray-200 w-1/3'>Name</p>
                                <p className={'text-sm text-gray-500 px-2 w-2/3'}>{artData?.Doctor}</p>
                            </div>
                            <div className='flex text-left      border-b-[1px]  border-b-gray-300   w-full  '>
                                <p className=' text-sbase font-medium p-0.5 px-2  text-gray-700  bg-gray-200  w-1/3'>Location</p>
                                <p className={'text-sm text-gray-500 px-2 w-2/3'}>{artData?.LocationName}</p>
                            </div>
                            <div className='flex text-left       border-b-[1px]    w-full  '>
                                <p className='cursor-pointer text-sbase font-medium p-0.5 px-2  text-gray-700  bg-gray-200 w-1/3'>Contact</p>
                                <p className={'text-sm text-gray-500 px-2  w-2/3'}>{artData?.DoctorContact}</p>
                            </div>
                        </div>
                    </div>
                    <div className='w-3/12  '>
                        <p className={'bg-[#22668D]   text-sm font-medium text-white text-left p-1  rounded-t'}>ART Cycle Information</p>
                        <div className='w-full rounded-b  border'>
                            <div className='flex text-left      border-b-[1px]  border-b-gray-300   w-full   '>
                                <p className='cursor-pointer text-sbase font-medium p-0.5 px-2  text-gray-700  bg-gray-200 w-1/3'>Procedure</p>
                                <p className={'text-sm text-gray-500 px-2 w-2/3'}>{artData?.cycletype}</p>
                            </div>
                            <div className='flex text-left      border-b-[1px]  border-b-gray-300   w-full  '>
                                <p className='cursor-pointer text-sbase font-medium p-0.5 px-2  text-gray-700  bg-gray-200 w-1/3'>{artData?.cycletype?.toLowerCase().includes('ivf') ? 'IVF Type' : 'FET Type'}</p>
                                <p className={'text-sm text-gray-500 px-2 w-2/3'}>{artData?.cycletype?.toLowerCase().includes('ivf') ? artData?.typeofIVFscycle : artData?.fettype}</p>
                            </div>
                            <div className='flex text-left     border-b-[1px]    w-full  '>
                                <p className='cursor-pointer text-sbase font-medium p-0.5 px-2  text-gray-700  bg-gray-200 w-1/3'>Day 1</p>
                                <p className={'text-sm text-gray-500 px-2 w-2/3'}>{artData?.DayofCycle}</p>
                            </div>
                        </div>
                    </div>

                    <div className=' rounded w-2/12   '>
                        <p className={'bg-pink-800  text-sm font-medium text-white text-left p-1  text-center justify-center rounded-t'}>Dose Legend</p>
                        <div className='w-full rounded-b  border'>
                            <div className='flex text-left      border-b-[1px]  border-b-gray-300   w-full   '>
                                <p className='w-1/2 cursor-pointer text-sbase font-medium p-0.5 px-2  text-gray-700  bg-gray-200 '>Daily</p>
                                <p className={'text-sm text-gray-500 px-2 '}>1 Time a Day</p>
                            </div>
                            <div className='flex text-left      border-b-[1px]  border-b-gray-300   w-full   '>
                                <p className='w-1/2 cursor-pointer text-sbase font-medium p-0.5 px-2  text-gray-700  bg-gray-200 '>BD</p>
                                <p className={'text-sm text-gray-500 px-2 '}>2 Time a Day</p>
                            </div>
                            <div className='flex text-left      border-b-[1px]  border-b-gray-300   w-full   '>
                                <p className='w-1/2 cursor-pointer text-sbase font-medium p-0.5 px-2   text-gray-700  bg-gray-200 '>TDS</p>
                                <p className={'text-sm text-gray-500 px-2 '}>3 Time a Day</p>
                            </div>
                            <div className='flex text-left     border-b-[1px]    w-full   '>
                                <p className='w-1/2 cursor-pointer text-sbase font-medium p-0.5 px-2  text-gray-700  bg-gray-200 '>QID</p>
                                <p className={'text-sm text-gray-500 px-2 '}>4 Time a Day</p>
                            </div>
                        </div>
                    </div>

                </div>

                {/*Schedule Information------- */}
                <div className={' flex py-3 pb-3 w-full   '}>
                    <div>

                        <div className={'flex items-center text-white w-full'}>
                            <div className='w-28  p-2 text-sm font-bold sticky   
                                //   .bg-[#22668D] 
                                   '  >
                                {/* <label> Day of Cycle</label>
                                    <p className='m-0'> Date</p> */}
                            </div>

                            {(() => {
                                const columns = [];
                                for (let index = (minDay ? minDay - 1 : minDay); index < (maxDay + 1); index++) {
                                    columns.push(
                                        <div className='w-16 p-2 text-sm font-bold sticky  border border-gray-300  bg-blue-300 '  >
                                            <p className='text-center text-black'>Day -{index + 1}</p>
                                            <label className='text-center text-gray-800'>{dayOneOfCycle ? moment(getCorrectDate(dayOneOfCycle), 'DD-MM-YYYY').add(index, 'days').format('ddd D') : 'Day ' + (index + 1)}</label>
                                        </div>
                                    );
                                }
                                return columns;
                            })()}
                            {/* 
                                    {Array.from({ length: NumberOfColums }, (_, index) => (
                                    
                                    ))} */}
                        </div>
                        {/* <div className={'flex items-center text-white '}>
                                <div className='w-28  p-2 text-sm font-bold sticky shadow  border-r  bg-[#22668D]  '  >
                                    <label> Day of Cycle</label>
                                </div>

                                {(() => {
                                    const columns = [];
                                    for (let index = (minDay ? minDay - 1 : minDay); index < (maxDay + 1); index++) {
                                        columns.push(
                                            <div className='w-16 p-2 text-sm font-bold sticky  border  bg-[#22668D]  '  >
                                                <label>{index + 1}</label>
                                            </div>
                                        );
                                    }
                                    return columns;
                                })()}
                            </div> */}

                        {/* drugmastername */}
                        {medication && medicationVariables.map((med) => getUniqueObjectsByKey(medication[med?.obj], 'drugmastername')?.length > 0 ?
                            <>
                                {/* <p className={theme.txt_color + ' text-sm font-bold cursor-pointer px-1 '}>{med?.label}</p> */}
                                {getUniqueObjectsByKey(medication[med?.obj], 'drugmastername').map((item) => (
                                    <div className={'flex items-center  mr-3 '}>
                                        <div className='w-28 h-12 p-2 text-gray-600 font-bold text-sm   border  border-gray-300 cursor-pointer  bg-blue-300 '  >
                                            <p className={theme.txt_color + ' text-xs font-bold cursor-pointer  '}>{med?.label}</p>
                                            <p >{item?.drugmastername}</p>
                                        </div>
                                        {(() => {
                                            const columns = [];

                                            for (let index = (minDay ? minDay - 1 : minDay); index < (maxDay + 1); index++) {

                                                const labelContent = getLabelContent(item, index, med?.obj);
                                                const isWeekend = ['Sat', 'Sun'].includes(moment(getCorrectDate(dayOneOfCycle)).add(index, 'days').format('ddd'));
                                                const bgColorClass = labelContent ? 'bg-orange-300' : isWeekend ? 'bg-[#DDFFFF]' : '';

                                                columns.push(
                                                    <div key={index} className={'w-16 h-12 justify-center  flex flex-col items-center font-bold hover:scale-125 text-center border border-gray-500 cursor-pointer ' + bgColorClass}>
                                                        <p className=' text-base leading-3 '>
                                                            {labelContent?.dose}
                                                        </p>
                                                        <p className='rounded text-xs '>
                                                            {(labelContent?.units1 ? labelContent?.units1 : '') + ' ' + (labelContent?.period ? labelContent?.period : '')}<br />
                                                        </p>
                                                    </div>
                                                );
                                            }
                                            return columns;
                                        })()}
                                    </div>
                                ))

                                }
                            </>
                            : ''
                        )}

                        {/* <p className={theme.txt_color + '  font-bold cursor-pointer p-1 '}></p> */}
                        <div className={'flex items-center my-1  '}>
                            <div className={'w-28 h-8 flex items-center p-2 border border-gray-500 cursor-pointer bg-blue-100 '}  >
                                <label className=' text-[#166534] font-bold text-base '>Scans</label>
                            </div>
                            {(() => {
                                const columns = [];
                                for (let index = (minDay ? minDay - 1 : minDay); index < (maxDay + 1); index++) {
                                    columns.push(
                                        <div className={(isExist(index, 'Scan') ? 'bg-blue-50' : '') + ' w-16 h-8 p-2  font-bold text-black  flex justify-center items-center font-bold hover:scale-125 text-center  border border-gray-500 cursor-pointer '}  >
                                            {isExist(index, 'Scan') ?
                                                <svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0.301593 5.99005C2.6069 10.1215 4.8955 13.8887 6.88498 18.4507C11.4363 12.7335 16.0658 7.01042 22.8881 1.21718L21.6203 0.227757C16.0852 4.11085 11.5998 7.95521 7.37247 12.7706C5.72119 10.3591 2.9651 6.8528 1.22267 4.97831L0.301593 5.99005Z" fill={theme.iconColor} />
                                                </svg>

                                                // <FontAwesomeIcon icon={faCheck} />
                                                : ''}
                                        </div>
                                    );
                                }
                                return columns;
                            })()}
                        </div>

                        <div className={'flex items-center my-1  '}>
                            <div className='w-28 h-8 flex items-center p-2 text-gray-600 font-bold text-sm border  border-gray-500 cursor-pointer  bg-yellow-50  '  >
                                <label className=' text-[#166534] font-bold text-base '>Bloods</label>
                            </div>
                            {(() => {
                                const columns = [];
                                for (let index = (minDay ? minDay - 1 : minDay); index < (maxDay + 1); index++) {
                                    columns.push(
                                        <div className={(isExist(index, 'Blood') ? 'bg-yellow-50' : '') + ' w-16 h-8 p-2  font-bold text-black  flex justify-center items-center font-bold hover:scale-125 text-center   border  border-gray-500 cursor-pointer '}  >
                                            {isExist(index, 'Blood') ? <svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.301593 5.99005C2.6069 10.1215 4.8955 13.8887 6.88498 18.4507C11.4363 12.7335 16.0658 7.01042 22.8881 1.21718L21.6203 0.227757C16.0852 4.11085 11.5998 7.95521 7.37247 12.7706C5.72119 10.3591 2.9651 6.8528 1.22267 4.97831L0.301593 5.99005Z" fill={theme.iconColor} />
                                            </svg>
                                                : ''}
                                        </div>
                                    );
                                }
                                return columns;
                            })()}
                        </div>



                    </div>


                    {/* </div> */}
                </div>
                <div className='w-full flex gap-2  '>
                    {artData?.tridate || artData?.tritime || artData?.fastdate || artData?.fasttime || artData?.hospdate || artData?.hosptime
                        || artData?.semdel || artData?.semdate || artData?.semtime || artData?.embdate || artData?.embtime ?
                        <div className=' w-2/5 border shadow '>
                            <p className={'bg-[#22668D]   text-sm font-medium text-white text-left p-1  rounded-t'}>Egg Collection Booking Instructions</p>

                            {(artData?.tridate || artData?.tritime) ? <div className='flex border-b-[1px]  border-gray-500   w-full  '>
                                <p className=' text-sm font-semibold py-1 px-2  text-gray-700  bg-gray-200 w-1/3'>TRIGGER</p>
                                <p className={'text-sm text-gray-500 py-1 px-2 w-2/3 '}>{(getCorrectDate(artData?.tridate) ? getCorrectDate(artData?.tridate) : '') + (artData?.tritime ? ' at ' + artData?.tritime : '')}</p>
                            </div> : ''}
                            {(artData?.fastdate || artData?.fasttime) ? <div className='flex border-b-[1px]  border-gray-500   w-full  '>
                                <p className=' text-sm font-semibold py-1 px-2  text-gray-700  bg-gray-200 w-1/3'>FASTING</p>
                                <p className={'text-sm text-gray-500 py-1 px-2 w-2/3 '}>{(getCorrectDate(artData?.fastdate) ? getCorrectDate(artData?.fastdate) : '') + (artData?.fasttime ? ' at ' + artData?.fasttime : '')}</p>
                            </div> : ''}
                            {(artData?.hospdate || artData?.hosptime) ? <div className='flex border-b-[1px]  border-gray-500   w-full  '>
                                <p className=' text-sm font-semibold py-1 px-2  text-gray-700  bg-gray-200 w-1/3'>HOSPITAL ADMISSION</p>
                                <p className={'text-sm text-gray-500 py-1 px-2 w-2/3 '}>{(getCorrectDate(artData?.hospdate) ? getCorrectDate(artData?.hospdate) : '') + (artData?.hosptime ? ' at ' + artData?.hosptime : '')}</p>
                            </div> : ''}
                            {(artData?.semdel) ? <div className='flex border-b-[1px]  border-gray-500   w-full  '>
                                <p className=' text-sm font-semibold py-1 px-2  text-gray-700  bg-gray-200 w-1/3'>SEMEN SAMPLE</p>
                                <p className={'text-sm text-gray-500 py-1 px-2 w-2/3'}>{artData?.semdel ? artData?.semdel : ''}</p>
                            </div> : ''}
                            {(artData?.semdate || artData?.semtime) ? <div className='flex border-b-[1px]  border-gray-500   w-full  '>
                                <p className=' text-sm font-semibold py-1 px-2  text-gray-700  bg-gray-200 w-1/3'>SEMEN SAMPLE Time</p>
                                <p className={'text-sm text-gray-500 py-1 px-2 w-2/3'}>{(getCorrectDate(artData?.semdate) ? getCorrectDate(artData?.semdate) : '') + (artData?.semtime ? ' at ' + artData?.semtime : '')}</p>
                            </div> : ''}
                            {(artData?.embdate || artData?.embtime) ? <div className='flex  border-gray-500   w-full  '>
                                <p className=' text-sm font-semibold py-1 px-2  text-gray-700  bg-gray-200 w-1/3'>EMBRYO TRANSFER</p>
                                <p className={'text-sm text-gray-500 py-1 px-2 w-2/3 '}>{(getCorrectDate(artData?.embdate) ? (getCorrectDate(artData?.embdate)) : '') + (artData?.embtime ? ' at ' + artData?.embtime : '')}</p>
                            </div> : ''}


                        </div>
                        : ''}

                    <div className=' w-3/5 '>
                        {isLuteal ? <div className='shadow w-full'>
                            <p className={'bg-[#22668D]  text-sm font-medium text-white text-left p-1 rounded-t border'}>Medications Post Egg Collection (Luteal Phase)
                            </p>
                            {getUniqueObjectsByKey(medication['Luteal'], 'drugmastername').map((item) => (
                                <div className='border-b-[1px]  border-b-gray-300 w-full mb-1'>
                                    <div className=' items-center justify-between'>
                                        <p className=' text-sbase font-bold pt-1 px-2   text-gray-700 '>{item.drugmastername}</p>
                                        <p className=' text-sbase  px-2 text-gray-500 '>{'Dose and Instructions:  Please administer on the following Dates / Date Ranges'}</p>
                                    </div>
                                    <p className={'text-sm text-gray-700 pb-1 px-2 '}>{"Dose: " + item.dose + " " + item.theunits + "  --  Administer " + item.showdates}</p>
                                </div>
                            ))}

                        </div> : ''}

                        {artData?.handnotes && <div className='shadow mt-1 w-full'> <p className={'bg-[#22668D]  text-sm font-medium text-white text-left p-1  rounded-t  '}>Notes</p>
                            <div className='flex    border-[1px] text-sm text-gray-500 py-1 px-2 border-b-gray-300   w-full  '>
                                {renderWithLineBreaks(artData?.handnotes)}
                            </div></div>}
                    </div>

                </div>
            </main >
            <footer className='flex mt-3 p-2 hidden'>
                <div className='w-full flex items-center'>
                    <img className='rounded-full  h-14' src={logo} alt={"logo"} />
                    <div className='border-gray-200  p-1 ml-2 '>
                        <p className='text-xss text-left  m-0 text-gray-700 font-medium'>
                            Document generated on {moment(new Date()).format('ddd DD-MMM-yyyy')}<br />
                            Do NOT rely solely on the information on this print-out. Always check the Current Treatment Plan in the FSF Database for changes.<br />
                            Form version 8.1 saved on 31-OCT-2022 09:41:04 AEST by Michelle Brown. </p>
                    </div>
                </div>
            </footer>
        </div >
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(StimPrint)