import { useState, React } from 'react';
import classNames from 'classnames';
import SideMenu from './SideMenu';
import './styles.css';
import { useAppContext } from '../../../AppContextProvider ';

function MainSideBarNew( props) {
  const user = useAppContext()
  // console.log(user, "c");
  const [isExpanded, setIsExpanded] = useState(false);
  const theme = useAppContext().themeDetails;
  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
    if (isExpanded) {
      document.getElementById('App').classList.add("ml-180");
      document.getElementById('App').classList.remove("ml-18");

    }
    else {
      document.getElementById('App').classList.remove("ml-180");
      document.getElementById('App').classList.add("ml-18");

    }
  };

  const sidebarClasses = classNames(
    theme?.sidebar + ' sidbarabsolute  text-white  w-4  py-8  top-18 left-0 overflow-auto transition-all duration-300 ease-in-out  '

  );
  const sidebarClassesUnMerged = classNames(
    theme?.sidebar + ' ' + theme?.thumb + ' ' + theme?.track + ' text-white  unMergedSideBarDiv px-0.5  pt-10 py-8  top-18 left-0 top-15 overflow-auto transition-all duration-300 ease-in-out  scrollbar  scrollbar-thin ',
  );

  const buttonClassesUnmerged = classNames(
    'hover:scale-125 absolute top-14 btnLeftSpacingExpanded drop-shadow-md mt-4 mr-4 h-6 w-6 rounded-full z-10	 flex justify-center items-center transition-all duration-300 ease-in-out bg-white'
  );

  const buttonClasses = classNames(
    'hover:scale-125 absolute top-14 btnLeftSpacingUnExpanded drop-shadow-md mt-4 mr-4 h-6 w-6 rounded-full z-10	 flex justify-center items-center transition-all duration-300 ease-in-out bg-white'
  );

  return (
    <>
      {isExpanded ? (
        <>
          <button onClick={toggleSidebar} className={buttonClasses} >
            <svg width="22" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_1_59)">
                <path d="M2 9L9.875 9M2 5.5L16 5.5M16 5.5L13.375 8.125M16 5.5L13.375 2.875M2 2L9.875 2"
                  stroke={theme?.iconColor}
                  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" shapeRendering="crispEdges" />
              </g>
              <defs>
                <filter id="filter0_d_1_59" x="0" y="0" width="18" height="11" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset />
                  <feGaussianBlur stdDeviation="0.5" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_59" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_59" result="shape" />
                </filter>
              </defs>
            </svg>
          </button>
          <div className={sidebarClasses}>
          </div>
        </>
      ) : (
        <>
          <button onClick={toggleSidebar} className={buttonClassesUnmerged} id="sidebarOpenButton">
            <svg width="22" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg" >
              <g filter="url(#filter0_d_1_1508)">
                <path d="M16 2H8.125M16 5.5H2M2 5.5L4.625 2.875M2 5.5L4.625 8.125M16 9H8.125"
                  stroke={theme?.iconColor}
                  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" shapeRendering="crispEdges" />
              </g>

            </svg>
          </button>
          <div className={sidebarClassesUnMerged} id="sideBarId">
            <SideMenu cplData={props.cplData}  />
          </div>
        </>
      )}


    </>
  );
}

export default MainSideBarNew;

