import React, { useState } from 'react'
import { connect } from 'react-redux'

import { ApiCall_GET } from '../../../Redux/Generic/action'

import { useAppContext } from '../../../AppContextProvider ';
import { useLocation, useNavigate } from 'react-router-dom';

import { useEffect } from 'react';
import { replaceEmptyObjectsWithNull, calculateBMIpatient, getPatientInformation } from '../../../common';


const CoupleInfo = ({ ApiCall_GET, coupleid }) => {

  const location = useLocation();

  let navigate = useNavigate();
  const theme = useAppContext().themeDetails;
  const [activeStep, setActiveStep] = useState(1);
  const [isSideBarShown, setisSideBarShown] = useState(false);
  const [coupleData, setcoupleData] = useState(1);
  // var patientInfo = getPatientInformation()


  useEffect(() => {
    ApiCall_GET('patient/LoadCoupleDetailsByCoupleId/' + coupleid, true).then(function (payload) {
      payload = replaceEmptyObjectsWithNull(payload)

      setcoupleData(payload)
    })

  }, [])


  // useEffect(() => {
  //   const patientInfo = getUserInformation('patient')

  //   ApiCall_GET('patient/GetPatientDetailsByCoupleId/' + coupleId, true).then(function (payload) {
  //   },[])

  const toggleSideBar = () => {
    setisSideBarShown(!isSideBarShown)
  }
  // const handelActiveStep = (step) => {
  //   setActiveStep(step);
  // };

  const handleTabClick = (step) => {
    setActiveStep(step);
  };
  return (
    <div className='w-full  h-full p-2'>
      {/* <div className={isSideBarShown ? 'w-5/6   h-full ' : 'w-full h-full bg-white rounded '}> */}
        {/* <div className={theme.bg_Light + ' flex items-between p-0.5  pl-2 rounded shadow '}>
          <div className='w-2/5 text-left'>
            <label className={theme.txt_color + ' pl-2text-base font-medium '}>Couple Info</label>
            <label className={theme.txt_color + ' ml-2 text-base font-medium '}> </label>
          </div>
          <div className='w-3/5 text-right flex justify-end'>
            <button
              onClick={() => navigate('/coupledetails', { state: { id: patientInfo.coupleId } })}
              className="flex items-center cursor-pointer justify-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:text-black hover:bg-yellow-400 focus:ring-4 focus:ring-gray-200 font-medium rounded text-sm px-3 py-1 mr-2"
            >
              <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.15716 0.607836C5.26072 0.711393 5.3125 0.834005 5.3125 0.975672C5.3125 1.11734 5.26072 1.23981 5.15716 1.34309L2.12293 4.37732L5.16752 7.42191C5.26417 7.51856 5.3125 7.63938 5.3125 7.78436C5.3125 7.92934 5.26072 8.05361 5.15716 8.15716C5.05361 8.26072 4.93099 8.3125 4.78933 8.3125C4.64766 8.3125 4.52519 8.26072 4.42191 8.15716L0.942381 4.66728C0.900958 4.62586 0.871548 4.58098 0.85415 4.53266C0.836753 4.48433 0.828192 4.43255 0.828468 4.37732C0.828468 4.32209 0.837167 4.27031 0.854564 4.22199C0.871962 4.17366 0.901234 4.12878 0.942381 4.08736L4.43226 0.59748C4.52892 0.500827 4.64808 0.4525 4.78974 0.4525C4.93141 0.4525 5.05388 0.504279 5.15716 0.607836Z" fill="#52575C" />
              </svg>
              <label className='pl-2 cursor-pointer'> Back to ART</label>
            </button>
          </div>
        </div> */}

        {/* Tab Body  */}
        {/* <div className={theme.thumb + ' ' + theme.track + ` bg-white relative  rounded-b--md shadow  overflow-y-auto scrollbar overflow-x-auto  scrollbar-thin h-[96%]`} > */}

        <div className={`${theme.thumb} ${theme.track}  ${theme.thin} w-full overflow-y-scroll scrollbar scrollbar-thin h-screen pb-40`}>
          {/* Tab Body Section 4 */}

          {/* Primary Information */}
          <div className='flex flex-row justify-between w-full'>
            <div className='w-full shadow  pb-2 font-medium bg-white  px-0.5 '>
              <div className={theme.tHedUpdte + ' flex justify-between text-white text-left p-1 rounded-t'}> <p className='text-sbase font-medium'>Primary Information</p></div>
              <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                <p className='w-1/2  text-sbase font-bold  text-gray-700'>Clinician</p>
                <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.username} </p>
              </div>

              {/* --------------------- */}

              <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                <p className='w-1/2  text-sbase font-bold  text-gray-700'>Clinic Location</p>
                <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.brandcode} </p>
              </div>
              <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                <p className='w-1/2  text-sbase font-bold  text-gray-700'>Chart Location</p>
                <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.ChartLocationName}</p>
              </div>
              <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                <p className='w-1/2  text-sbase font-bold  text-gray-700'>How did you hear about us?</p>
                <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.HowHear} </p>

              </div>
              {/* --------------- */}


              <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                <p className='w-1/2  text-sbase font-bold  text-gray-700'>Chill Patient?</p>
                <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData?.chillpatient ? 'Yes' : 'No'}</p>
                {/* {procedureTypes.length > 0 ? procedureTypes?.filter(x => x.value === treatmentPlanObj[0].procedureTypeId)[0]?.label : ''}
                                                        {treatmentPlanObj.procedureTypeName} */}
              </div>
            </div>
            {/* Referral information*/}
            <div className='w-full shadow  pb-2 font-medium bg-white px-0.5 '>
              <div className={theme.tHedUpdte + ' flex justify-between text-white text-left p-1 rounded-t'}> <p className='text-sbase font-medium'>Referral Information</p></div>
              <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                <p className='w-1/2  text-sbase font-bold  text-gray-700'>Referring GP Name</p>
                <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData?.PatientRefGPName}</p>
              </div>
              <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                <p className='w-1/2  text-sbase font-bold  text-gray-700'>Referring GP Clinic</p>
                <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientRefGPClinic} </p>
              </div>
              <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                <p className='w-1/2  text-sbase font-bold  text-gray-700'>Referring GP Address</p>
                <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientRefGPAdd
                  // + ' ' + coupleData.PatientRefGPSuburb + ' ' + coupleData.PatientRefGPState + ' ' + coupleData.PatientRefGPPostCode
                }</p>
              </div>
            </div>
            {/* Couple Requirements*/}
            <div className='w-full shadow  pb-2 font-medium bg-white  px-0.5 '>
              <div className={theme.tHedUpdte + ' flex justify-between text-white text-left p-1 rounded-t'}> <p className='text-sbase font-medium'>Couple Requirements</p></div>
              <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                <p className='w-1/2  text-sbase font-bold  text-gray-700'>Disability?</p>
                <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData?.disability ? coupleData.disability : 'No'}</p>
              </div>
              <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                <p className='w-1/2  text-sbase font-bold  text-gray-700'>Adjustments required?</p>
                <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.disabilityAdjustment} </p>
              </div>
              <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                <p className='w-1/2  text-sbase font-bold  text-gray-700'>Receive Marketing</p>
                <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.commAllowedMarketing ? 'Yes' : 'No'}</p>
              </div>
            </div>
          </div>
          <div className='w-full flex overflow-hidden  py-2' >
            {/* Patient Information */}
            <div className=' w-1/2 p-1'>
              <div className='w-full shadow  pb-2 font-medium bg-white  px-0.5 '>
                <div className={theme.tHedUpdte + ' flex justify-between text-white text-left p-1 rounded-t'}> <p className='text-sbase font-medium'>Patient Information</p></div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Last name</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientLastName}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Preferred First name</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientFirstName}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>DOB</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientDOB}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Maiden Name</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientMaidenName}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Middle Name</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientMiddleName ? coupleData.PatientMiddleName : 'N/A'}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Uploaded ID Card</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{ }</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Gender Identify As
                  </p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientGenderIdentifyAs ? coupleData.PatientGenderIdentifyAs : 'N/A'}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Intersex</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientIntersex ? coupleData.PatientIntersex : 'N/A'}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Sexuality</p>
                  <p className='cursor-pointer text-sbase  text-gray-700 flex items-center font-bold'>{coupleData.sexorientdesc ? coupleData.sexorientdesc : 'N/A'}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Pro-nouns</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.sexname ? coupleData.sexname : 'N/A'}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Security Word</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientSecurityWord}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Monash URN</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientMonashURN}</p>
                </div>
              </div>
              {/* Patient Contact Information */}
              <div className='w-full shadow  pb-2 font-medium bg-white px-0.5 '>
                <div className={theme.tHedUpdte + ' flex justify-between text-white text-left p-1 rounded-t'}> <p className='text-sbase font-medium'>Patient Contact Information</p></div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Residential Address</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientResAddress}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Postal Address</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientPostAddress}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Phone (H)</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientPhoneHome}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Phone (W)</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientPhoneWork ? coupleData.PatientPhoneWork : 'N/A'}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Phone (M)</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientPhoneMobile}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Email Address</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientEmail}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Preferred Contact Method</p>
                  <p className='cursor-pointer text-sbase  text-gray-700 flex items-center font-bold'>{coupleData.PatientPreferredContactMethod ? coupleData.PatientPreferredContactMethod : 'N/A'}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Emergency Contact Name</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientEmergencyName ? coupleData.PatientEmergencyName : 'N/A'}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Emergency Contact Number</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientEmergencyNumber ? coupleData.PatientEmergencyNumber : 'N/A'}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Work Suburb</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientWorkSuburb ? coupleData.PatientWorkSuburb : 'N/A'}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Work Postcode</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientWorkPostcode ? coupleData.PatientWorkPostcode : 'N/A'}</p>
                </div>
              </div>
              {/* Patient Medical Detail */}
              <div className='w-full shadow  pb-2 font-medium bg-white px-0.5 '>
                <div className={theme.tHedUpdte + ' flex justify-between text-white text-left p-1 rounded-t'}>
                  <p className='text-sbase font-medium'>Patient Medical Details</p>
                </div>
                {/* ------ */}

                <div className={'w-full flex flex-wrap border-b-[2px] border-b-gray-200 py-1 ' + theme.hoverBg}>
                  <div className='xl:w-1/2 w-full cursor-pointer'>
                    <div className={'text-left p-1 px-2  flex items-center w-full '}>
                      <p className='w-1/2  text-sbase font-bold  text-gray-700'>Medicare Card No.</p>
                      <p className='W-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientMedicareNumber}</p>
                    </div>
                    <div className={'text-left p-1 px-2 flex items-center w-full '}>
                      <p className='w-1/2  text-sbase font-bold  text-gray-700'>Full name on Medicare card:</p>
                      <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientMedicareName ? coupleData.PatientMedicareName + ' ' + coupleData.PatientMedicareSurname : 'N/A'}</p>
                    </div>
                  </div>
                  <div className='xl:w-1/2 w-full '>
                    <div className={'text-left p-1 px-2  flex items-center w-full '}>
                      <p className='w-1/2  text-sbase font-bold  text-gray-700'>Ref/IRN:</p>
                      <p className='w-1/2 text-sbase text-gray-700 flex items-center'>{coupleData.PatientReference ? coupleData.PatientReference : 'N/A'}</p>
                    </div>
                    <div className={'text-left p-1 px-2 flex items-center w-full '}>
                      <p className='w-1/2  text-sbase font-bold  text-gray-700'>Expiry:</p>
                      <p className='w-1/2 text-sbase text-gray-700 flex items-center'>{coupleData.PatientReferenceExpiry ? coupleData.PatientReferenceExpiry : 'N/A'}</p>
                    </div>
                  </div>
                </div>

                {/* ------ */}

                <div className={'w-full flex flex-wrap border-b-[2px] border-b-gray-200  p-2 ' + theme.hoverBg}>
                  <div className='xl:w-1/2 w-full flex'>
                    <p className='w-1/2  text-sbase font-bold  text-gray-700'>Health Care Card No.</p>
                    <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientPensionNumber ? coupleData.PatientPensionNumber : 'N/A'}</p>
                  </div>

                  <div className='xl:w-1/2 w-full flex'>
                    <p className='w-1/2  text-sbase font-bold  text-gray-700'>Expiry.</p>
                    <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientPensionExpiry ? coupleData.PatientPensionExpiry : 'N/A'}</p>
                  </div>
                </div>
                <div className={'w-full flex flex-wrap border-b-[2px] border-b-gray-200  p-2 ' + theme.hoverBg}>
                  <div className='xl:w-1/2 w-full flex'>
                    <p className='w-1/2  text-sbase font-bold  text-gray-700'>Private Heath Fund</p>
                    <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientPrivinsFund ? coupleData.PatientPrivinsFund : 'N/A'}</p>
                  </div>
                  <div className='xl:w-1/2 w-full flex'>
                    <p className='w-1/2  text-sbase font-bold  text-gray-700'>No</p>
                    <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientPrivinsFundNumber ? coupleData.PatientPrivinsFundNumber : 'N/A'}</p>
                  </div>
                  <div className='xl:w-1/2 w-full flex'>
                    <p className='w-1/2  text-sbase font-bold  text-gray-700'>Level of Cover</p>
                    <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientPrivinsFundLevelofCover ? coupleData.PatientPrivinsFundLevelofCover : 'N/A'}</p>
                  </div>
                </div>
                <div className={'w-full flex flex-wrap border-b-[2px] border-b-gray-200  p-2 ' + theme.hoverBg}>
                  <div className='xl:w-1/3 w-full flex'>
                    <p className='w-1/2  text-sbase font-bold  text-gray-700'>Height (cm)</p>
                    <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientHeight}</p>
                  </div>
                  <div className='xl:w-1/3 w-full flex'>
                    <p className='w-1/2  text-sbase font-bold  text-gray-700'>Weight (kg)</p>
                    <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientWeight}</p>
                  </div>
                  <div className='xl:w-1/3 w-full flex'>
                    <p className='w-1/2  text-sbase font-bold  text-gray-700'>BMI</p>
                    <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{calculateBMIpatient(coupleData.PatientWeight, coupleData.PatientHeight)}</p>
                  </div>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Allergies</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientAllergies ? coupleData.PatientAllergies : 'N/A'}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Occupation</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientOccupation ? coupleData.PatientOccupation : 'N/A'}</p>
                </div>
              </div>
              {/*Patient Nationality Details*/}
              <div className='w-full shadow  pb-2 font-medium bg-white px-0.5 '>
                <div className={theme.tHedUpdte + ' flex justify-between text-white text-left p-1 rounded-t'}> <p className='text-sbase font-medium'>Patient Nationality Details</p></div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Australian or New Zealand Citizen?</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientAus_Nz_Citizen ? 'Yes' : 'No'}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Aboriginal or Torres Strait Islander Origin?.</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerOccupation ? coupleData.PartnerOccupation : 'N/A'}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Country of Birth.</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientBirthCountry}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>State of Birth (If born Australia).</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientBirthState ? coupleData.PatientBirthState : 'N/A'}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Country of Residence.</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientCountry_Of_Residence ? coupleData.PatientCountry_Of_Residence : 'N/A'}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Ethnic Background.</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientEthnicOrigin}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>First Language.</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientFirstLanguage ? coupleData.PatientFirstLanguage : 'N/A'}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Interpreter Required?.</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerOccupation ? coupleData.PartnerOccupation : 'N/A'}</p>
                </div>

              </div>
            </div>
            {/* Partner Information */}
            <div className='w-1/2 p-1'>
              <div className='w-full shadow  pb-2 font-medium bg-white px-0.5 '>
                <div className={theme.tHedUpdte + ' flex justify-between text-white text-left p-1 rounded-t'}> <p className='text-sbase font-medium'>Partner Information</p></div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Last name</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerLastName}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Preferred First name
                  </p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerFirstName}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>DOB</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerDOB}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Maiden Name</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerMaidenName}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Middle Name </p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerMiddleName ? coupleData.PartnerMiddleName : 'N/A'}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Uploaded ID Card</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{ }</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Gender Identify As</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerGenderIdentifyAs ? coupleData.PartnerGenderIdentifyAs : 'N/A'}</p>
                </div>

                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Intersex</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerIntersex ? coupleData.PartnerIntersex : 'N/A'}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Sexuality</p>
                  <p className='cursor-pointer text-sbase  text-gray-700 flex items-center font-bold'>{coupleData.sexorientdesc ? coupleData.sexorientdesc : 'N/A'}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Pro-nouns</p>
                  <p className='cursor-pointer text-sbase  text-gray-700 flex items-center font-bold'>{coupleData.sexname ? coupleData.sexname : 'N/A'}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Security Word</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerSecurityWord}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Monash URN</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerMonashURN}</p>
                </div>
              </div>
              {/* Partner Contact Information */}
              <div className='w-full shadow  pb-2 font-medium bg-white px-0.5 '>
                <div className={theme.tHedUpdte + ' flex justify-between text-white text-left p-1 rounded-t'}> <p className='text-sbase font-medium'>Partner Contact Information</p></div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Residential Address</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerResAddress}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Postal Address</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerPostAddress}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Phone (H)</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerPhoneMobile}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Phone (W)</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerPhoneWork}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Phone (M)</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerPhoneMobile}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Email Address</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerEmail}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Preferred Contact Method</p>
                  <p className='cursor-pointer text-sbase  text-gray-700 flex items-center font-bold'>{coupleData.PartnerPreferredContactMethod ? coupleData.PartnerPreferredContactMethod : 'N/A'}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Emergency Contact Name</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerEmergencyName ? coupleData.PartnerEmergencyName : 'N/A'}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Emergency Contact Number</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerEmergencyNumber ? coupleData.PartnerEmergencyNumber : 'N/A'}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Work Suburb</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerWorkSuburb ? coupleData.PartnerWorkSuburb : 'N/A'}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Work Postcode</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerWorkPostcode ? coupleData.PartnerWorkPostcode : 'N/A'}</p>
                </div>
              </div>
              {/*Partner Medical Details */}
              <div className='w-full shadow  pb-2 font-medium bg-white px-0.5 '>

                {/* ---------------- */}

                <div className={theme.tHedUpdte + ' flex justify-between text-white text-left p-1 rounded-t'}>
                  <p className='text-sbase font-medium'>Partner Medical Details</p>
                </div>
                {/* ------ */}

                <div className={'w-full flex flex-wrap border-b-[2px] border-b-gray-200 py-1 ' + theme.hoverBg}>
                  <div className='xl:w-1/2 w-full cursor-pointer'>
                    <div className={'text-left p-1 px-2  flex items-center w-full '}>
                      <p className='w-1/2  text-sbase font-bold  text-gray-700'>Medicare Card No.</p>
                      <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerMedicareNumber}</p>
                    </div>
                    <div className={'text-left p-1 px-2  flex items-center w-full '}>
                      <p className='w-1/2  text-sbase font-bold  text-gray-700'>Full name on Medicare card:</p>
                      <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerMedicareName ? coupleData.PartnerMedicareName + ' ' + coupleData.PartnerMedicareSurname : 'N/A'}</p>
                    </div>
                  </div>
                  <div className='xl:w-1/2 w-full '>
                    <div className={'text-left p-1 px-2  flex items-center w-full '}>
                      <p className='w-1/2  text-sbase font-bold  text-gray-700'>Ref/IRN:</p>
                      <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerReference ? coupleData.PartnerReference : 'N/A'}</p>
                    </div>
                    <div className={'text-left p-1 px-2  flex items-center w-full '}>
                      <p className='w-1/2  text-sbase font-bold  text-gray-700'>Expiry:</p>
                      <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerReferenceExpiry ? coupleData.PartnerReferenceExpiry : 'N/A'}</p>
                    </div>
                  </div>
                </div>

                {/* -------------------- */}

                <div className={'w-full flex flex-wrap border-b-[2px] border-b-gray-200  p-2 ' + theme.hoverBg}>
                  <div className='xl:w-1/2 w-full flex'>
                    <p className='w-1/2  text-sbase font-bold  text-gray-700'>Health Care Card No.</p>
                    <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerPensionNumber ? coupleData.PartnerPensionNumber : 'N/A'}</p>
                  </div>
                  <div className='xl:w-1/2 w-full flex'>
                    <p className='w-1/2  text-sbase font-bold  text-gray-700'>Expiry.</p>
                    <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerPensionExpiry ? coupleData.PartnerPensionExpiry : 'N/A'}</p>
                  </div>
                </div>
                <div className={'w-full flex flex-wrap border-b-[2px] border-b-gray-200  p-2 ' + theme.hoverBg}>
                  <div className='xl:w-1/2 w-full flex'>
                    <p className='w-1/2  text-sbase font-bold  text-gray-700'>Private Heath Fund</p>
                    <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerPrivinsFund ? coupleData.PartnerPrivinsFund : 'N/A'}</p>
                  </div>
                  <div className='xl:w-1/2 w-full flex'>
                    <p className='w-1/2  text-sbase font-bold  text-gray-700'>No</p>
                    <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerPrivinsFundNumber ? coupleData.PartnerPrivinsFundNumber : 'N/A'}</p>
                  </div>
                  <div className='xl:w-1/2 w-full flex'>
                    <p className='w-1/2  text-sbase font-bold  text-gray-700'>Level of Cover</p>
                    <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerPrivinsFundLevelofCover ? coupleData.PartnerPrivinsFundLevelofCover : 'N/A'}</p>
                  </div>
                </div>

                <div className={'w-full flex flex-wrap border-b-[2px] border-b-gray-200  p-2 ' + theme.hoverBg}>
                  <div className='xl:w-1/3 w-full flex'>
                    <p className='w-1/2  text-sbase font-bold  text-gray-700'>Height (cm)</p>
                    <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerHeight}</p>
                  </div>
                  <div className='xl:w-1/3 w-full flex'>
                    <p className='w-1/2  text-sbase font-bold  text-gray-700'>Weight (kg)</p>
                    <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerWeight}</p>
                  </div>
                  <div className='xl:w-1/3 w-full flex'>
                    <p className='w-1/2  text-sbase font-bold  text-gray-700'>BMI</p>
                    <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{calculateBMIpatient(coupleData.PartnerWeight, coupleData.PartnerHeight)}</p>
                  </div>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Allergies.</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerAllergies ? coupleData.PartnerAllergies : 'N/A'}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Occupation.</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerOccupation ? coupleData.PartnerOccupation : 'N/A'}</p>
                </div>
              </div>
              {/*Partner Nationality Details*/}
              <div className='w-full shadow  pb-2 font-medium bg-white px-0.5 '>
                <div className={theme.tHedUpdte + ' flex justify-between text-white text-left p-1 rounded-t'}> <p className='text-sbase font-medium'>Partner Nationality Details</p></div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Australian or New Zealand Citizen?</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerAus_Nz_Citizen ? 'Yes' : 'No'}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Aboriginal or Torres Strait Islander Origin?.</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerOccupation ? coupleData.PartnerOccupation : 'N/A'}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Country of Birth.</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PatientBirthCountry}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>State of Birth (If born Australia).</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerBirthState ? coupleData.PartnerBirthState : 'N/A'}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Country of Residence.</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerCountry_Of_Residence ? coupleData.PartnerCountry_Of_Residence : 'N/A'}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Ethnic Background.</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerOccupation}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>First Language.</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerFirstLanguage ? coupleData.PartnerFirstLanguage : 'N/A'}</p>
                </div>
                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sbase font-bold  text-gray-700'>Interpreter Required?.</p>
                  <p className='w-1/2  text-sbase text-gray-700 flex items-center'>{coupleData.PartnerOccupation ? coupleData.PartnerOccupation : 'N/A'}</p>
                </div>
              </div>
            </div>
          </div>

       
      </div >
    </div >
  )
}

const mapStateToProps = (state) => ({

})
export default connect(mapStateToProps, {
  ApiCall_GET
})(CoupleInfo);
