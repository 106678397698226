import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { calculateDifference, getCorrectDate, getFormattedDateForInput, getScanResults, getUserInformation, getPatientInformation, loadClinicDropdown } from '../../../../../../../common'
import { useState } from 'react';
import { ApiCall_GET, ApiCall_POST } from '../../../../../../../Redux/Generic/action';
import Swal from 'sweetalert2';
import Draggable from 'react-draggable';

export const UpdateInCycleScan = ({ theme, scanList, ApiCall_POST, ApiCall_GET }) => {
    const [formObj, setFormObj] = useState(null);
    const [clinicianlist, setClinicianlist] = useState([]);
    const [nextScanDay, setNextScanDay] = useState(0);
    const [ShowModal, setShowModal] = useState(false)
    const [showAlert, setShowAlert] = useState(false);
    const [resultState, setResultState] = useState({ r: '', l: '' })
    const [nextBloodDay, setNextBloodDay] = useState(0);
    const [locationid, setLocationid] = useState(0);
    const [clinicsList, setClinicsList] = useState(null)
    const [hospitalList, setHospitalList] = useState([])

    const handleInputChange = (e) => {
        // Access the event object (e) here
        const { name, value } = e.target;
        // formObj.result_uterus = [formObj.result_uterus1, formObj.result_uterus2].filter(Boolean).join(",");

        if (name === 'scandate') {
            setFormObj({
                ...formObj,
                scandayofcycle: calculateDifference(formObj?.dayoneofcycle, getCorrectDate(value)) + 1, //getCycleDay(formObj?.dayoneofcycle, getCorrectDate(value)),
                [name]: value,
            });
        }
        else if (name === 'ClinicId') {
            // Only update the ClinicId value
            loadClinianList(Number(value) ? value : null)
            setFormObj({
                ...formObj,
                HospitalId: null,
                [name]: value,
            });
            // You may also need to update the hospital list based on the selected clinic
            setHospitalList(clinicsList.filter(x => x.locationid === Number(value)))
        }
        else {
            // Create a copy of the formObj state and update the value for the input field
            setFormObj({
                ...formObj,
                [name]: value,
            });
        }

    };
    useEffect(() => {

        var data = {
            scandate: (new Date()).toISOString().substr(0, 10),
            nextscan: '',
            ant_whentostart: '',
            assessingclinicianuserid: getUserInformation().UserId,
            logdayonedate: scanList[0].dayoneofcycle,
            dayoneofcycle: scanList[0].dayoneofcycle,
            scandayofcycle: calculateDifference(scanList[0]?.dayoneofcycle, (new Date()).toISOString().substr(0, 10)) + 1,
            result_r: '',
            result_l: '',
            result_endo: '',
            result_uterus: '',
            result_uterus1: '',
            result_uterus2: '',
            ClinicId: scanList[0].ClinicId,
            HospitalId: scanList[0].HospitalId,
            comments: ''
        };
        setFormObj(data);
        var locationIdVar = scanList[0]?.ClinicId || getPatientInformation()?.LocationId || getUserInformation()?.LocationId
        // ApiCall_GET('UserManagment/GetClinicianList/' + locationId, true).then(function (payload) {
        //     setClinicianlist(payload);
        // });
        loadClinianList(locationIdVar)
        setLocationid(Number(locationIdVar))



    }, [])

    const loadClinianList = (locationId) => {
        locationId = locationId || getPatientInformation() || getUserInformation()?.LocationId
        ApiCall_GET('UserManagment/GetClinicianList/' + locationId, true).then(function (payload) {
            setClinicianlist(payload);
            ApiCall_GET('UserManagment/GetClinicDetailsList/', true).then(function (payload) {
                setClinicsList(payload)
                setHospitalList(payload)
            });
            return;
        })
        setClinicianlist(0);
    };




    function handleSubmit() {
        formObj.result_l = getScanResults(resultState.l)
        formObj.result_r = getScanResults(resultState.r)
        formObj.artid = scanList[0].artid
        formObj.treatmentplanid = scanList[0].TreatmentPlanId
        formObj.coupleid = scanList[0].coupleid
        formObj.result_uterus = [formObj.result_uterus1, formObj.result_uterus2].filter(Boolean).join(",");

        ApiCall_POST('Art/SaveScanDetails', formObj, true).then(function (payload) {
            Swal.fire({
                icon: 'success',
                title: 'Scans have been saved.',
                showConfirmButton: false,
                timer: 1500
            })
            window.location.reload();
            setShowModal(false)
        })

    }

    const checkResults = () => {
        const elementsr = Array.from(document.getElementsByName('result_r'))
        let resultR = elementsr.map(element => element.value).reverse().join(',');

        const elementsl = Array.from(document.getElementsByName('result_l'))
        let resultL = elementsl.map(element => element.value).reverse().join(',');

        setResultState({ r: resultR, l: resultL })


        if (resultL.replaceAll(',', '').length === 0 && resultR.replaceAll(',', '').length === 0) {

            setShowModal(false)

            Swal.fire({
                icon: 'error',
                title: 'Please enter the scan results',
                showConfirmButton: true
            })
            return;
        }
    }
    const handlePinChange = (e) => {
        var value = e.target.value
        if (value.length > 4) {
            var userInfo = getUserInformation()
            if (userInfo.PinNumber === Number(value)) {
                handleSubmit();
                setShowModal(false);
            } else {
                setShowAlert(true);
            }
        } else {
            setShowAlert(false);
        }
        return;
    };

    return (
        <div className={" rounded py-0 px-2 mb-1 "}>
            <div className='flex justify-between'>
                <label className={theme.txt_color + " pt-1 text-sm  font-bold "} >Day : {formObj?.scandayofcycle && formObj?.scandate !== '' ? formObj?.scandayofcycle : <span className='text-sm text-red-400 font-medium '>--</span>}</label>
                {/* <div className={theme.txt_color + "  text-sm  flex font-bold "} >
                    <label className={theme.txt_color + "  text-sm  font-bold w-8 "} >Size : </label>

                    {Array.from({ length: 16 }).map((_, i) => (

                        // <p id={`l_${i + 10}`} name='result_readonly' className={'h-5  w-8 text-center mx-1  text-sm text-gray-700 '}>{(23 - i) === 23 ? ('>' + (23 - i)) : (23 - i) === 10 ? ('<' + (23 - i)) : (23 - i).toString()}</p>
                        // <p id={`l_${i + 10}`} name='result_readonly' className={'h-5  w-8 text-center mx-1  text-sm text-gray-700 '}>{(i + 10) === 10 ? '<' + (i + 10) : (i + 10) === 23 ? ('>' + (i + 10)) : (i + 10)}</p>
                        <p id={`l_${i + 10}`} name='result_readonly' className={'h-5  w-8 text-center mx-1  text-sm text-gray-700 '}>{(24 - i) === 24 ? ('>23') : (24 - i) === 9 ? ('<10') : (24 - i).toString()}</p>
                    ))}
                    </div> */}
            </div>
            <div className={theme.panelBg + ' flex justify-between  rounded'}>
                <div className='flex flex-wrap justify-betwen  p-2border rounded '>
                    <div className={'flex text-left   items-center flex-wrap w-1/3 '}>
                        <label className={" text-sm mx-2  w-24"} >Day One of Cycle </label>
                        <span className="text-gray-400 text-sm pl-2">{formObj?.dayoneofcycle}</span>
                    </div>
                    <div className={'flex text-left items-center flex-wrap  w-1/3 '}>
                        <label htmlFor="scandate" className={" text-sm mx-2 w-16  "} >Scan Date</label>
                        <input type='date' name='scandate' defaultValue={getFormattedDateForInput(formObj?.scandate)} min={formObj && formObj?.scandate && getCorrectDate(formObj?.scandate).split('-').reverse().join('-')} onChange={(e)=> handleInputChange(e)} className={theme.cheqColor + ' w-32 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />

                    </div>
                    <div className={'flex text-left    items-center flex-wrap  w-1/3 '}>
                        <label className={" text-sm mr-1    "} >Endometrial Thicknes</label>
                        <input type='text' name='result_endo' onChange={(e)=> handleInputChange(e)} value={formObj?.result_endo} className={theme.cheqColor + ' w-32 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />

                    </div>
                    <div className={'flex text-left pt-1  items-center flex-wrap  w-1/3  '}>
                        <label className={" text-sm mx-2 w-24  "} >Next Scan Date</label>
                        <input name='nextscan' defaultValue={getFormattedDateForInput(formObj?.nextscan)} type='date' onChange={(e) => { handleInputChange(e); setNextScanDay(calculateDifference(formObj?.dayoneofcycle ?? formObj?.dayoneofcycle, e.target.value) + 1) }} className={theme.cheqColor + ' w-32 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                        <i className='text-gray-400 text-sm ml-3'>{formObj?.nextscan && nextScanDay ? '~ Day-' + nextScanDay : ''}</i>
                    </div>
                    <div className={'flex text-left pt-1 items-center flex-wrap w-1/3'}>
                        <label className={" text-sm mx-2 w-32 "} >Next Blood Test Date</label>
                        <input name='NextBloodTestDate' value={formObj?.NextBloodTestDate} type='date' onChange={(e) => { handleInputChange(e); setNextBloodDay(calculateDifference(formObj?.dayoneofcycle ?? formObj?.dayoneofcycle, e.target.value) + 1) }} className={theme.cheqColor + ' w-32 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                        <i className='text-gray-400 text-sm ml-3'>{formObj?.NextBloodTestDate && nextBloodDay ? '~ Day-' + nextBloodDay : ''}</i>
                    </div>
                    <div className={'flex text-left pt-1 items-center flex-wrap w-1/3 '}>
                        <label className={" text-sm mx-2  "} >Scan Performed By</label>
                        <select name='assessingclinicianuserid' id='ddlClinican' onChange={(e)=> handleInputChange(e)} className={theme.cheqColor + ' w-32 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '}>
                            <option value={null}>~Select~</option>
                            {
                                clinicianlist && clinicianlist.map((item) => (
                                    <option
                                        value={item.userid}
                                        selected={formObj && formObj?.assessingclinicianuserid === item.userid}
                                    >{item?.username}  </option>
                                ))
                            }
                        </select>
                    </div>
                    <div className='flex text-left pt-1 flex-wrap items-center w-1/3 '>
                        <label className={"w-16 text-sm mx-2 text-left   "} >Uterus</label>
                        <div className='flex  gap-2 py-2'>
                            <select onChange={(e) => { handleInputChange(e) }} name='result_uterus1' className={theme.cheqColor + ' w-32 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none   '} >
                                <option key={0} value={''}>~Select~</option>
                                <option value={'Anteverted'}>Anteverted</option>
                                <option value={'Retoverted'}>Retoverted</option>
                                <option value={'Axial'}>Axial</option>
                            </select>
                            <select onChange={(e) => { handleInputChange(e) }} name='result_uterus2' className={theme.cheqColor + ' w-32 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none   '} >
                                <option key={0} value={''}>~Select~</option>
                                <option value={'Antiflexed'}>Antiflexed</option>
                                <option value={'Retroflexed'}>Retroflexed</option>
                            </select>
                        </div>
                        {/* <div className={' flex text-left justify-between items-center flex-wrap'}>
                            <input type='radio' id="AV" onChange={()=> handleInputChange} className={theme.cheqColor + ' border focus:outline-none'} value={'AV'} name='result_uterus' />
                            <label htmlFor="AV" className={" text-sm mx-2 "} >AV</label>
                            <input type='radio' id="RV" onChange={()=> handleInputChange} className={theme.cheqColor + ' border focus:outline-none'} value={'RV'} name='result_uterus' />
                            <label htmlFor="RV" className={" text-sm mx-2 "} >RV</label>

                        </div> */}

                    </div>
                    <div className='flex text-left pt-1  w-2/3  mb-0.5 '>
                        <p className='text-sm mx-2  w-28  '>Notes/Comments</p>
                        <textarea id="txtcomments" rows="1" onChange={(e)=> handleInputChange(e)} name="comments" className="  w-full text-sm px-1 py-0.5 border rounded accent-green-700  text-gray-700 focus:border-teal-500 focus:outline-none" placeholder="Add comments ..." />

                    </div>

                </div>
                <div className='p-2 pr-0 '>
                    <div className='flex items-center mb-2'>
                        <label className={"w-16 text-sm    "} >Clinic</label>
                        {/* <select onChange={(e) => { handleInputChange(e) }} name='result_uterus1' className={theme.cheqColor + ' w-48 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none   '} >
                            <option key={0} value={''}>~Select~</option>
                            <option value={'Anteverted'}>Anteverted</option>
                            <option value={'Retoverted'}>Retoverted</option>
                            <option value={'Axial'}>Axial</option>
                        </select> */}
                        <select name='ClinicId' id='ddlClinic' value={formObj?.ClinicId} onChange={(e)=> handleInputChange(e)} className={theme.cheqColor + ' w-40 shadow border rounded rounded-xl p-1 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '}>
                            <option value={null} selected={!formObj?.HospitalId}>~Select~</option>
                            {clinicsList && loadClinicDropdown(clinicsList, locationid, true)}
                        </select>
                        <label className={"w-32 text-sm  ml-10    "} >Procedure Location</label>
                        <select name='HospitalId' id='ddlHospital' value={formObj?.HospitalId} onChange={(e)=> handleInputChange(e)} className={theme.cheqColor + ' w-40 shadow border rounded rounded-xl p-1 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '}>
                            <option value={null} selected={!formObj?.HospitalId}>~Select~</option>
                            {hospitalList && loadClinicDropdown(hospitalList, locationid, false, formObj?.ClinicId)}
                        </select>
                    </div>
                    <div className={' flex  items-center  '}>
                        <div className='w-8'>
                            <label className={theme.txt_color + "  text-sm  font-bold "} >Size: </label>
                            <label className={" text-sm mr-3  w-8 "} >L</label>
                        </div>
                        {Array.from({ length: 16 }).map((_, i) => (
                            <div>
                                <p id={`l_${i + 10}`} name='result_readonly' className={'h-5  w-8 text-center mx-1  text-sm text-gray-700 '}>{(24 - i) === 24 ? ('>23') : (24 - i) === 9 ? ('<10') : (24 - i).toString()}</p>
                                <input
                                    type='number'
                                    id={`l_${24 - i}`}
                                    name='result_l'
                                    min={0} // Set minimum value to 11
                                    max={23} // Set maximum value to 23
                                    onChange={(e)=> handleInputChange(e)}
                                    className={'w-8 h-5 text-center mt-1 mx-1 shadow border rounded rounded  text-sm text-gray-700 '}
                                /></div>
                        ))}
                    </div>
                    <div className='flex items-center '>
                        <label className={" text-sm   w-8  "} >R</label>

                        {Array.from({ length: 16 }).map((_, i) => (
                            <input
                                type='number'
                                id={`r_${24 - i}`}
                                name='result_r'
                                min={0} // Set minimum value to 11
                                max={24} // Set maximum value to 23
                                onChange={(e)=> handleInputChange(e)}
                                className={'w-8 h-5 text-center mt-1 mx-1 shadow border rounded rounded  text-sm text-gray-700 '}
                            />
                        ))}
                    </div>
                    <div className={theme.panelBg + 'mt-2 rounded flex justify-end items-center  py-4'}>
                        <button className="bg-transparent mx-1 hover:bg-red-500 text-red-500 font-semibold text-sm hover:text-white  px-2 border border-red-300 hover:border-transparent rounded" > Cancel  </button>
                        <button className="bg-transparent ml-1 hover:bg-green-600 text-green-800 font-semibold text-sm hover:text-white  px-2 border border-green-500 hover:border-transparent rounded" onClick={() => { setShowModal(true); checkResults() }} >  Save</button>

                    </div>
                </div>
            </div>

            {ShowModal ? (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true" >
                            <div className="absolute inset-0 bg-gray-500 opacity-75" ></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true" ></span>
                        <Draggable>
                            <div className={`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform sm:my-8 sm:align-middle sm:max-w-lg sm:w-full  ${showAlert ? 'animate-pulse  border border-red-300 ' : ''}`}
                                role="dialog"
                                aria-modal="true"
                                aria-labelledby="modal-headline"
                            >

                                <div className={`bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative`}>
                                    {/* <button className=" absolute top-2 right-2 bg-transparent  hover:bg-red-500 text-red-500 font-semibold hover:text-white  px-1 border border-red-300 hover:border-transparent rounded"
                                        onClick={() => setShowModal(false)}>  X </button>
                                         */}
                                    <svg className={'absolute top-2 right-2  shadow  font-semibold cursor-pointer'}
                                        onClick={() => { setShowModal(false); }} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path className="hover:shadow   " d="M16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM12.6 14L9 10.4L5.4 14L4 12.6L7.6 9L4 5.4L5.4 4L9 7.6L12.6 4L14 5.4L10.4 9L14 12.6L12.6 14Z" fill="#ed4545" />
                                    </svg>
                                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline" > Saving Scans</h3>
                                    <div onMouseDown={(e) => e.stopPropagation()} className="my-3 ">
                                        <div className='w-full bg-gray-100 p-3 my-2  rounded-lg'>
                                            <div className='flex items-center'>
                                                <p className='leading-6 text-sbase ' >Enter your PIN</p>
                                            </div>
                                            <input type='password' name='PIN' placeholder='Enter PIN' autoFocus={true} maxLength={5} onChange={(e) => handlePinChange(e)} className={'rounded w-full bg-white px-2 py-2 text-sm font-semibold text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 outline-none '} />
                                        </div>
                                        {showAlert && (
                                            <div className="buzz-alert text-red-600 text-sm">
                                                Wrong password! Please try again.
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Draggable>
                    </div>
                </div>) : ''}


        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { ApiCall_POST, ApiCall_GET }

export default connect(mapStateToProps, mapDispatchToProps)(UpdateInCycleScan)