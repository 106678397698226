import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useAppContext } from '../../../../AppContextProvider ';
import { ApiCall_GET, ApiCall_POST } from '../../../../Redux/Generic/action';
import { addYellowClassOnChnage, calculateBMIpatient, getColorAndCategory, getMonthsDropdown, getYearsDropdown, replaceEmptyObjectsWithNull, showNotificationMessage } from '../../../../common';
import PinModal from '../../../Components/PinModal';
import GaugeChart from 'react-gauge-chart';

export const ClinicalHistory = ({ ApiCall_GET, ApiCall_POST, setshowModalClinical, ...props }) => {

    const coupleid = props.coupleId;
    const theme = useAppContext().themeDetails;
    const [formData, setformData] = useState({})
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        ApiCall_GET('Art/GetClinicalHistoryDetails/' + coupleid, true).then(function (payload) {
            setformData(replaceEmptyObjectsWithNull(payload))

        })
    }, [])

    const saveData = () => {

        ApiCall_POST('Art/SaveClinicalHistory', formData, true).then(function (payload) {
            showNotificationMessage('success', 'Information has been saved.', false)
            setShowModal(false)
        })
    };

    const handleInputChange = (e) => {
        const { name, value, checked, type } = e.target;
        addYellowClassOnChnage(e)

        if (name.includes('Comment')) {
            var obj = name.replace('Comment', '');
            setformData(prevFormData => ({
                ...prevFormData,
                [obj]: value?.trim() ? true : false,
                [name]: value
            }));
        } else if (name === 'partnerHeightCentimetres') {
            let bmi = calculateBMIpatient(value, formData.partnerWeightKilograms);
            setformData(prevFormData => ({
                ...prevFormData,
                [name]: value,
                partnerBMI: bmi,
            }));
        } else if (name === 'partnerWeightKilograms') {
            let bmi = calculateBMIpatient(formData.partnerHeightCentimetres, value);
            setformData(prevFormData => ({
                ...prevFormData,
                [name]: value,
                partnerBMI: bmi,
            }));
        } else if (name === 'patientHeightCentimetres') {
            let bmi = calculateBMIpatient(value, formData.patientWeightKilograms);
            setformData(prevFormData => ({
                ...prevFormData,
                [name]: value,
                patientBMI: bmi,
            }));
        } else if (name === 'patientWeightKilograms') {
            let bmi = calculateBMIpatient(formData.patientHeightCentimetres, value);

            setformData(prevFormData => ({
                ...prevFormData,
                [name]: value,
                patientBMI: bmi,
            }));
        } else if (type === 'checkbox') {
            setformData(prevFormData => ({
                ...prevFormData,
                [name]: checked
            }));
        }
        else if (name === 'durationOfInfertilityYears') {
            setformData(prevFormData => ({
                ...prevFormData,
                [name]: value.slice(-2)
            }));
        }
        else if (name === 'preliminaryFWorkUpByClinician') {
            setformData({
                ...formData,
                preliminaryFWorkUpByClinician: checked,
                preliminaryFWorkUpByEndocrinologist: false
            });
        } else if (name === 'preliminaryFWorkUpByEndocrinologist') {
            setformData(prevFormData => ({
                ...prevFormData,
                preliminaryFWorkUpByClinician: false,
                preliminaryFWorkUpByEndocrinologist: checked
            }));
        } else {
            setformData(prevFormData => ({
                ...prevFormData,
                [name]: value
            }));
        }

    };

    const patientData = getColorAndCategory(formData.patientBMI);
    const partnerData = getColorAndCategory(formData.partnerBMI);
    // const bmiRanges = [
    //     { name: 'Normal weight', min: 0, max: 34.9, color: '#3CB371', },
    //     { name: 'Overweight', min: 35, max: 39.9, color: '#ffd70061' },
    //     { name: 'Obesity', min: 40, max: 100, color: '#FF0000' },
    //     // { name: 'Obesity', min: 40, max: 74.9, color: '#FF4500' },
    //     // { name: 'Severe obesity', min: 75, max: 100, color: '#FF0000' },
    // ];

    // const patientpercentage = Math.min(formData.patientBMI / maxBmi, 1);
    // const partnerpercentage = Math.min((formData.partnerBMI / maxBmi), 1);

    return (


        <div className={`${theme.thumb} ${theme.track}  ${theme.thin} relative  w-full overflow-y-scroll scrollbar scrollbar-thin h-screen pb-32 p-1 px-2 bg-gray-200 rounded`}>
            {/* <div className={'pl-2 py-2 rounded flex items-center '}>
                <label className={'mr-4 font-normal cursor-pointer  border-b-[3px] border-b-amber-500 ' + theme.txt_color}>Clinical History Form</label>
            </div> */}

            <div className='w-full flex   rounded  rounded-lg   gap-2'>
                <div className='w-2/3   gap-2 '>
                    <div className={'w-full  flex gap-1 mb-1'}>
                        <div className={' text-left  w-1/3 shadow border-b-gray-200 flex-wrap   ' + theme.hoverBg}>
                            <div className={'bg-[#4d6d9a] text-white text-left p-2 rounded-t w-full'}>
                                <p className='text-sbase font-medium'>Referring GP's Name and Address</p>
                            </div>
                            <textarea id="message" onChange={(e) => handleInputChange(e)}
                                rows={formData.referringGP && Math.max(formData.referringGP.split('\n').length, +1)}
                                // rows="2"
                                name={"referringGP"}
                                style={{
                                    height: 'auto', // Allows automatic adjustment
                                   
                                }} className={" pb-0 block px-2 w-full text-sm  py-1 border border-white rounded accent-green-700  text-gray-900 focus:border-teal-500 focus:outline-none"} placeholder="Referring GP's Name" defaultValue={formData.referringGP} />
                        </div>

                        <div className={'w-1/3 shadow text-left rounded items-center bg-white ' + theme.hoverBg}>
                            <div className={'bg-[#4d6d9a] text-white text-left p-2 rounded-t'}>
                                <p className='text-sbase font-medium'>Preliminary work-up Male </p>
                            </div>
                            <div className='w-full flex-wrap gap-x-1 flex px-3 items-center'>
                                <div className={`flex rounded px-2 py-1 transition delay-150 duration-700 `}>
                                    <input id="mWorkUpByClinician"
                                        defaultChecked={formData.preliminaryMWorkUpByClinician}
                                        onClick={(e) => { handleInputChange(e) }
                                        } type='checkbox' className={theme.cheqColor + ' border focus:outline-none'} name={"preliminaryMWorkUpByClinician"} />
                                    <label htmlFor="mWorkUpByClinician" className={" font-normal text-sm text-gray-800  text-sm mx-2"} >Clinician</label>
                                </div>
                                <div className={`flex rounded py-1  px-2 transition delay-150 duration-700 `}>
                                    <input id="mWorkUpByUrologist" defaultChecked={formData.preliminaryMWorkUpByUrologist}
                                        onClick={(e) => { handleInputChange(e) }
                                        } type='checkbox' className={theme.cheqColor + ' border focus:outline-none'} name={"preliminaryMWorkUpByUrologist"} />
                                    <label htmlFor="mWorkUpByUrologist" className={" font-normal text-sm text-gray-800  text-sm mx-2"} >Urologist</label>
                                </div>
                                <div className={`flex  rounded py-1 px-2 transition delay-150 duration-700`}>
                                    <input
                                        id="mWorkUpByEndocrinologist"
                                        defaultChecked={formData.preliminaryMWorkUpByEndocrinologist}
                                        onClick={(e) => { handleInputChange(e) }}
                                        type="checkbox"
                                        className={theme.cheqColor + ' border focus:outline-none'}
                                        name="preliminaryMWorkUpByEndocrinologist"
                                    />
                                    <label htmlFor="mWorkUpByEndocrinologist" className={" font-normal text-sm text-gray-800 mx-2"}>Endocrinologist</label>
                                </div>
                            </div>
                        </div>
                        <div className={'w-1/3 shadow text-left rounded items-center bg-white ' + theme.hoverBg}>

                            <div className={'bg-[#4d6d9a] text-white text-left p-2 rounded-t'}>
                                <p className='text-sbase font-medium'>Preliminary work-up Female </p>
                            </div>
                            <div className='w-full flex gap-x-1 px-1  items-center '>
                                <div className='rounded mb-0.5 w-1/2 px-2 py-1 flex items-center justify-'>
                                    <input type='radio' id="preliminaryFWorkUpByClinicians" checked={formData.preliminaryFWorkUpByClinician} onChange={(e) => handleInputChange(e)} className={theme.cheqColor + ' border focus:outline-none'} name='preliminaryFWorkUpByClinician' />
                                    <label htmlFor="preliminaryFWorkUpByClinicians" className={" text-sm px-2 "} >Clinician</label>
                                </div>
                                <div className='rounded w-full px-2 py-1 flex items-center'>
                                    <input type='radio' id="preliminaryFWorkUpByEndocrinologists" checked={formData.preliminaryFWorkUpByEndocrinologist} onChange={(e) => handleInputChange(e)} className={theme.cheqColor + ' border focus:outline-none'} name='preliminaryFWorkUpByEndocrinologist' />
                                    <label htmlFor="preliminaryFWorkUpByEndocrinologists" className={" text-sm px-2 "} >Endocrinologist{formData.preliminaryFWorkUpByEndocrinologist}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex w-full  items-between my-1  gap-2 '>
                        <div className='w-1/2   rounded-t'>
                            <div className='w-full bg-[#fff6f6] rounded shadow  rounded'>
                                <div className={'bg-[#FE9696] p-2  text-gray-900 text-left  shadow rounded-t'}><p className=' text-sbase font-medium '>Aetiology (Female)</p></div>
                                <div className={'text-left px-4 p-1 flex ' + theme.hoverBg}>
                                    <p className='cursor-pointer text-sbase font-normal  w-1/2 text-gray-900'>Idiopathic (Female)</p>
                                    <textarea rows="1" style={{ lineHeight: "1" }} type='text' onChange={(e) => { handleInputChange(e) }} name='aetiologyIdiopathicComment' defaultValue={formData.aetiologyIdiopathicComment} className={"p-2.5 w-1/2 text-sm px-1 py-1 border border-white rounded accent-green-700 text-gray-900 focus:border-teal-500 focus:outline-none "} />
                                </div>
                                <div className={'  text-left px-4 p-1 flex  border-b-[0.1px] border-b-gray-50  cursor-pointer  ' + theme.hoverBg}>
                                    <p className='cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Polycystic Ovaries (PCOS):</p>
                                    <textarea rows="1" style={{ lineHeight: "1" }} type='text' onChange={(e) => { handleInputChange(e) }} name='aetiologyPolycysticOvariesComment' defaultValue={formData.aetiologyPolycysticOvariesComment} className={" p-2.5 w-1/2 text-sm px-1 py-1 border border-white rounded accent-green-700  text-gray-900 focus:border-teal-500 focus:outline-none "} />
                                </div>
                                <div className={`  text-left px-4 p-1 flex  border-b-[0.1px] border-b-gray-50  cursor-pointer  ${formData.aetiologyPrematureOvarianFailureComment_changed ? 'bg-yellow-200 ' : ''}` + theme.hoverBg}>
                                    <p className='cursor-pointer text-sbase font-normal  w-1/2 text-gray-900'>Premature Ovarian Failure:</p>
                                    <textarea rows="1" style={{ lineHeight: "1" }} type='text' onChange={(e) => { handleInputChange(e) }} name='aetiologyPrematureOvarianFailureComment' defaultValue={formData.aetiologyPrematureOvarianFailureComment} className={" p-2.5 w-1/2 text-sm px-1 py-1 border border-white rounded accent-green-700  text-gray-900 focus:border-teal-500 focus:outline-none  "} />
                                </div>
                                <div className={'  text-left px-4 p-1 flex  border-b-[0.1px] border-b-gray-50  cursor-pointer  ' + theme.hoverBg}>
                                    <p className='cursor-pointer text-sbase font-normal  w-1/2 text-gray-900'>Endometriosis:</p>
                                    <textarea rows="1" style={{ lineHeight: "1" }} type='text' onChange={(e) => { handleInputChange(e) }} name='aetiologyEndometriosisComment' defaultValue={formData.aetiologyEndometriosisComment} className={"  p-2.5 w-1/2 text-sm px-1 py-1 border border-white rounded accent-green-700  text-gray-900 focus:border-teal-500 focus:outline-none "} />
                                </div>
                                <div className={'  text-left px-4 p-1 flex  border-b-[0.1px] border-b-gray-50  cursor-pointer  ' + theme.hoverBg}>
                                    <p className='cursor-pointer text-sbase font-normal  w-1/2 text-gray-900'>Ovulation Disorder:</p>
                                    <textarea rows="1" style={{ lineHeight: "1" }} type='text' onChange={(e) => { handleInputChange(e) }} name='aetiologyOvulationDisorderComment' defaultValue={formData.aetiologyOvulationDisorderComment} className={" w-1/2 text-sm px-1 py-1 border border-white rounded accent-green-700  text-gray-900 focus:border-teal-500 focus:outline-none "} />
                                </div>
                                <div className={'  text-left px-4 p-1 flex  border-b-[0.1px] border-b-gray-50  cursor-pointer  ' + theme.hoverBg}>
                                    <p className='cursor-pointer text-sbase font-normal  w-1/2 text-gray-900'>Hypo Hypo:</p>
                                    <textarea rows="1" style={{ lineHeight: "1" }} type='text' onChange={(e) => { handleInputChange(e) }} name='aetiologyHypoHypoComment' defaultValue={formData.aetiologyHypoHypoComment} className={"  p-2.5 w-1/2 text-sm px-1 py-1 border border-white rounded accent-green-700  text-gray-900 focus:border-teal-500 focus:outline-none "} />
                                </div>
                                <div className={'  text-left px-4 p-1 flex  border-b-[0.1px] border-b-gray-50  cursor-pointer  ' + theme.hoverBg}>
                                    <p className='cursor-pointer text-sbase font-normal  w-1/2 text-gray-900'>Tubal Factors:</p>
                                    <textarea rows="1" style={{ lineHeight: "1" }} type='text' onChange={(e) => { handleInputChange(e) }} name='aetiologyTubalComment' defaultValue={formData.aetiologyTubalComment} className={"  p-2.5 w-1/2 text-sm px-1 py-1 border border-white rounded accent-green-700  text-gray-900 focus:border-teal-500 focus:outline-none "} />
                                </div>
                                <div className={'  text-left px-4 p-1 flex  border-b-[0.1px] border-b-gray-50  cursor-pointer  ' + theme.hoverBg}>
                                    <p className='cursor-pointer text-sbase font-normal  w-1/2 text-gray-900'>Thyroid Disorders:</p>
                                    <textarea rows="1" style={{ lineHeight: "1" }} type='text' onChange={(e) => { handleInputChange(e) }} name='aetiologyThyroidDisordersComment' defaultValue={formData.aetiologyThyroidDisordersComment} className={"  p-2.5 w-1/2 text-sm px-1 py-1 border border-white rounded accent-green-700  text-gray-900 focus:border-teal-500 focus:outline-none "} />
                                </div>
                            </div>
                            <div className='w-full bg-[#f2faff] rounded shadow '>
                                <div className={'bg-[#86b3d1] mt-2 text-gray-800 text-left p-1 rounded-t shadow '}>
                                    <p className='p-1 text-sbase font-medium'>Aetiology (Other)</p>
                                </div>
                                <div className={'  text-left px-4 p-1 flex  border-b-[0.1px] border-b-gray-50  cursor-pointer  ' + theme.hoverBg}>
                                    <p className='cursor-pointer text-sbase font-normal  w-1/2 text-gray-900'>Male Factor (legacy field - please use newer options):</p>
                                    <textarea rows="1" style={{ lineHeight: "1" }} type='text' name='aetiologyMaleFactorComment' defaultValue={formData.aetiologyMaleFactorComment} onChange={(e) => handleInputChange(e)} className={"   w-1/2 text-sm px-1 py-1 border border-white rounded accent-green-700  text-gray-900 focus:border-teal-500 focus:outline-none "} />
                                </div>
                                <div className={'  text-left px-4 p-1 flex  border-b-[0.1px] border-b-gray-50  cursor-pointer  ' + theme.hoverBg}>
                                    <p className='cursor-pointer text-sbase font-normal  w-1/2 text-gray-900'>Social:</p>
                                    <textarea rows="1" style={{ lineHeight: "1" }} type='text' onChange={(e) => { handleInputChange(e) }} name='aetiologySocialComment' defaultValue={formData.aetiologySocialComment} className={"   w-1/2 text-sm px-1 py-1 border border-white rounded accent-green-700  text-gray-900 focus:border-teal-500 focus:outline-none "} />
                                </div>
                                <div className={'  text-left px-4 p-1 flex  border-b-[0.1px] border-b-gray-50  cursor-pointer  ' + theme.hoverBg}>
                                    <p className='cursor-pointer text-sbase font-normal  w-1/2 text-gray-900'>Maternal Age:</p>
                                    <textarea style={{ lineHeight: "1" }} rows="1" type='text' onChange={(e) => { handleInputChange(e) }} name='aetiologyMaternalAgeComment' defaultValue={formData.aetiologyMaternalAgeComment} className={"   w-1/2 text-sm px-1 py-1 border border-white rounded accent-green-700  text-gray-900 focus:border-teal-500 focus:outline-none "} />
                                </div>
                                <div className={'  text-left px-4 p-1 flex  border-b-[0.1px] border-b-gray-50  cursor-pointer  ' + theme.hoverBg}>
                                    <p className='cursor-pointer text-sbase font-normal  w-1/2 text-gray-900'>Other:</p>
                                    <textarea rows="1" style={{ lineHeight: "1" }} type='text' onChange={(e) => { handleInputChange(e) }} name='aetiologyOtherComment' defaultValue={formData.aetiologyOtherComment} className={"   w-1/2 text-sm px-1 py-1 border border-white rounded accent-green-700  text-gray-900 focus:border-teal-500 focus:outline-none "} />
                                </div>
                            </div>
                        </div>
                        <div className='w-1/2 rounded shadow bg-[#f0f5ff]'>
                            <div className={'bg-[#A9C6FF] text- text-left p-1 rounded-t'}>
                                <p className='  text-sbase font-medium text-gray-800 p-1'>Aetiology (Male)</p> </div>
                            <div className={' text-left p-1 px-4  flex  border-b-[0.1px] border-b-gray-50  cursor-pointer ' + theme.hoverBg}>
                                <p className='cursor-pointer text-sbase font-normal  w-1/2 text-gray-800'>Idiopathic (Male):</p>
                                <textarea
                                    value={formData.aetiologyMaleIdiopathicComment}
                                    onChange={(e) => handleInputChange(e)} name="aetiologyMaleIdiopathicComment" rows="1" style={{ lineHeight: "1" }} className={"  w-1/2 text-sm px-1 py-1 border border-white rounded accent-green-700  text-gray-800 focus:border-teal-500 focus:outline-none "} />
                            </div>
                            <div className={' text-left px-4 p-1 flex  border-b-[0.1px] border-b-gray-50  cursor-pointer ' + theme.hoverBg}>
                                <p className='cursor-pointer text-sbase font-normal  w-1/2 text-gray-800'>Genetic - Y deletion:</p>
                                <textarea type='text' onChange={(e) => { handleInputChange(e) }} rows="1" style={{ lineHeight: "1" }} name='aetiologyGeneticYdeletionComment' defaultValue={formData.aetiologyGeneticYdeletionComment} className={"  w-1/2 text-sm px-1 py-1 border border-white  rounded accent-green-700  text-gray-800 focus:border-teal-500 focus:outline-none "} />
                            </div>
                            <div className={' text-left px-4 p-1 flex  border-b-[0.1px] border-b-gray-50  cursor-pointer ' + theme.hoverBg}>
                                <p className='cursor-pointer text-sbase font-normal  w-1/2 text-gray-800'>Testis damage - other:</p>
                                <textarea rows="1" style={{ lineHeight: "1" }} type='text' onChange={(e) => { handleInputChange(e) }} name='aetiologyTestisDamageOtherComment' defaultValue={formData.aetiologyTestisDamageOtherComment} className={"  w-1/2 text-sm px-1 py-1 border border-white  rounded accent-green-700  text-gray-800 focus:border-teal-500 focus:outline-none "} />
                            </div>
                            <div className={' text-left px-4 p-1 flex  border-b-[0.1px] border-b-gray-50  cursor-pointer ' + theme.hoverBg}>
                                <p className='cursor-pointer text-sbase font-normal  w-1/2 text-gray-800'>Testis damage - cancer treatment:</p>
                                <textarea rows="1" style={{ lineHeight: "1" }} type='text' onChange={(e) => { handleInputChange(e) }} name='aetiologyTestisDamageCancerTreatmentComment' defaultValue={formData.aetiologyTestisDamageCancerTreatmentComment} className={"  w-1/2 text-sm px-1 py-1 border border-white  rounded accent-green-700  text-gray-800 focus:border-teal-500 focus:outline-none "} />
                            </div>
                            <div className={' text-left px-4 p-1 flex  border-b-[0.1px] border-b-gray-50  cursor-pointer ' + theme.hoverBg}>
                                <p className='cursor-pointer text-sbase font-normal  w-1/2 text-gray-800'>Vasectomy:</p>
                                <textarea rows="1" style={{ lineHeight: "1" }} type='text' onChange={(e) => { handleInputChange(e) }} name='aetiologyVasectomyComment' defaultValue={formData.aetiologyVasectomyComment} className={"  w-1/2 text-sm px-1 py-1 border border-white  rounded accent-green-700  text-gray-800 focus:border-teal-500 focus:outline-none "} />
                            </div>
                            <div className={' text-left px-4 p-1 flex  border-b-[0.1px] border-b-gray-50  cursor-pointer ' + theme.hoverBg}>
                                <p className='cursor-pointer text-sbase font-normal  w-1/2 text-gray-800'>Congenital absence of the vas deferens / cystic fibrosis:</p>
                                <textarea rows="1" style={{ lineHeight: "1" }} type='text' onChange={(e) => { handleInputChange(e) }} name='aetiologyAbsenceOfVasDeferensOrCysticFibrosisComment' defaultValue={formData.aetiologyAbsenceOfVasDeferensOrCysticFibrosisComment} className={"  w-1/2 text-sm px-1 py-1 border border-white  rounded accent-green-700  text-gray-800 focus:border-teal-500 focus:outline-none "} />
                            </div>
                            <div className={' text-left px-4 p-1 flex  border-b-[0.1px] border-b-gray-50  cursor-pointer ' + theme.hoverBg}>
                                <p className='cursor-pointer text-sbase font-normal  w-1/2 text-gray-800'>Genetic - Klinefelter:</p>
                                <textarea type='text' onChange={(e) => { handleInputChange(e) }}
                                    name='aetiologyGeneticKlinefelterComment' defaultValue={formData.aetiologyGeneticKlinefelterComment} rows="1" style={{ lineHeight: "1" }} className={"  w-1/2 text-sm px-1 py-1 border border-white rounded accent-green-700  text-gray-800 focus:border-teal-500 focus:outline-none "} />
                            </div>
                            <div className={' text-left px-4 p-1 flex  border-b-[0.1px] border-b-gray-50  cursor-pointer ' + theme.hoverBg}>
                                <p className='cursor-pointer text-sbase font-normal  w-1/2 text-gray-800'>Genetic - Other Aneuploidies:</p>
                                <textarea rows="1" style={{ lineHeight: "1" }} type='text' onChange={(e) => { handleInputChange(e) }} name='aetiologyGeneticOtherAneuploidiesComment' defaultValue={formData.aetiologyGeneticOtherAneuploidiesComment} className={"  w-1/2 text-sm px-1 py-1 border border-white rounded accent-green-700  text-gray-800 focus:border-teal-500 focus:outline-none "} />
                            </div>
                            <div className={' text-left px-4 p-1 flex  border-b-[0.1px] border-b-gray-50  cursor-pointer ' + theme.hoverBg}>
                                <p className='cursor-pointer text-sbase font-normal  w-1/2 text-gray-800'>Obstructive disorder:</p>
                                <textarea rows="1" style={{ lineHeight: "1" }} type='text' onChange={(e) => { handleInputChange(e) }} name='aetiologyObstructiveDisorderComment' defaultValue={formData.aetiologyObstructiveDisorderComment} className={"  w-1/2 text-sm px-1 py-1 border border-white rounded accent-green-700  text-gray-800 focus:border-teal-500 focus:outline-none "} />
                            </div>
                            <div className={' text-left px-4 p-1 flex  border-b-[0.1px] border-b-gray-50  cursor-pointer ' + theme.hoverBg}>
                                <p className='cursor-pointer text-sbase font-normal  w-1/2 text-gray-800'>Gonadotrophin deficiency:</p>
                                <textarea rows="1" style={{ lineHeight: "1" }} type='text' onChange={(e) => { handleInputChange(e) }} name='aetiologyGonadotrophinDeficiencyComment' defaultValue={formData.aetiologyGonadotrophinDeficiencyComment} className={"  w-1/2 text-sm px-1 py-1 border border-white rounded accent-green-700  text-gray-800 focus:border-teal-500 focus:outline-none "} />
                            </div>
                            <div className={' text-left px-4 p-1 flex  border-b-[0.1px] border-b-gray-50  cursor-pointer ' + theme.hoverBg}>
                                <p className='cursor-pointer text-sbase font-normal  w-1/2 text-gray-800'>Erectile dysfunction (incl. psychosexual):</p>
                                <textarea rows="1" style={{ lineHeight: "1" }} type='text' onChange={(e) => { handleInputChange(e) }} name='aetiologyErectileDysfunctionComment' defaultValue={formData.aetiologyErectileDysfunctionComment} className={"  w-1/2 text-sm px-1 py-1 border border-white rounded accent-green-700  text-gray-800 focus:border-teal-500 focus:outline-none "} />
                            </div>
                            <div className={' text-left px-4 p-1 flex  border-b-[0.1px] border-b-gray-50  cursor-pointer ' + theme.hoverBg}>
                                <p className='cursor-pointer text-sbase font-normal  w-1/2 text-gray-800'>Ejaculatory disorders (incl. spinal injury, retrograde, anejaculation):</p>
                                <textarea
                                    rows="1"
                                    style={{ lineHeight: "1" }}
                                    type="text"
                                    onChange={(e) => handleInputChange(e)}
                                    name="aetiologyEjaculatoryDisordersComment"
                                    defaultValue={formData.aetiologyEjaculatoryDisordersComment}
                                    className={`p-2.5 w-1/2 text-sm px-1 py-1 border border-white rounded accent-green-700 text-gray-800 focus:border-teal-500 focus:outline-none`}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={' text-left w-full border-gray-200 flex-wrap rounded shadow'}>
                        <div className={'bg-[#86B3D1] text-white text-left p-2 rounded-t'}><p className='text-sbase font-medium'>Height, weight & BMI calculation</p></div>
                        <div className={`flex items-center text-left p-2 py-1 gap-2 border-b  w-full   border-b-gray-200  bg-white ` + theme.hoverBg}>
                            <p className="text-sm w-1/5 font-normal text-gray-900">Duration of Infertility</p>

                            <div className="relative  gap-x-6 flex justify-start items-center w-2/5">
                                <p className="text-sm w- font-normal text-gray-900 px-2">Year</p>

                                <select onChange={(e) => { handleInputChange(e); }} value={'20' + formData?.durationOfInfertilityYears} name={'durationOfInfertilityYears'}
                                    className="appearance-none  block w-2/3 rounded-md p-1 px-2 text-sm focus:border-teal-500 focus:outline-none"
                                >
                                    {getYearsDropdown()}
                                </select>

                            </div>
                            <div className="relative flex justify-between items-center w-2/5">
                                <p className="text-sm w-1/3 font-normal text-gray-900 px-2">Month</p>
                                <select onChange={(e) => { handleInputChange(e); }} value={formData?.durationOfInfertilityMonths}
                                    className="appearance-none block w-full rounded-md p-1 px-2 text-sm focus:border-teal-500 focus:outline-none"
                                    name={'durationOfInfertilityMonths'}
                                >
                                    {getMonthsDropdown()}
                                </select>

                            </div>
                        </div>
                        <div className='flex flex-wrap w-full bg-white'>
                            <div className={`flex items-center text-left p-2  w-1/2 border-r-[2px]  border-b-gray-200  ${theme.hoverBg} `} style={{ backgroundColor: patientData?.color }}>
                                <p className='cursor-pointer text-sbase font-normal w-1/3 text-gray-900'>Patient: </p>
                                <div className=' w-4/5 flex justify-between'>
                                    <div className={`w-2/3  `}>
                                        <div className='flex'>
                                            <p className='cursor-pointer text-sbase w-32 text-gray-900'> Height (cm) </p>
                                            <input type='number' onChange={(e) => { handleInputChange(e); }} value={formData?.patientHeightCentimetres} name='patientHeightCentimetres'
                                                className={theme.cheqColor + ' border  border-white rounded rounded-xl px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none w-2/3 '} />
                                        </div>
                                        <div className='flex  my-1'>
                                            <p className='cursor-pointer text-sbase w-32 text-gray-900'>Weight (kg)</p>
                                            <input type='number' onChange={(e) => { handleInputChange(e) }} value={formData.patientWeightKilograms} name='patientWeightKilograms'
                                                className={theme.cheqColor + '  border border-white rounded rounded-xl px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none w-2/3 '} />
                                        </div>
                                        <div className='flex'>
                                            <p className='cursor-pointer text-sbase w-32 text-gray-900'>BMI </p>

                                            <input type='number' disabled value={formData.patientBMI} name='patientWeightKilograms'
                                                className={theme.cheqColor + '   rounded rounded-xl px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none w-2/3 '} />
                                        </div>
                                    </div>
                                    <div className='flex w-1/3 items-center justify-center'>

                                        {patientData &&
                                            <p className='font-normal'>{patientData?.icon}</p>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={'flex items-center text-left p-2  w-1/2  border-b-gray-200   bg-white ' + theme.hoverBg} style={{ backgroundColor: partnerData?.color }}>
                                <p className='cursor-pointer text-sbase font-normal w-1/3 text-gray-900'>Partner: </p>
                                <div className=' w-4/5 flex justify-between gap-3'>
                                    <div className=' w-2/3  '>
                                        <div className='flex'>
                                            <p className='cursor-pointer text-sbase w-32 text-gray-900'>Height (cm)  </p>
                                            <input type='number' name='partnerHeightCentimetres'
                                                value={formData.partnerHeightCentimetres}
                                                onChange={(e) => { handleInputChange(e) }}
                                                className={theme.cheqColor + ' border  border-white rounded rounded-xl px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none w-2/3 '} />
                                        </div>
                                        <div className='flex  my-1'>
                                            <p className='cursor-pointer text-sbase w-32 text-gray-900'>Weight (kg)</p>
                                            <input type='number' name='partnerWeightKilograms' value={formData.partnerWeightKilograms}
                                                onChange={(e) => { handleInputChange(e) }} className={theme.cheqColor + ' border  border-white rounded rounded-xl px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none w-2/3 '} />
                                        </div>
                                        <div className='flex'>
                                            <p className='cursor-pointer text-sbase w-32 text-gray-900'>BMI</p>
                                            <input type='number' disabled value={formData.partnerBMI} name='partnerBMI' className={theme.cheqColor + '   rounded rounded-xl px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none w-2/3 '} />
                                        </div>
                                    </div>

                                    <div className='flex w-1/3 items-center justify-center'>
                                        {partnerData && <p className='font-normal'>{partnerData.icon}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-1/3   gap-2'>

                    <div className={'bg-[#4d6d9a]  text-white text-left p-2 rounded-t'}>
                        <p className='text-sbase font-medium'> Previous ART Treatment History</p>
                    </div>
                    <div className='w-full flex rounded flex-wrap shadow pb-2 bg-white'>
                        <div className={'w-full  text-left   rounded  border-b-gray-200 bg-white ' + theme.hoverBg}>
                            <div className={'w-full  px-4 p-2 flex ' + theme.hoverBg}>
                                <p className='cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Previous OI Cycles:</p>
                                <input type='text' name='previoustreatment_OI_NumberOf' defaultValue={formData.previoustreatment_OI_NumberOf} onChange={(e) => handleInputChange(e)} className={theme.cheqColor + ' border border-white   rounded rounded-xl px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-700  '} />
                            </div>
                        </div>

                        <div className=' w-full'>
                            <div className={'w-full flex text-left p-2 items-center border  border-gray-200 bg-white ' + theme.hoverBg}>
                                <p className='w-1/3  text-sbase font-medium  text-gray-900  '>Previous IUI Cycles</p>
                                <div className='w-2/3  pt-2 px-2  items-center  border-l'>
                                    <div className=' w-full flex items-center justify-between'>
                                        <p className='w-1/2  text-sbase font-normal  text-gray-900'>Number of IUIs:</p>
                                        <input type='text' name='previoustreatment_IUI_NumberOf' defaultValue={formData.previoustreatment_IUI_NumberOf} onChange={(e) => handleInputChange(e)} className={theme.cheqColor + ' border border-white w-1/2  rounded rounded-xl px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-500  '} />
                                    </div>

                                    <div className=' w-full  items-center'>
                                        <div className=' w-full flex items-center'>
                                            <p className='w-1/2  my-1 text-sbase font-normal text-gray-900'>when:</p>
                                            <input type='text' name='previousTreatment_IUI_When' defaultValue={formData.previousTreatment_IUI_When} onChange={(e) => handleInputChange(e)} className={theme.cheqColor + ' border border-white  w-1/2  rounded rounded-xl px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-500 '} />
                                        </div>
                                        <div className=' w-full flex items-center'>
                                            <p className='w-1/2  text-sbase font-normal text-gray-900'>where:</p>
                                            <input type='text' name='previousTreatment_IUI_Where' defaultValue={formData.previousTreatment_IUI_Where} onChange={(e) => handleInputChange(e)} className={theme.cheqColor + ' border border-white  w-1/2   rounded rounded-xl px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-500 '} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'w-full flex text-left p-2 items-center  border border-b-gray-200 bg-white ' + theme.hoverBg}>
                                <p className='w-1/3  text-sbase font-medium  text-gray-900'>Previous IVF Cycles</p>
                                <div className='w-2/3 pt-2 px-2  items-center border-l'>
                                    <div className=' w-full flex items-center'>
                                        <p className='w-1/2  text-sbase font-normal  text-gray-900'>Number of IVFs:</p>
                                        <input type='text' name='previoustreatment_IVF_NumberOf' defaultValue={formData.previoustreatment_IVF_NumberOf} onChange={handleInputChange}
                                            className={`${theme.cheqColor} border border-white  w-1/2 rounded-xl px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none `}
                                        />
                                    </div>
                                    <div className=' w-full  items-center'>
                                        <div className=' w-full flex items-center'>
                                            <p className='w-1/2  my-1 text-sbase font-normal  text-gray-900'>When:</p>
                                            <input type='text' name='previousTreatment_IVF_When' defaultValue={formData.previousTreatment_IVF_When} onChange={handleInputChange}
                                                className={`${theme.cheqColor} border border-white  w-1/2 rounded-xl px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none `}
                                            />
                                        </div>
                                        <div className=' w-full flex items-center'>
                                            <p className='w-1/2   text-sbase font-normal  text-gray-900'>Where:</p>
                                            <input type='text' name='previousTreatment_IVF_Where' defaultValue={formData.previousTreatment_IVF_Where} onChange={handleInputChange}
                                                className={`${theme.cheqColor} border border-white  w-1/2 rounded-xl px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'wfull flex text-left p-2 items-center  border  border-b-gray-200 bg-white ' + theme.hoverBg}>
                                <p className='w-1/3  text-sbase font-medium  text-gray-900'>Previous FET Cycles</p>
                                <div className='w-2/3 pt-2 px-2 items-center border-l'>
                                    <div className={' w-full flex items-center '}>
                                        <p className='w-1/2  text-sbase font-normal  text-gray-900'>Number of FETs:</p>
                                        <input type='text' name='previoustreatment_FET_NumberOf' defaultValue={formData.previoustreatment_FET_NumberOf} onChange={(e) => handleInputChange(e)} className={theme.cheqColor + ' border border-white  w-1/2 rounded rounded-xl px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none '} />
                                    </div>

                                    <div className=' w-full flex items-center'>
                                        <p className='w-1/2  my-1  text-sbase font-normal  text-gray-900'>when:</p>
                                        <input type='text' name='previousTreatment_FET_When' defaultValue={formData.previousTreatment_FET_When} onChange={(e) => handleInputChange(e)} className={theme.cheqColor + ' border border-white  w-1/2 rounded rounded-xl px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none '} />
                                    </div>
                                    <div className=' w-full flex items-center'>
                                        <p className='w-1/2  text-sbase font-normal  text-gray-900'>where:</p>
                                        <input type='text' name='previousTreatment_FET_Where' defaultValue={formData.previousTreatment_FET_Where} onChange={(e) => handleInputChange(e)} className={theme.cheqColor + ' border border-white  w-1/2 rounded rounded-xl px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none '} />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className={' w-full text-left px-4 p-2 flex border-r border-b-[2px]  border-b-gray-200 bg-white ' + theme.hoverBg}>
                            <p className='cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>[G] Gravida:</p>
                            <input type='text' name='previoustreatment_G_Gravida' defaultValue={formData.previoustreatment_G_Gravida} onChange={(e) => handleInputChange(e)} className={theme.cheqColor + ' border border-white   rounded rounded-xl px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none '} />
                        </div>
                        <div className={'w-full text-left px-4 p-2 flex border-r border-b-[2px]  border-b-gray-200 bg-white ' + theme.hoverBg}>
                            <p className='cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>[P] Parity:</p>
                            <input type='text' name='previoustreatment_P_Parity' defaultValue={formData.previoustreatment_P_Parity} onChange={(e) => handleInputChange(e)} className={theme.cheqColor + ' border border-white   rounded rounded-xl px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none '} />
                        </div>
                        <div className={'w-full text-left px-4 p-2 flex border-r border-b-[2px]  border-b-gray-200 bg-white ' + theme.hoverBg}>
                            <p className='cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>[M] Miscarriage:</p>
                            <input type='text' name='previoustreatment_M_Miscarriage' defaultValue={formData.previoustreatment_M_Miscarriage} onChange={(e) => handleInputChange(e)} className={theme.cheqColor + ' border border-white   rounded rounded-xl px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none '} />
                        </div>
                        <div className={'w-full  text-left px-4 p-2 flex border-r border-b-[2px]  border-b-gray-200 bg-white ' + theme.hoverBg}>
                            <p className='cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Change Clinic Reason:</p>
                            <input type='text' name='changeClinicReason' defaultValue={formData.changeClinicReason} onChange={(e) => handleInputChange(e)} className={theme.cheqColor + ' border border-white   rounded rounded-xl px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none '} />
                        </div>
                        <div className={'w-full  text-left px-4 p-2 flex border-r border-b-[2px]  border-b-gray-200 bg-white ' + theme.hoverBg}>
                            <p className='cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>[T] Termination:</p>
                            <input type='text' name='previoustreatment_T_Termination' defaultValue={formData.previoustreatment_T_Termination} onChange={(e) => handleInputChange(e)} className={theme.cheqColor + ' border border-white   rounded rounded-xl px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none '} />
                        </div>
                        <div className={'w-full  text-left px-4 p-2 flex border-r  border-b-gray-200 bg-white ' + theme.hoverBg}>
                            <p className='cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>[E] Ectopic:</p>
                            <input type='text' name='previoustreatment_E_Ectopic' defaultValue={formData.previoustreatment_E_Ectopic} onChange={(e) => handleInputChange(e)} className={theme.cheqColor + ' border border-white   rounded rounded-xl px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none '} />
                        </div>
                    </div>

                    <div className={' text-left   w-full mt-1  border-b-gray-200 flex-wrap   ' + theme.hoverBg}>
                        <div className={'bg-[#86B3D1]  text-white text-left p-2 rounded-t'}><p className='text-sbase font-medium'>Additional Comments</p></div>
                        <textarea value={formData.comments} onChange={(e) => handleInputChange(e)} name="comments" rows="5"
                            className={`block p-2.5 w-full text-sm px-1 py-1 border border-white rounded accent-green-700 text-gray-900 focus:border-teal-500 focus:outline-none `}
                            placeholder=" comments ..."
                        />
                    </div>

                </div>
            </div>

            < div className='xl:w-[28.3%] bg-white  py-2 hidden  rounded flex justify-end items-center  px-6  fixed bottom-0 right-[24px]' >
                <button className={"bg-white bg-transparent mx-2 hover:bg-red-500 text-red-500 font-semibold hover:text-white py-1 px-4 border border-red-300 hover:border-transparent rounded"} onClick={() => { window.location.reload(); }}  > Cancel  </button>
                <button onClick={() => setShowModal(true)} className={"bg-white bg-transparent ml-2 hover:bg-green-600 text-green-800 font-semibold hover:text-white py-1 px-4 border border-green-500 hover:border-transparent rounded"}> Confirm</ button>
            </div >
            < div className='xl:w-full bg-white  py-2  rounded flex justify-end items-center  px-6  mt-0.5 bottom-0 right-[24px]' >
                <button className={"bg-white bg-transparent mx-2 hover:bg-red-500 text-red-500 font-semibold hover:text-white py-1 px-4 border border-red-300 hover:border-transparent rounded"} onClick={() => { window.location.reload(); }}  > Cancel  </button>
                <button onClick={() => setShowModal(true)} className={"bg-white bg-transparent ml-2 hover:bg-green-600 text-green-800 font-semibold hover:text-white py-1 px-4 border border-green-500 hover:border-transparent rounded"}> Confirm</ button>
            </div >

            {showModal && <PinModal title="Saving Clinicial History" successFunction={saveData} setStateFunction={setShowModal} setShowModal={setShowModal} showModal={showModal} />}
        </div >
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { ApiCall_GET, ApiCall_POST }

export default connect(mapStateToProps, mapDispatchToProps)(ClinicalHistory)