import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import './style.css'
import Swal from 'sweetalert2'
import { ApiCall_GET, ApiCall_POST } from '../../../../Redux/Generic/action'
import { useAppContext } from '../../../../AppContextProvider '
import { getCorrectDateForInput, getPatientInformation, getUserInformation } from '../../../../common'

const AddArt = ({ notes, ApiCall_POST, ApiCall_GET, patientState, coupleid, getArtList, setIsOpen }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [clinicianlist, setClinicianlist] = useState([]);

    useEffect(() => {
        var locationId = patientState?.couple_File_People?.PatientLocationId || patientState?.couple_File_People?.LocationId || getPatientInformation()?.LocationId || getUserInformation()?.LocationId

        Number(locationId) && ApiCall_GET('UserManagment/GetClinicianList/' + locationId, true).then(function (payload) {
            setClinicianlist(payload);
        })

        setArtState({
            ...artState,
            ['lesser']: getNumericFields()?.lesser,
            ['greater']: getNumericFields()?.greater,
            ['the13200']: getNumericFields()?.the13200,
        });

    }, []);

    const getNumericFields = () => {
        var variables = {
            lesser: 0,
            greater: 0,
            the13200: 0,
        }

        if (patientState) {
            variables.lesser = patientState?.couple_File_People?.previoustreatmentL20
            const num_cycle_rs = patientState?.artDetailList?.filter(x => x.completed && x.Finalised && (
                ((x.miscarridge || x.ectapicol) && (x.miscarridgweeks <= 20 || x.miscarridgweeks === null)) || x.blightedovum
            )).length || 0;

            variables.lesser += num_cycle_rs;

            variables.greater = patientState?.couple_File_People?.previoustreatmentM20
            variables.greater += patientState?.artDetailList?.filter(x => x.cycletype.toLowerCase().includes('ivf') && x.completed && x.Finalised && (x.miscarridge || x.ectapicol) && (x.miscarridgweeks > 20 || x.miscarridgweeks === null)).length || 0;
            var num_baby = patientState?.artDetailList?.filter(x => x.completed && x.Finalised && !isNaN(parseInt(x.num_after_20)))?.reduce((sum, x) => sum + parseInt(x.num_after_20), 0) || 0;
            variables.greater += num_baby;


            variables.the13200 = patientState?.couple_File_People?.previoustreatment13200
            variables.the13200 += patientState?.artDetailList?.filter(x => x.cycletype.toLowerCase().includes('ivf') && x.completed && x.Finalised).length;
        }



        return variables
    };

    var initialObj = {
        coupleId: coupleid,
        cycletype: '',
        converted: null,
        clinician: patientState?.couple_File_People?.clinicianFullName,
        converteddate: null,
        typeofIVFscycle: null,
        date: getCorrectDateForInput(new Date()),
        dayofcycle: null,
        fsh_CommenceDate: null,

        drugtrialnum: null
    }




    const [artState, setArtState] = useState(initialObj)
    const theme = useAppContext().themeDetails;




    // ApiCall_POST('Art/SaveArt', artState, true).then(function (payload) {
    //     Swal.fire({
    //       icon: 'success',
    //       title: 'ART Cycle has been saved.',
    //       showConfirmButton: false,
    //       timer: 1500
    //     })
    //     getArtList();
    //     closeModal();
    // })


    const sendData = () => {

        const elements = Array.from(document.getElementsByName('consentTP'));
        const checkedValues = elements.filter(element => element.checked).map(element => element.value).join(',');
        artState.consentTP = checkedValues
        artState.coupleId = coupleid
        if (!artState.cycletype || !artState.clinician || !artState.date) {
            // Iterate through each required input element and set its border color to red if it's empty
            const requiredInputs = document.querySelectorAll('[name="cycletype"], [name="clinician"], [name="date"]');
            requiredInputs.forEach(input => {
                input.style.borderColor = input.value ? '' : 'red';
            });

            // const divElement = document.querySelector('.surrounding-div');
            // divElement.style.borderColor = 'red';

            // Show an alert or handle the error message accordingly
            if (!artState.cycletype) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Choose a Cycle Type',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
            return; // Exit the function if any required field is empty
        }
        // if (artState.cycletype == '') {
        //     const divElement = document.querySelector('.surrounding-div');
        //     divElement.style.borderColor = ''; // Remove border color
        // }

        if (artState.cycletype && artState.clinician && artState.date) {
            ApiCall_POST('Art/SaveArt', artState, true).then(function (payload) {
                Swal.fire({
                    icon: 'success',
                    title: 'ART Cycle has been saved.',
                    showConfirmButton: false,
                    timer: 1500
                })
                getArtList();
                setIsOpen(false);
                navigate('../treatmentplan', { state: { artid: payload, coupleid: coupleid, isEmptyART: true, notes: notes } })
            });
        }
    }


    const handleInputChange = (e) => {

        const { name, value, checked, type } = e.target;
        if (name === 'converted' && !checked) {
            setArtState((prevData) => ({
                ...prevData,
                ['converteddate']: '',
            }));
        }
        if (type === 'checkbox') {
            // If it's a checkbox, save the checked state
            setArtState((prevData) => ({
                ...prevData,
                [name]: checked,
            }));
            if (name === 'converted' && !checked) {
                setArtState((prevData) => ({
                    ...prevData,
                    ['converteddate']: '',
                }));
            }
        } else {
            // For other input types, save the value
            setArtState((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };


    return (
        <div className="fixed z-50 inset-0 overflow-y-auto " >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div style={{ backdropFilter: 'blur(7px)' }} className="fixed inset-0 transition-opacity filter" aria-hidden="true" >
                    <div className="absolute inset-0 bg-gray-700 opacity-70  " ></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen filter blur-xl " aria-hidden="true" ></span>

                <div className={`sm:my-8  w-2/3   filter inline-block align-bottom bg-white sm:align-middle  rounded-lg text-left overflow-hidden shadow-xl`}
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <div className={`bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative w-full  `}>
                        <svg onClick={(e) => setIsOpen(false)} className={' absolute top-2 right-2 cursor-pointer shadow  font-semibold  '}
                            width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className="hover:shadow   " d="M16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM12.6 14L9 10.4L5.4 14L4 12.6L7.6 9L4 5.4L5.4 4L9 7.6L12.6 4L14 5.4L10.4 9L14 12.6L12.6 14Z" fill="#ed4545" />
                        </svg>
                        <h3 className={theme.txt_color + " text-xxl text-center py-4 leading-6 font-bold text-gray-900"} id="modal-headline" > Create ART Cycle</h3>
                        <div className={`bg-white px-4 pt-2 pb-4 sm:p-6 sm:pb-4 relative w-full `}>
                            <div className='flex items-start gap-2 justify-between '>
                                <div className='w-[35%]  '>
                                    <label className={'mr-4 font-bold text- xl ' + theme.txt_color + ' border-b-[3px] border-b-amber-500'}>Current Reservations</label>
                                    <div className='shadow text-left p-2 mt-2 pb-6 border-b-[1px]  border-b-gray-200   '>

                                        <div className='px-2  w-1/2 mb-3'>
                                            <label className="flex items-center text-sm text-gray-800 ">Select a Cycle Clinician <span className='text-red-400'>*</span></label>
                                            <select name='clinician' onChange={(e) => { handleInputChange(e) }} className=" border w-full rounded-md bg-white px-1 py-1 text-xss  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 ">
                                                <option value=''>- Select Clinician - </option>
                                                {clinicianlist && clinicianlist.map((item) => (
                                                    <option value={item.userid} selected={item.username == artState?.clinician}>{item?.username}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <p className='cursor-pointer text-base font-bold w-52 text-gray-700 pb-2'>Choose a Cycle Type<span className='text-red-400'>*</span> </p>
                                        <div className={`p-2 pt-0 `} >
                                            <label className="flex items-center mb-2 ">
                                                <input type="radio" className={theme.cheqColor + ' border-0 '} name='cycletype' value="Ovulation Induction" onClick={(e) => handleInputChange(e)} />
                                                <span className=" ml-2 text-sm  text-gray-800 dark:text-gray-600">Ovulation Induction</span>
                                            </label>
                                            <label className="flex items-center mb-2 ">
                                                <input type="radio" className={theme.cheqColor + ' border-0 '} name='cycletype' value="IVF - Stimulated" onClick={(e) => handleInputChange(e)} />
                                                <span className=" cursor-pointer text-sm  ml-2 w-40 text-gray-700 ">IVF - Stimulated</span>
                                            </label>
                                            <label className="flex items-center mb-2 ">
                                                <input type="radio" className={theme.cheqColor + ' border-0 '} name='cycletype' value="FOT - Frozen Oocyte Transfer" onClick={(e) => handleInputChange(e)} />
                                                <span className=" cursor-pointer text-sm  ml-2 w-40 text-gray-700  ">FOT - Frozen Oocyte Transfer</span>
                                            </label>
                                            <label className="flex items-center mb-2 ">
                                                <input type="radio" className={theme.cheqColor + ' border-0 '} name='cycletype' value="IUI - Intrauterine Insemination" onClick={(e) => handleInputChange(e)} />
                                                <span className=" cursor-pointer text-sm  ml-2 w-40 text-gray-700  ">IUI - Intrauterine Insemination</span>
                                            </label>
                                            <label className="flex items-center mb-2 ">
                                                <input type="radio" className={theme.cheqColor + ' border-0 '} name='cycletype' value="IVF - Natural" onClick={(e) => handleInputChange(e)} />
                                                <span className=" cursor-pointer text-sm  ml-2 w-40 text-gray-700  ">IVF - Natural</span>
                                            </label>
                                            <label className="flex items-center mb-2 ">
                                                <input type="radio" className={theme.cheqColor + ' border-0 '} name='cycletype' value="FOC - Frozen Oocyte Cycle" onClick={(e) => handleInputChange(e)} />
                                                <span className=" cursor-pointer text-sm  ml-2 w-40 text-gray-700  ">FOC - Frozen Oocyte Cycle</span>
                                            </label>
                                            <label className="flex items-center mb-2 ">
                                                <input type="radio" className={theme.cheqColor + ' border-0 '} name='cycletype' value="IVF - EDA Donor Cycle" onClick={(e) => handleInputChange(e)} />
                                                <span className=" cursor-pointer text-sm  ml-2 w-40 text-gray-700  ">IVF - EDA Donor Cycle</span>
                                            </label>
                                            <label className="flex items-center mb-2 ">
                                                <input type="radio" className={theme.cheqColor + ' border-0 '} name='cycletype' value="FET - Frozen Embryo Transfer" onClick={(e) => handleInputChange(e)} />
                                                <span className=" cursor-pointer text-sm  ml-2 w-40 text-gray-700  ">FET - Frozen Embryo Transfer</span>
                                            </label>
                                            <label className="flex items-center mb-2 ">
                                                <input type="radio" className={theme.cheqColor + ' border-0 '} name='cycletype' value="IVF - Minimal Stim" onClick={(e) => handleInputChange(e)} />
                                                <span className=" cursor-pointer text-sm  ml-2 w-40 text-gray-700  ">IVF - Minimal Stim</span>
                                            </label>
                                            <label className="flex items-center mb-2 ">
                                                <input type="radio" className={theme.cheqColor + ' border-0 '} name='cycletype' value="IVF - EDA Recipient Cycle" onClick={(e) => handleInputChange(e)} />
                                                <span className=" cursor-pointer text-sm  ml-2 w-40 text-gray-700  ">IVF - EDA Recipient Cycle</span>
                                            </label>

                                        </div>
                                    </div>
                                </div>
                                <div className='w-[25%]   '>
                                    <label className={'mr-4 font-bold text- xl ' + theme.txt_color + ' border-b-[3px] border-b-amber-500'}>Consent Treatment Options</label>
                                    <div className=' text-left  shadow p-2 px-3 mt-2 border-b-[1px]  border-b-gray-200   '>
                                        <label className="flex items-center mb-2 ">
                                            <input id="consentTP" value="1" name='consentTP' type="checkbox" className={theme.cheqColor + ' border-0 '} />
                                            <span className=" cursor-pointer text-sm ml-1 w-40 text-gray-700  ">IVF Cycle</span>
                                        </label>
                                        <label className="flex items-center mb-2 ">
                                            <input name='consentTP' value="4" type="checkbox" className={theme.cheqColor + ' border-0 '} />
                                            <span className=" cursor-pointer text-sm ml-1 w-40 text-gray-700  ">PESA / TESA Biopsy</span>
                                        </label>
                                        <label className="flex items-center mb-2 ">
                                            <input name='consentTP' value="7" type="checkbox" className={theme.cheqColor + ' border-0 '} />
                                            <span className=" cursor-pointer text-sm ml-1 w-40 text-gray-700  ">Oocyte Freezing</span>
                                        </label>
                                        <label className="flex items-center mb-2 ">
                                            <input name='consentTP' value="6" type="checkbox" className={theme.cheqColor + ' border-0 '} />
                                            <span className=" cursor-pointer text-sm ml-1 w-40 text-gray-700  ">Embryo Freezing</span>
                                        </label>
                                        <label className="flex items-center mb-2 ">
                                            <input name='consentTP' value="12" type="checkbox" className={theme.cheqColor + ' border-0 '} />
                                            <span className=" cursor-pointer text-sm ml-1 w-40 text-gray-700  ">Embryo Donor</span>
                                        </label>
                                        <label className="flex items-center mb-2 ">
                                            <input name='consentTP' value="13" type="checkbox" className={theme.cheqColor + ' border-0 '} />
                                            <span className=" cursor-pointer text-sm ml-1 w-40 text-gray-700  ">Surrogate Cycle</span>
                                        </label>
                                        <label className="flex items-center mb-2 ">
                                            <input name='consentTP' value="21" type="checkbox" className={theme.cheqColor + ' border-0 '} />
                                            <span className=" cursor-pointer text-sm ml-1 w-40 text-gray-700  ">General Cycle Consent (Portal)</span>
                                        </label>
                                        <label className="flex items-center mb-2 ">
                                            <input name='consentTP' value="11" type="checkbox" className={theme.cheqColor + ' border-0 '} />
                                            <span className=" cursor-pointer text-sm ml-1 w-40 text-gray-700  ">Oocyte Donor</span>
                                        </label>
                                        <label className="flex items-center mb-2 ">
                                            <input name='consentTP' value="2" type="checkbox" className={theme.cheqColor + ' border-0 '} />
                                            <span className=" cursor-pointer text-sm ml-1 w-40 text-gray-700  ">FET Cycle</span>
                                        </label>
                                        <label className="flex items-center mb-2 ">
                                            <input name='consentTP' value="5" type="checkbox" className={theme.cheqColor + ' border-0 '} />
                                            <span className=" cursor-pointer text-sm ml-1 w-40 text-gray-700  ">PGD / CGH</span>
                                        </label>
                                        <label className="flex items-center mb-2 ">
                                            <input name='consentTP' value="3" type="checkbox" className={theme.cheqColor + ' border-0 '} />
                                            <span className=" cursor-pointer text-sm ml-1 w-40 text-gray-700  ">IUI Cycle</span>
                                        </label>
                                        <label className="flex items-center mb-2 ">
                                            <input name='consentTP' value="9" type="checkbox" className={theme.cheqColor + ' border-0 '} />
                                            <span className=" cursor-pointer text-sm ml-1 w-40 text-gray-700  ">Gamete / Embryo Disposal</span>
                                        </label>
                                        <label className="flex items-center mb-2 ">
                                            <input name='consentTP' value="14" type="checkbox" className={theme.cheqColor + ' border-0 '} />
                                            <span className=" cursor-pointer text-sm ml-1 w-40 text-gray-700  ">Commissioning Parents</span>
                                        </label>
                                        <label className="flex items-center  ">
                                            <input name='consentTP' value="8" type="checkbox" className={theme.cheqColor + ' border-0 '} />
                                            <span className=" cursor-pointer text-sm ml-1 w-40 text-gray-700  ">Sperm Freezing</span>
                                        </label>
                                    </div>
                                </div>
                                <div className='w-[40%] '>
                                    <label className={'mr-4 font-bold text- xl ' + theme.txt_color + ' border-b-[3px] border-b-amber-500'}>Current Reservations</label>
                                    <div className='flex flex-wrap text-left p-1 shadow p-2 mt-2  w-full border-b-[1px]  border-b-gray-200   '>
                                        <div className='px-2  w-1/2 mb-3'>
                                            <label className="flex items-center text-sm text-gray-800 ">IVF Type </label>
                                            <select name='typeofIVFscycle' onChange={(e) => { handleInputChange(e) }} className=" border w-full rounded-md bg-white px-1 py-1 text-xss  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 ">
                                                <option value=''>Select IVF Type</option>
                                                <option value='OCP/Downregulation'>OCP / Downregulation</option>
                                                <option value='Long Protocol'>Long Protocol</option>
                                                <option value='Boost Protocol'>Boost Protocol</option>
                                                <option value='Natural Cycle'>Natural Cycle</option>
                                                <option value='FSH/Antagonist'>FSH / Antagonist</option>
                                                <option value='Clomid/FSH/Antagonist'>Clomid / FSH / Antagonist</option>
                                                <option value='Clomid/Antagonist'>Clomid / Antagonist</option>
                                                <option value='Clomid/natural'>Clomid / natural</option>
                                            </select>
                                        </div>
                                        <div className='px-2 w-1/2 mb-3 '>
                                            <label className="flex items-center text-sm text-gray-800  ">FET Type</label>
                                            <select name='fettype' onChange={(e) => { handleInputChange(e) }} className=" border  w-full rounded-md bg-white px-1 py-1 text-xss  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 ">
                                                <option value=''>Select FET Type</option>
                                                <option value='Natural'>Natural</option>
                                                <option value='HRT'>HRT</option>
                                                <option value='Clomid'>Clomid</option>
                                            </select>
                                        </div>
                                        <div className='px-2 w-2/6  mb-3'>
                                            <label className="flex items-center text-sm text-gray-800 ">Date<span className='text-red-400'>*</span> </label>
                                            <input type="date" name='date' value={artState?.date} onChange={(e) => { handleInputChange(e) }} className=" w-full py-1 px-2 border rounded rounded accent-green-700 shadow text-xss text-gray-700 focus:border-teal-500 focus:outline-none" />
                                        </div>
                                        <div className='px-2 w-2/6  mb-3'>
                                            <label className="flex items-center text-sm text-gray-800 ">Day 1 of Cycle</label>
                                            <input type="date" onChange={(e) => { handleInputChange(e) }} name="dayofcycle" className=" w-full py-1 px-2 border rounded rounded accent-green-700 shadow text-xss text-gray-700 focus:border-teal-500 focus:outline-none" />
                                        </div>
                                        <div className='px-2  w-2/6 mb-3'>
                                            <label className="flex items-center text-sm text-gray-800 ">FSH Commence Date</label>
                                            <input type="date" onChange={(e) => { handleInputChange(e) }} name="fsh_CommenceDate" className="py-1 px-2 border rounded rounded accent-green-700 shadow text-xss w-full  text-gray-700 focus:border-teal-500 focus:outline-none" />
                                        </div>
                                    </div>

                                    <div className='flex flex-wrap text-left p-1 shadow p-2 mt-5  w-full border-b-[1px]  border-b-gray-200   '>

                                        <div className='px-2  w-2/6 mb-3'>
                                            <label className="flex items-center text-sm text-gray-800 "> {'< '}20 <span className='text-red-300'>*</span></label>
                                            <input name="lesser" type="number" value={artState?.lesser} disabled="" onChange={(e) => { handleInputChange(e) }} className="py-1 px-2 border rounded rounded accent-green-700 shadow text-xss w-5/6  text-gray-700 focus:border-teal-500 focus:outline-none" />
                                        </div>
                                        <div className='px-2  w-2/6 mb-3'>
                                            <label className="flex items-center text-sm text-gray-800 ">{'> '}20 <span className='text-red-300'> *</span></label>
                                            <input name="greater" type="number" value={artState?.greater} disabled="" onChange={(e) => { handleInputChange(e) }} className="py-1 px-2 border rounded rounded accent-green-700 shadow text-xss w-5/6  text-gray-700 focus:border-teal-500 focus:outline-none" />
                                        </div>
                                        <div className='px-2  w-2/6 mb-3'>
                                            <label className="flex items-center text-sm text-gray-800 ">Previous 13200 <span className='text-red-300'>*</span></label>
                                            <input name="the13200" type="number" value={artState?.the13200} disabled="" onChange={(e) => { handleInputChange(e) }} className="py-1 px-2 border rounded rounded accent-green-700 shadow text-xss w-5/6  text-gray-700 focus:border-teal-500 focus:outline-none" />
                                        </div>
                                        <div className='px-2  w-full my-3'>
                                            <label className="flex items-center text-sm text-gray-800 mb-2">Additional Options</label>
                                            <label className="flex items-center ">
                                                <input name="drugtrial" type="checkbox" onChange={(e) => { handleInputChange(e) }} className={theme.cheqColor + ' border-0 '} />
                                                <span className=" cursor-pointer text-sm ml-1 w-40 text-gray-700">Participant in Drug Trial</span>
                                            </label>
                                        </div>


                                    </div>
                                    <div className='ml-4 mt-5 flex items-center'>
                                        <label className="flex items-center">
                                            <input type="checkbox" className={theme.cheqColor + ' border-0 '} name='converted' onChange={(e) => { handleInputChange(e) }} />
                                            <span className=" cursor-pointer text-sm ml-1 w-40 text-gray-700  ">Converted : Converted Date </span>
                                        </label>
                                        <input type="date" name="converteddate" onChange={(e) => { handleInputChange(e) }} value={artState && artState.converteddate} disabled={artState && !artState.converted} className="ml-4 px-2 py-0.5 border rounded rounded accent-green-700 shadow text-xss text-gray-700 focus:border-teal-500 focus:outline-none" />
                                    </div>
                                </div>
                            </div>
                            <div className=" py-2 rounded flex justify-end items-center border px-6">
                                <button
                                    onClick={(e) => setIsOpen(false)}
                                    className="bg-transparent mx-6 hover:bg-red-500 text-red-500 font-semibold hover:text-white py-1 px-4 border border-red-300 hover:border-transparent rounded"> Cancel  </button>
                                <button onClick={() => sendData()} className="bg-transparent hover:bg-green-800 text-green-700 font-semibold hover:text-white py-1 px-4 border border-green-500 hover:border-transparent mr-3 rounded" > Save </button>

                            </div>
                        </div >
                    </div>
                </div>


            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    referenceItem: state.referenceItemReducer.referenceItems
})
export default connect(mapStateToProps, {
    ApiCall_POST, ApiCall_GET
})(AddArt);