
import React, { createContext, useContext, useEffect, useState } from 'react';
import { getUserInformation } from './common';
import {setSession } from './session';

// Create a context
const AppContext = createContext();

// Create a context provider
export const AppContextProvider = ({ children }) => {
    const currentPath = window.location.pathname;

    const [themeDetails, setThemeDetails] = useState({});
    const [isSelectedd, setIsSelected] = useState(false);
    const [selectedCoupletDetails, setCoupletData] = useState();

    if (currentPath == '/addreferenceitems') {
        setSession('patient', {});
    }
    useEffect(() => {

    }, [selectedCoupletDetails]);


    return (
        <AppContext.Provider value={{
            themeDetails, setThemeDetails, isSelectedd, setIsSelected, selectedCoupletDetails, setCoupletData
        }}>
            {children}
        </AppContext.Provider>
    );
};

// Custom hook to use the context
export const useAppContext = () => useContext(AppContext);