import axios from "axios";
import { baseApiUrl, setGlobalIsLoading } from '../../session'
import { getUserInformation, replaceEmptyObjectsWithNull } from "../../common";

axios.defaults.withCredentials = true;


export const ApiCall_POST = (url, data, isAsync, isShowLoader = true) => {
    setGlobalIsLoading(isShowLoader)
    const User = getUserInformation()
    if (!User && !User?.UserId) { return 'error' }
    const headers = {
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': `Bearer ${User.UserId}`,
    };

    if (isAsync) {
        return async (dispatch) => {
            try {
                dispatch({ type: "Request" });

                const response = await axios.post(baseApiUrl + url, data, { headers });

                dispatch({ type: "Success", payload: response?.data });
                var result = response.data;
                return result;
            } catch (error) {
                dispatch({ type: "Failed", payload: error });
                throw error;
            }
        };
    } else {
        return (dispatch) => {
            try {
                const response = axios.post(baseApiUrl + url, data, { headers });

                if (response.result.length > 0) {
                    dispatch({ type: "Success", payload: response.data });
                }
                else {
                    dispatch({ type: "Failed", payload: response.data });
                }
                var result = response.data;
                return result;
            } catch (error) {
                dispatch({ type: "Failed", payload: error });
                throw error;
            }
        };
    }
};

export const ApiCall_GET = (url, isAsync, isShowLoader = true) => {

    setGlobalIsLoading(isShowLoader)
    const User = getUserInformation()

    if (isAsync) {
        return async (dispatch) => {
            try {

                if (!User || !User.UserId) {
                    dispatch({ type: 'Failed', payload: 'User information not available' });
                    return 'error'
                }

                const headers = {
                    'Authorization': `Bearer ${User.UserId}`,
                };


                const response = await axios.get(baseApiUrl + url, { headers });
                const result = response.data;

                dispatch({ type: 'Success', payload: result });
                return result;
            } catch (error) {
                dispatch({ type: 'Failed', payload: error });
                throw error;
            }
        };
    } else {
        return async (dispatch) => {
            try {



                if (!User || !User.UserId) {
                    dispatch({ type: 'Failed', payload: 'User information not available' });
                    return 'result';
                }

                const headers = {
                    'Authorization': `Bearer ${User.UserId}`,
                };


                const response = await axios.get(baseApiUrl + url, { headers });
                const result = replaceEmptyObjectsWithNull(response.data);
                return result;
            } catch (error) {
                throw error;
            }
        };
    }
};

