import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import logo from '../../../../Assets/images/white.png';
import clinicIcon from '../../../../Assets/images/clinicIcon.png';
import { getSession } from '../../../../session';
import moment from 'moment'
import { getCorrectDate, getUserInformation } from '../../../../common';
// import moment from 'moment'

export const PregnancyScanPrint = ({ theme, allScans, artid, coupleDetails, formData }) => {
    const [currentTime, setCurrentTime] = useState(new Date());
    const user = getUserInformation()
    const [couple, setCouple] = useState(null)
    useEffect(() => {
        setCouple(getSession('patient'))
    }, [])
    function getCycleDay(dayOne, ScanDate) {
        // Parse the input dates using moment

        dayOne = getCorrectDate(dayOne);
        ScanDate = getCorrectDate(ScanDate);
        const momentDayOne = moment(dayOne, 'DD-MM-YYYY');//moment(dayOne, 'DD-MM-YYYY');
        const momentScanDate = moment(ScanDate, 'DD-MM-YYYY')//, 'YYYY-MM-DD');
        // Calculate the difference in days


        const differenceInDays = momentScanDate.diff(momentDayOne, 'days') + 1;


        return isNaN(differenceInDays) ? '0' : differenceInDays;
    }

    return (
        <div id="divPrint" className=" flex min-h-screen  w-full p-2 delay-900 duration-700 pagebreak ">
            {/* Header */}
            <div className="relative w-full  mx-auto p-3">
                <div className={` ${theme.navbar} ` + 'flex justify-between   mb-2  p-2'} >

                    <div className='flex items-center justify-end '>
                        <img src={logo} className="logoCFC" alt="img" width='100px' />
                        <p className={`dot-${user?.BrandCode?.toUpperCase()} `} style={{ backgroundColor: `#${user?.ChartingColor}` }}></p>
                        <p className=' text-white  text-sm ml-1'>{user?.LocationName}</p>
                    </div>
                    <div className='flex justify-end text-white items-center '>
                        <label className='  text-sm'>{moment(new Date()).format('ddd DD-MMM-yyyy')}</label>
                        {/* <label className='pl-1  font-semibold'>{moment(currentTime.toString()).format('hh:mm:ss A')}</label> */}
                    </div>

                </div>


                <label className={'mr-4 font-bold text- xl ' + theme.txt_color + ' border-b-[3px] border-b-amber-500 '}>Pregnancy Scan</label>


                {/* patient info */}
                <div className='w-full mt-2 shadow mr-1 mt-3 mb-2'>
                    <p className={theme.tHedUpdte + '  text-sm font-medium text-white text-left p-1  rounded-t'}>Patient / Couple Details</p>


                    <div className='flex w-full'>
                        <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2  '>
                            <p className='cursor-pointer text-sbase font-medium   text-gray-700'>Patient </p>
                            <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                <p className={'text-sm text-gray-500  '}>
                                    {couple?.patientFullName}
                                </p>
                                <p className={'text-sm text-gray-500  '}>
                                    <b>DOB: </b>  {couple?.patientDOB}
                                </p>
                            </div>
                        </div>
                        <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2 '>
                            <p className='cursor-pointer text-sbase font-medium   text-gray-700'>Partner
                            </p>
                            <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                <p className={'text-sm text-gray-500  '}>
                                    {couple?.partnerFullName}
                                </p>
                                <p className={'text-sm text-gray-500  '}>
                                    <b>DOB: </b>  {couple?.partnerDOB}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='flex w-full'>
                        <div className=' text-left  mx-2 flex items-center  py-1    w-1/2 '>
                            <p className=' text-sm font-medium   text-gray-700'>Clinician</p>
                            <p className={'pl-2 text-sm text-gray-500  '}> {couple?.clinicianName}</p>

                        </div>
                        <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2 '>
                            <p className='cursor-pointer text-sbase w-28 font-medium   text-gray-700'>Couple Code
                            </p>
                            <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                <p className={'text-sm text-gray-500  '}>
                                    {couple?.coupleCode}
                                </p>
                                <p className={'text-sm text-gray-500  '}>
                                    <b>{'ART' + artid}</b>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <label className={'mr-4 font-bold text- xl ' + theme.txt_color + ' border-b-[3px] border-b-amber-500 '}>Pregnancy Scan Results</label>



                <div className={'   w-full mt-3'}>
                    {allScans[0]?.scanresultid > 0 ?
                        (allScans.map((item) => (
                            <div className='w-full mb-2 border '>
                                <div className={((theme.bg_Light + " " + theme.txt_color + '  font-medium')) + ' flex rounded-t text-xss text-left p-1'}>
                                    <label className='w-4/5'>
                                        Pregnancy Scan {getCorrectDate(item.scandate) ? 'on ' + getCorrectDate(item.scandate) : ''}
                                    </label>
                                    <label className='w-2/5 text-right'>
                                        {item.clinicianname ? 'by : ' + item.clinicianname : ''}
                                    </label>
                                </div>
                                <div className='bg-white rounded  pl-3 flex flex-wrap p-1 items-center w-full '>
                                    <div className='w-1/3 flex    border-b  py-0.5'>
                                        <label className='text-xss text-black w-16'>Scan Date</label>
                                        <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{getCorrectDate(item.scandate)} </label>
                                    </div>
                                    <div className='w-1/3 flex     border-b   py-0.5'>
                                        <label className='text-xss text-black w-16'>EDD Date </label>
                                        <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{getCorrectDate(item.edddate)} </label>
                                    </div>
                                    <div className='w-1/3 flex   border-b  py-0.5'>
                                        <label className='text-xss text-black   '>Next Scans </label>
                                        <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{getCorrectDate(item.nextscan)}
                                            <i className='text-gray-400 mx-1'>{(getCorrectDate(item.nextscan) && item?.dayoneofcycle) ? `(Day-${getCycleDay(item?.dayoneofcycle, getCorrectDate(item?.nextscan))})` : ''}
                                            </i>
                                        </label>
                                    </div>

                                    <div className='w-1/3 flex   border-b  py-0.5'>
                                        <label className='text-xss text-black  w-24 '>Private Hospital ~old </label>
                                        <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>
                                            {item?.privateHospital}
                                        </label>
                                    </div>
                                    <div className='w-1/3 flex   border-b  py-0.5'>
                                        <label className='text-xss text-black  w-24 '>Public Hospital ~old</label>
                                        <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '> {item?.publicHospital}
                                        </label>
                                    </div>

                                    <div className='w-1/3 flex   border-b  py-0.5'>
                                        <label className='text-xss text-black  w-16 '>Clinic</label>
                                        <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>
                                            {item?.ClinicName}
                                        </label>
                                    </div>
                                    <div className='w-1/3 flex   border-b  py-0.5'>
                                        <label className='text-xss text-black  w-24 '>Procedure Location</label>
                                        <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '> {item?.HospitalName}
                                        </label>
                                    </div>
                                    <div className='w-1/3 flex   border-b  py-0.5'>
                                        <label className='text-xss text-black  w-16 '>FET Date</label>
                                        <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{getCorrectDate(item.fetdate)} </label>

                                    </div>
                                    <div className='w-1/3 flex   border-b  py-0.5'>
                                        <label className='text-xss text-black   w-32 '>Embryo Development Stage</label>
                                        <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{item.embryodevelopmentstage} </label>
                                    </div>
                                    <div className='w-1/3 flex   border-b  py-0.5'>
                                        <label className='text-xss text-black  w-16 '>CRL</label>
                                        <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{item.crl} </label>
                                    </div>

                                    {/*  */}
                                    <div className='w-1/3 flex   border-b  py-0.5'>
                                        <label className='text-xss text-black   w-16 '>EPU Date</label>
                                        <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{getCorrectDate(item.epudate)} </label>
                                    </div>
                                    <div className='w-1/3 flex   border-b  py-0.5'>
                                        <label className='text-xss text-black w-32  '>Number of Intrauterine Sacs</label>
                                        <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{item.fetalSacs} </label>

                                    </div>
                                    <div className='w-1/3 flex   border-b  py-0.5'>
                                        <label className='text-xss text-black  w-16 '>Heart Rate</label>
                                        <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{item.heartrate} </label>

                                    </div>
                                    {/*  */}
                                    <div className='w-1/3 flex   border-b  py-0.5'>
                                        <label className='text-xss text-black  w-16 '>ET Date</label>
                                        <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{getCorrectDate(item.etdate)} </label>

                                    </div>
                                    <div className='w-1/3 flex   border-b  py-0.5'>
                                        <label className='text-xss text-black  w-32  '> Number of Fetal Hearts</label>
                                        <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{item.fetalHearts} </label>
                                    </div>
                                    <div className='w-1/3 flex   py-0.5'>
                                        <label className='text-xss text-black  w-16 '>Comments</label>
                                        <label className='text-xss text-gray-500 ml-3  rounded-md px-2 '>{item.comments} </label>
                                    </div>
                                </div>
                            </div>
                        )))
                        : <p className='px-2 py-1 text-sm text-gray-400 text-center font-bold mt-5'>~No previous scans found~</p>
                    }
                </div>


                {/* {doctorView?.ScanDetails[0]?.scanresultid > 0 &&
                    <>
                        <label className={'mr-4 font-bold text- xl  ' + theme.txt_color + ' border-b-[3px] border-b-amber-500 mt-2'}>In-Cycle Scan Result</label>


                        < div className={theme.thumb + ' ' + theme.track + '   w-full mt-2'} style={{ width: "100%" }}>

                            <div className={theme.tHedUpdte + ' text-white text-left p-1 flex justify-between rounded-t px-2'}>
                                <p className='text-sbase font-medium w-2/6'>Day One  </p>
                                <p className='text-sbase font-medium w-2/6'>Scan Date </p>
                                <p className='text-sbase font-medium w-2/6'>Cycle Day </p>
                                <p className='text-sbase font-medium w-2/6'>Next Scan </p>
                                <p className='text-sbase font-medium w-2/6'>Scan  By</p>
                                <p className='text-sbase font-medium w-1/6'>Uterus</p>
                                <p className='text-sbase font-medium w-3/6 pl-2'>Endo. Thicknes</p>
                                <p className='text-sbase font-medium w-2/6'>Comments</p>
                            </div>
                            {doctorView?.ScanDetails.map((item, index) => (
                                index <= 2 ?
                                    < div className={'  w-full   mb-1  rounded shadow border mt-1'} >
                                        <div className={theme.tableBg + '  rounded   text-left p-2 flex justify-between rounded-t '}>
                                            <p className='text-sm w-2/6 '>{item.dayoneofcycle} </p>
                                            <p className='text-sm  w-2/6'>{getCorrectDate(item.scandate)}</p>
                                            <p className='text-sm  w-2/6'>{item.scandayofcycle || '--'}</p>
                                            <p className='text-sm  w-2/6'>{getCorrectDate(item.nextscan) || '--'}</p>
                                            <p className='text-sm  w-2/6'>{item.clinicianname}</p>
                                            <p className='text-sm  w-1/6 pl-2'>{item.result_uterus}</p>
                                            <p className='text-sm  w-3/6'>{item.result_endo}</p>
                                            <p className='text-sm  w-2/6'>{item.comments}</p>
                                        </div>

                                        <div className={theme.panelBg + ' w-full flex text-left  p-1   '}>
                                            <div className='flex text-gray-500 w-1/2 '>
                                                <label className={" text-sm  font-bold mr-1"} >L : </label>
                                                <label className={" text-sm   "} >{item.result_l}</label>
                                            </div>
                                            <div className='flex text-gray-500 w-1/2 '>
                                                <label className={" text-sm   font-bold mr-1"} >R : </label>
                                                <label className={" text-sm   "} >{item.result_r}</label>
                                            </div>
                                        </div>

                                    </div>
                                    : ''
                            ))}
                        </div>
                    </>
                } */}

                <footer className='flex mt-3 p-2 hidden'>
                    <div className='w-full flex items-center'>
                        <img className='rounded-full  h-14' src={logo} alt={"logo"} />
                        <div className='border-gray-200  p-1 ml-2 '>
                            <p className='text-xss text-left  m-0 text-gray-700 font-medium'>
                                Document generated on 19-JUN-2023 16:10:05 AEST<br />
                                Do NOT rely solely on the information on this print-out. Always check the Current Treatment Plan in the FSF Database for changes.<br />
                                Form version 8.1 saved on 31-OCT-2022 09:41:04 AEST by Michelle Brown. </p>

                        </div>
                    </div>
                </footer>
            </div >
        </div >
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PregnancyScanPrint)